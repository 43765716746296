// @ts-strict-ignore
import { Link } from '@mui/material';
import React from 'react';
import style from './style.module.scss';
import { Link as ReactLink } from 'react-router-dom';

interface BigTextLinkProps {
    onClick?: () => any;
    children: any;
    color?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    route?: string;
}

export const BigTextLink = React.memo((props: BigTextLinkProps) => {
    return (
        <Link
            className={style.link}
            component={ReactLink}
            to={props.route}
            style={{ textDecoration: 'none', color: props.color, ...props.style }}
            onClick={props.disabled ? undefined : props.onClick}
            underline='hover'
        >
            {props.children}
        </Link>
    );
});
