// @ts-strict-ignore
import _ from "lodash";
import { Urls } from "phoenix/constants";
import { OnboardingData } from "phoenix/onboarding/OnboardingTypes";
import { SnexAxios } from "phoenix/stores/AxiosHelpers";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { BaseOnboardingStoreData } from "../Base/BaseOnboardingStore";


interface FuturesOnboardingData extends BaseOnboardingStoreData {
    loaded?: boolean,
    isWelcomed: boolean,
    isReviewed: boolean,
    data: OnboardingData,
    requiredLinks: string[],
    clickedLinks: string[],
    requiredCerts: string [],
    clickedCerts: string[],
    submitting?: boolean,
    acceptArbitration?: boolean,
    setValue: (key: string, value?: any) => void,
    clearData: () => void,
    submit: () => Promise<FuturesOnboardingDataResult>,
    submitResult: 'pristine' | 'loading' | FuturesOnboardingDataResult
}

export const useFuturesOnboardingStore = create<FuturesOnboardingData>()(persist((set, get) => ({ 
    selectedScreenId: 'welcome',
    isWelcomed: false,
    isReviewed: false,
    requiredLinks: [],
    clickedLinks: [],
    requiredCerts: [],
    clickedCerts: [],
    acceptArbitration: undefined,
    data: {
        personalInfo: {
            country: 'US'
        }
    },
    submitResult: 'pristine',
    setValue: (key: string, value?: any) => set((s) => ({..._.set(s, key, value)})),
    clearData: () => set((s) => ({
        selectedScreenId: 'welcome',
        isWelcomed: false,
        isReviewed: false,
        requiredLinks: [],
        clickedLinks: [],
        requiredCerts: [],
        clickedCerts: [],
        acceptArbitration: undefined,
        data: {}
    })),
    submit: async () => {
        set((s) => ({...s, submitResult: 'loading'}))
        const dataToSubmit = get().data
        try
        {
            const response = await SnexAxios.ApiPut<FuturesOnboardingDataResult>(Urls.futuresOnboarding.submitFuturesOnboarding(), {data: dataToSubmit}).run()
            set((s) => ({...s, submitResult: response}))
            return response;
        }
        catch(error)
        {
            const result = { isSuccess: false, onboardingResponse: { errorMessage: error.toString()} }
            set((s) => ({...s, submitResult: result}))
            return result
        }
    }
 }),{ name: 'webFuturesOnboarding',  storage: createJSONStorage(() => localStorage) }))
 
 type FuturesOnboardingDataResult =
 {
    isSuccess: boolean,
    onboardingResponse: {
        applicationId?: string,
        accountId?: string, 
        errorMessage?: string
    }
 }