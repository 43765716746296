const premiumGradientStop1 = '#EC8F4B';
const premiumGradientStop2 = '#FFB84E';

export default {
    bestValue: '#60c',
    black: '#000',
    blackOverlay: 'rgba(0, 0, 0, .4)',
    blueLight: '#7aadd4',
    blurredAdornmentColor: '#a9a9a9',
    cardButtonBorder: '#0d5da3',
    deleteButton: 'rgba(200, 89, 53, 0.85)',
    deleteButtonDark: 'rgb(200, 89, 53)',
    donutChartBorder: '#F4FAFF',
    donutChartSegments: [
        '#2fcd8e', // Minty green
        '#ea9d1e', // Gold
        '#2b69ff', // Blue
        '#e3effd', // "On Cloud 9"
        '#5E2CA8', // Roman's Purple
        '#AFF3DC', // Light Minty Green
        '#f9379d', // Pink
        '#666666', // Devil's Food Gray
        '#f8342e', // Red
        '#DACE50', // Golden Graham
        '#89C6FF', // Light Blue
        '#D8FF6D' // Light Green
    ],
    fadedGold: 'rgba(238,220,154, 0.2)',
    fplGreen: '#13B53F',
    fplGreenOpacity: '#13B53F45',
    futuresBackdropColor: '#28bcd63d',
    futuresColor: '#28BBD6',
    gold: 'rgb(182, 156, 10)',
    goldenTicket: '#E99C23',
    goldenTicketFaded: '#E99C2326',
    goldenTicketLight: '#FCF3E4',
    grayDark: '#333',
    grayText: 'rgb(167, 167, 167)',
    green: 'rgba(44,179,51, 1.0)',
    greenLight: 'rgba(44,179,51, 0.1)',
    moneyGreen: '#090',
    negativeValueLighterColor: 'rgba(178, 41, 46, 0.16)',
    neutralValueColor: 'rgb(218, 206, 80)', // Golden Graham,
    neutralValueLighterColor: 'rgba(218, 206, 80, 0.16)',
    onboardingButtonColor: '#fff',
    orange: 'rgba(233,156,35, 1.0)', // TODO This appears to be the same as goldenTicket
    orangeLight: 'rgba(233,156,35, 0.1)',
    paperless: '#287150',
    positiveValueDarkerColor: 'rgb(0,150,8)',
    positiveValueLighterColor: 'rgba(2,179,52,0.16)',
    premiumGradientStop1,
    premiumGradientStop2,
    premiumGradient: `linear-gradient(${premiumGradientStop1}, ${premiumGradientStop2})`,
    primaryItemLightColor: '#345f948f',
    red: 'rgba(178,41,45, 1.0)',
    redLight: 'rgba(178,41,45, 0.1)',
    segmentedControlActiveColor: '#336094',
    selectListItemColor: 'rgba(51, 96, 148, 0.1)',
    selectListSelectedItemColor: '#346094',
    srri1: '#208125',
    srri2: '#7BB71A',
    srri3: '#C5AB21',
    srri4: '#BE8023',
    srri5: '#C34F0E',
    srri6: '#C42D0F',
    srri7: '#C30E0E',
    submitButton: '#0b6bbf',
    white: '#fff',
    whiteOverlay: 'rgba(255, 255, 255, .6)',
    working: '#5A325F',
    unknown: 'red' // This is essentially a TODO
};
