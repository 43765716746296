import { useFuturesOnboardingStore } from "../../Store"
import { T } from "phoenix/assets/lang/T"
import { OnboardingPageTitle } from "screens/OnboardingScreens/Base/OnboardingControls"
import React from "react"

export const FuturesOnboarding_Welcome_Form = () => {
    const {data} = useFuturesOnboardingStore()
    const title = T(t => t.futuresOnboarding.welcome(data?.contactInfo?.name || ''))
    const subtitle = [T(t => t.futuresOnboarding.welcomeSubtitle)]
    
    return(
        <OnboardingPageTitle title={title} subtitles={subtitle} />
    )
}

