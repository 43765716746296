// @ts-strict-ignore
import * as moment from 'moment-timezone';
import { DelayedPricing } from 'phoenix/hooks/UseIsDelayedPricing';
import { LiveDataGroups } from '../../constants';
import { StandardQuote } from '../../constants/ReduxSelectors';
import { SnexLsItemUpdate } from '../../ls-shim/models/SnexLsItemUpdate';
import { Lightstreamer, WithoutNaNs } from '../../util';
import { QualifiedId } from '../../util/QualifiedId';
import { XstreamState } from '../XstreamState';
import { XstreamReduxSubscribe } from './XstreamActionShared';
import { OptionQuote } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { Dispatch } from 'redux';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';

moment.tz.setDefault('Etc/UTC');

export const XstreamQuoteUpdateActionName = 'XSTREAM_QUOTE_UPDATE';

// prettier-ignore
export const FuturesQuoteFields = [
    'Open', 'HighPrice', 'LowPrice', 'Close', 'CloseTimestamp', 'TotalVol',
    'ChangePercent', 'Change', 'LastDateTime', 'LastPrice', 'BidPrice', 'AskPrice'
]

// prettier-ignore
const EquityQuoteFields = [
    'open', 'close', 'high', 'low', 'change',
    'latestPrice', 'lastTradeTime', 'latestVolume',
    'extendedPrice', 'extendedChange', 'extendedChangePercent', 'extendedPriceTime',
    'iexBidPrice', 'iexAskPrice'
]

const CryptoQuoteFields = ['lastTradeTime', 'iexBidPrice', 'iexAskPrice', 'latestPrice', 'change', 'changePercent'];

export type XstreamQuoteSubscribeActionOptions = Partial<{
    callback?: (update: Partial<StandardQuote>) => void;
    tags?: string[];
}>;

export const XstreamQuoteSubscribeAction = (
    qsi: string,
    namespace: string,
    options?: XstreamQuoteSubscribeActionOptions
): ((dispatch: Dispatch, getState: () => XstreamState) => Promise<string | null>) => {
    const { family, type } = GetAssetClassForSecurity(qsi);

    if (type === 'mutual-fund') return () => null; // Do not attempt to stream mutual funds

    if (family === 'futures' && new FuturesSymbol(qsi)?.baseContract === qsi) {
        console.info(`[XS] Attempted to subscribe to invalid futures base contract symbol ${qsi}. Aborting. Please use a concrete contract symbol`);
        return () => null;
    }

    const { fields, upstream, items, group } = (() => {
        switch (family) {
  
            case 'futures': {
                const noPrefix = QualifiedId.RemovePrefix(qsi);
                const underscored = noPrefix.replace(/\s/g, '_');
                const futuresSuffix = DelayedPricing.futuresAreDelayed() ? ':delayed' : '';
                return {
                    fields: FuturesQuoteFields,
                    items: [`bar:${underscored}:1${futuresSuffix}`, `price:${underscored}${futuresSuffix}`],
                    upstream: 'gain',
                    group: LiveDataGroups.Futures
                };
            }

            case 'cryptos':
            case 'equities':
            default: {
                const equitiesPrefix = DelayedPricing.equitiesAreDelayed() ? 'dquote' : 'quote';
                return {
                    fields: EquityQuoteFields,
                    items: [`${equitiesPrefix}:${qsi}`],
                    upstream: 'snex',
                    group: LiveDataGroups.Quotes
                };
            }
        }
    })();

    return XstreamReduxSubscribe(
        group,
        items,
        XstreamQuoteUpdateActionName,
        fields,
        (u: SnexLsItemUpdate, _, getState) => {
            let quoteUpdate = null;
            switch (upstream) {
                case 'gain':
                    quoteUpdate = createQuoteFromGainUpdate(u, qsi, getState);
                    break;
                case 'snex':
                default:
                    if (family === 'cryptos') quoteUpdate = createQuoteFromSnexCryptoUpdate(u);
                    else quoteUpdate = createQuoteFromSnexUpdate(u);
                    break;
            }

            if (options?.callback) options?.callback(quoteUpdate);
            return quoteUpdate;
        },
        { includeSnapshot: true, mode: 'MERGE', subject: qsi, namespace, upstream: upstream as any }
    );
};

const createQuoteFromSnexCryptoUpdate = (u: SnexLsItemUpdate) => {
    const getValue = (key: string) => Lightstreamer.Helpers.getValueSafe(u, key);

    const quote: Partial<StandardQuote> = {
        lastTradeTime: parseInt(getValue('lastTradeTime')),
        bid: parseFloat(getValue('iexBidPrice')),
        ask: parseFloat(getValue('iexAskPrice')),
        price: parseFloat(getValue('latestPrice')),
        change: parseFloat(getValue('change')),
        changePercent: parseFloat(getValue('changePercent'))
    };

    return WithoutNaNs(quote);
};

const createQuoteFromSnexUpdate = (u: SnexLsItemUpdate) => {
    const getValue = (key: string) => Lightstreamer.Helpers.getValueSafe(u, key);

    const isExtendedHours = !!parseFloat(getValue('extendedPrice'));

    const lPrice = parseFloat(getValue('latestPrice'));
    const ePrice = parseFloat(getValue('extendedPrice'));
    const lChange = parseFloat(getValue('change'));
    const previousClose = lPrice - lChange;
    const changePercent = lChange / previousClose;

    const extendedChange = parseFloat(getValue('extendedChange'));
    const extendedChangePercent = parseFloat(getValue('extendedChangePercent'));
    const eChangeSum = extendedChange + lChange; // Note: "extendedChange" from stream is cumulative based on end-of-day change
    const extendedChangePercentSum = changePercent + extendedChangePercent;

    const [price, change, pct] = isExtendedHours ? [ePrice, eChangeSum, extendedChangePercentSum] : [lPrice, lChange, changePercent];

    const quote: Partial<StandardQuote> = {
        open: parseFloat(getValue('open')),
        close: parseFloat(getValue('close')),
        high: parseFloat(getValue('high')),
        low: parseFloat(getValue('low')),
        price,
        extendedPrice: ePrice,
        extendedChange,
        extendedChangePercent,
        extendedPriceTime: new Date(parseFloat(getValue('extendedPriceTime')))?.getTime(),
        change,
        changePercent: pct,
        lastTradeTime: new Date(parseFloat(getValue('lastTradeTime')))?.getTime(),
        volume: parseFloat(getValue('latestVolume')),
        ask: parseFloat(getValue('iexAskPrice')),
        bid: parseFloat(getValue('iexBidPrice'))
    };

    return WithoutNaNs(quote);
};

// Gain update model supports concrete futures contracts and options contracts
export const createQuoteFromGainUpdate = (u: SnexLsItemUpdate, qsi: string, getState: () => XstreamState, substate: keyof XstreamState = 'quotes') => {
    try {
        const getValue = (key: string) => Lightstreamer.Helpers.getValueSafe(u, key);

        const q = getState()?.[substate]?.[qsi];
        const lastTimeString = getValue('LastDateTime');
        const momentObj = moment.utc(`${lastTimeString}`, 'M/DD/YYYY H:mm:ss A');
        const momentDate = momentObj.toDate()?.getTime();
        const quote: Partial<StandardQuote | OptionQuote> = {
            open: parseFloat(getValue('Open')) || q?.open,
            close: parseFloat(getValue('Close')) || q?.close,
            high: parseFloat(getValue('HighPrice')) || q?.high,
            low: parseFloat(getValue('LowPrice')) || q?.low,
            change: parseFloat(getValue('Change')) || q?.change,
            changePercent: parseFloat(getValue('ChangePercent')) || q?.changePercent,
            price: parseFloat(getValue('LastPrice')) || q?.price,
            volume: parseFloat(getValue('TotalVol')) || q?.volume,
            bid: parseFloat(getValue('BidPrice')) || q?.bid,
            ask: parseFloat(getValue('AskPrice')) || q?.ask,
            extendedPrice: NaN,
            extendedChange: NaN,
            extendedChangePercent: NaN,
            lastTradeTime: lastTimeString ? momentDate : q?.lastTradeTime
        };

        return WithoutNaNs(quote);
    } catch (e) {
        console.log('Error parsing gain streaming payload!', e);
    }
};
