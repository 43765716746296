// @ts-strict-ignore
import { OnboardingInputError, ValidateRequired } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"

export const isNumeric = (value?: string) =>/^-?\d+$/.test(value);

export const EmploymentInfoIsValid = () => getEmploymentInfoErrors()?.length < 1
export const getEmploymentInfoErrors = () => {
    const keys = [
        'data.employmentInfo.status', 
        'data.employmentInfo.name', 
        'data.employmentInfo.jobTitle', 
        'data.employmentInfo.years'
    ]
    const store = useFuturesOnboardingStore.getState()
    const _errors = []  as OnboardingInputError[]
    keys.forEach(key => {
        switch(key) {
            case 'data.employmentInfo.status':
                _errors.push(ValidateRequired(store, key, 'Please select an employment status'))
                break;
            case 'data.employmentInfo.name':
            case 'data.employmentInfo.jobTitle':
                if (store.data.employmentInfo?.status?.toLowerCase() === 'employed'){
                    _errors.push(ValidateRequired(store, key));
                }
                break;
            case 'data.employmentInfo.years':
                if (store.data.employmentInfo?.status?.toLowerCase() === 'employed') {
                    _errors.push(ValidateRequired(store, key));

                    if (!isNumeric(store.data?.employmentInfo?.years)) {
                        _errors.push({key, error: 'Years must be a whole number'});
                    }
                }
                break;
        }
    });
    return _errors.filter(e => !!e);
}