import { Flex } from "components/Flex"
import { LoadingSpinner } from "components/LottieAnimations"
import { useAsyncEffect } from "phoenix/hooks/UseAsyncEffect"
import React, { useState } from "react"

import Axios from 'axios';
import { ResolveBackend } from "phoenix/constants"
import { SnexButton } from "components/SnexButton"
import { IconButton, Typography } from "@mui/material"
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SnexAxios } from "phoenix/stores/AxiosHelpers"
import { BaseScreenLayout } from "components/Layouts/BaseScreenLayout";

interface webToken {accessToken: string, vulcanToken: string, refreshToken: string, serverTime: string}

export const WebToken = () => {
    const [tokenJson, setTokenJson] = useState<webToken>()
    const rootUrl = ResolveBackend()


    
    useAsyncEffect(async () => {
        const json = await SnexAxios.ApiGet<webToken>(`${rootUrl}/token`).withMutex('web-token').run()
        console.log(json)
        setTokenJson(json)
    }, [])

    const CopyAll = (value: string) => {
        navigator.clipboard.writeText(value)
    }

    return(
        !tokenJson ?  
            <Flex column center justify='center' fullWidth style={{height: '100%'}}>
                <LoadingSpinner size={200}/>
            </Flex> :
            <BaseScreenLayout hideGlance>
             <Flex column fullWidth style={{wordBreak: 'break-all', padding: '16px 64px', marginTop: '16px', gap: '1rem', cursor: 'pointer', position: 'relative'}}>
                <Flex row align='center' onClick={() => CopyAll(tokenJson.accessToken)}><IconButton title={'Copy token to clipboard'} style={{marginRight: '1rem'}}><ContentCopyIcon/></IconButton><Typography variant="h4">Access Token:</Typography></Flex>
                <Flex row>{tokenJson.accessToken}</Flex>
                <Flex row align='center' onClick={() => CopyAll(tokenJson.vulcanToken)}><IconButton title={'Copy token to clipboard'} style={{marginRight: '1rem'}}><ContentCopyIcon/></IconButton><Typography variant="h4">Vulcan Token:</Typography></Flex>
                <Flex row>{tokenJson.vulcanToken}</Flex>
            </Flex>
            </BaseScreenLayout>
    )
}