// @ts-strict-ignore
import { Flex } from "components"
import { OnboardingInputRow } from "components/Onboarding"
import { useText } from "phoenix/hooks/UseText"
import React from "react"
import { AllowedFuturesCountryOptions } from "screens/OnboardingScreens/_constants/DropdownOptions/CountryOptions"
import { OnboardingDropdown, OnboardingTextField } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFuturesOnboardingStore } from "../../Store"
import { getContactInfoErrors } from "./Validation"
import { StatesOptions } from "screens/OnboardingScreens/_constants/DropdownOptions/StatesOptions"

export const FuturesOnboarding_ContactInfo_Form = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const handleChange = (key: string, value: string) => {
        setValue(key, value)
    }
    const text = useText(t => t.futuresOnboarding.contactInfo)
    const getErrors = (key: string) => {
        return getContactInfoErrors()?.filter(e => e?.key?.toLowerCase() === key.toLowerCase())
    }
    return(
        <Flex column fullWidth center style={{gap: '1rem'}}>
            <OnboardingInputRow noPadding>
                <OnboardingTextField name='data.contactInfo.firstName' label={text.firstName} value={data.contactInfo?.firstName || ''} handleChange={handleChange} errors={getErrors('data.contactInfo.firstName')}/>
                <OnboardingTextField name='data.contactInfo.lastName' label={text.lastName} value={data.contactInfo?.lastName || ''} handleChange={handleChange} errors={getErrors('data.contactInfo.lastName')}/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingTextField name='data.contactInfo.email' label={text.email} value={data.contactInfo?.email || ''} handleChange={handleChange} errors={getErrors('data.contactInfo.email')}/>
                <OnboardingTextField name='data.contactInfo.phone' label={text.phone} value={data.contactInfo?.phone || ''} handleChange={handleChange} errors={getErrors('data.contactInfo.phone')}/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingTextField name='data.contactInfo.street' maxLength={32} label={text.street} value={data.contactInfo?.street || ''} handleChange={handleChange} errors={getErrors('data.contactInfo.street')}/>
                <OnboardingTextField name='data.contactInfo.street2' maxLength={32} label={text.street2} value={data.contactInfo?.street2 || ''} handleChange={handleChange} errors={getErrors('data.contactInfo.street2')}/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingTextField name='data.contactInfo.city' label={text.city} value={data.contactInfo?.city || ''} handleChange={handleChange} errors={getErrors('data.contactInfo.city')}/>
                <OnboardingDropdown name='data.contactInfo.state' value={data?.contactInfo?.state} options={StatesOptions} label={text.state} handleChange={handleChange} errors={getErrors('data.contactInfo.state')}/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingTextField name='data.contactInfo.zip' label={text.zip} value={data.contactInfo?.zip || ''} handleChange={handleChange} errors={getErrors('data.contactInfo.zip')}/>
                <OnboardingDropdown name='data.contactInfo.country' options={AllowedFuturesCountryOptions} label={text.country} value={data.contactInfo?.country} handleChange={handleChange} errors={getErrors('data.contactInfo.country')}/>
            </OnboardingInputRow>
        </Flex>
    )
}