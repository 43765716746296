// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { ContactInfoIsValid } from "../ContactInfo/Validation"
import { EmploymentInfoIsValid } from "../EmploymentInfo/Validation"
import { FinancialInfo1IsValid, FinancialInfo2IsValid } from "../FinancialInfo/Validation"
import { PersonalInfoIsValid } from "../PersonalInfo/Validation"
import { TradingExperienceIsValid } from "../TradingExperience/Validation"
import { FuturesOnboarding_Affiliations_Form } from "./Form"
import { AffiliationIsValid, getAffiliationErrors } from "./Validation"

export const futuresOnboarding_affiliations_step: OnboardingStep = {
    label: "Affiliations",
    isComplete: () => AffiliationIsValid(),
    isEnabled: () => TradingExperienceIsValid() && EmploymentInfoIsValid() && FinancialInfo1IsValid() && FinancialInfo2IsValid() && PersonalInfoIsValid() && ContactInfoIsValid() 
        && useFuturesOnboardingStore.getState().isWelcomed,
    isCurrent: () => ['affiliations'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'affiliations'
}

export const futuresOnboarding_affiliations_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'regulatoryInfo'),
    isPrimary: true,
    isEnabled: () =>  AffiliationIsValid() && TradingExperienceIsValid() && EmploymentInfoIsValid() && FinancialInfo1IsValid() && FinancialInfo2IsValid() 
        && PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_affiliations_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => 
            useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'tradingExperience'),
    isPrimary: false,
    isEnabled: () => true
}


export const futuresOnboarding_affiliations_screen: OnboardingScreen = {
    id: "affiliations",
    validate: getAffiliationErrors,
    buttons: [futuresOnboarding_affiliations_prevButton, futuresOnboarding_affiliations_nextButton],
    title: T(t => t.futuresOnboarding.affiliationsInfo.title),
    subtitles: null,
    body: <FuturesOnboarding_Affiliations_Form/>
}