// @ts-strict-ignore
import { addDays, format, formatDistanceToNowStrict, isSameDay, isThisWeek, isThisYear, isToday, parseISO } from 'date-fns';
import { formatInTimeZone as formatTz, utcToZonedTime } from 'date-fns-tz';
import { ApiTimeInForce } from 'phoenix/models/ApiTradeRequest';
import {
    GetClosedSimulatedHours,
    GetHalfHolidaySimulatedHours,
    GetHolidaySimulatedHours,
    GetMaintenanceSimulatedHours,
    GetMarketSimulatedHours,
    GetNonWithdrawableSimulatedHours,
    GetPostmarketSimulatedHours,
    GetPremarketSimulatedHours,
    GetWeekendSimulatedHours
} from '.';
import { Ts } from '../assets/lang/T';
import { MarketHoliday } from '../redux/models/Market/MarketHoliday';
import { SetCacheTime } from './CacheManager';
import { useMarketTimeSegmentV2 } from 'phoenix/hooks/useMarketTimeSegment';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { useMemo } from 'react';

// ====
// TODO -- Deprecate these direct function exports and instead organize them under a single "FormatDate" export... but only if it makes sense for them to be there!
//         Some of these function are not for formatting. Those should have their own export in a separate file
//         Use @deprecated flag to point developers in the right direction where those functions have been re-homed
//         The already-deprecated functions don't have to move, but their deprecation messages should be updated to make sure they point developers to the right place
// ====

export const WeekendDays = new Set([0, 6]);

export const Months = () => Ts((s) => s.general.months);

/** @deprecated Please use SafeFormat instead */
export const FormatFullDate = (): string => {
    // export const FormatFullDate = (date: string | number | Date): string => {
    // try {
    return 'date-fns-tz is breaking my build so I am disabling this for now!!';
    // return format(new Date(date), 'yyyy/MM/dd hh:mm:ss aa z', { timeZone: tzName });
    // }
    // } catch {
    //     return 'Invalid Date';
    // }
};

export const TodayAtTime = (hour: number, minute: number): Date => new Date(new Date(new Date().setHours(hour)).setMinutes(minute));

export const SafeFormat = (
    date: string | Date,
    fmt: string,
    defaultValue = '---',
    opts?: {
        ianaTzName: string; // https://www.iana.org/time-zones
    }
): string => {
    const locale = Ts((s) => s.general.dateFnsLocale);

    return SafeFormatLocale({
        date,
        defaultValue,
        formatString: fmt,
        ianaTzName: opts?.ianaTzName,
        locale
    });
};

// Stateless
export const SafeFormatLocale = ({
    date,
    defaultValue = '---',
    formatString,
    ianaTzName,
    locale
}: {
    date: string | Date;
    defaultValue?: string;
    formatString: string;
    ianaTzName?: string; // https://www.iana.org/time-zones
    locale: Locale;
}): string => {
    const d = typeof date === 'string' ? new Date(date) : date;
    const isInvalid = !d || isNaN(d.getTime());
    if (isInvalid) return defaultValue;
    try {
        if (ianaTzName) return formatTz(d, ianaTzName, formatString, { locale });
        return format(d, formatString, { locale });
    } catch (error) {
        return defaultValue;
    }
};

export const SafeFormatToNow = (date: string | Date, defaultValue = '---', noSuffix?: boolean, unit?: 'second' | 'minute' | 'hour' | 'day') => {
    const d = typeof date === 'string' ? new Date(date) : date;
    const isInvalid = !d || isNaN(d.getTime()) || d.getFullYear() < 1980;
    return isInvalid ? defaultValue : formatDistanceToNowStrict(d, { addSuffix: !noSuffix, unit, locale: Ts((s) => s.general.dateFnsLocale) });
};

export const SafeFormatToNowAbbrev = (date: string | Date, defaultValue = '---', noSuffix?: boolean, unit?: 'second' | 'minute' | 'hour' | 'day') => {
    const full = SafeFormatToNow(date, defaultValue, true, unit);
    return noSuffix ? full.replace(/\D+/g, '') : full.replace(/^about/, '').replace(/(\d+)\s(.).*/, '$1$2');
};

export const FormatDateAdaptive = (date: string | number | Date, defaultValue = '---') => {
    if (!date) return defaultValue;
    const d = normalizeDate(date);
    if (isToday(d)) return SafeFormat(d, 'hh:mm aa'); // 1:47 PM
    if (isThisWeek(d)) return SafeFormat(d, 'EEE'); // Mon, Tue, Wed, etc.
    if (isThisYear(d)) return SafeFormat(d, 'dd MMM'); // 20 Oct
    return SafeFormat(d, 'dd MMM yy'); // 20 Oct 22
};

const normalizeDate = (date: string | number | Date): Date => {
    if (!date) return new Date(NaN);
    if ((date as any).getTime) return date as Date;
    const t = typeof date;
    if (t === 'string') return parseISO(date as string);
    if (t === 'number') return new Date(date);
    return new Date(NaN);
};

const msPerDay = 1000 * 60 * 60 * 24;
export const FormatDaysFromNow = (date: string | Date): string => {
    const then = new Date(date).getTime();
    const now = new Date().getTime();
    const days = Math.ceil(Math.abs(then - now) / msPerDay);
    return formatDistanceToNowStrict(addDays(now, days), { addSuffix: true, unit: 'day', locale: Ts((s) => s.general.dateFnsLocale) });
};

export const Cymd = (date: Date) => (date ? `${date.getFullYear()}${('00' + date.getMonth()).slice(-2)}${('00' + date.getDate()).slice(-2)}` : null);

/** @deprecated Please use GetNewYorkTimeChunks(dt?: number | Date | string). If you really want to get a specific timezone, please use GetTimeChunksForTimezone(tz: string, dt?: number | Date | string) */
export const GetTimeChunksForTz = (dt?: number | Date | string, timeZone = 'America/New_York') => GetTimeChunksForTimezone(timeZone, dt);

export const GetNewYorkTimeChunks = (dt?: number | Date | string) => GetTimeChunksForTimezone('America/New_York', dt);

export const GetTimeChunksForTimezone = (
    timeZone: string,
    dt?: number | Date | string
): { year: number; month: number; dayOfMonth: number; hour: number; minute: number; second: number; dayOfWeek: number } => {
    // Date object initialized as per New York timezone. Returns a datetime string
    const easternNow = utcToZonedTime(dt || new Date(), timeZone);

    const year = easternNow.getFullYear(); // year as (YYYY) format
    const month = easternNow.getMonth() + 1; // month as (MM) format
    const dayOfMonth = easternNow.getDate(); // date as (DD) format
    const hour = easternNow.getHours(); // hours as (HH) format
    const minute = easternNow.getMinutes(); // minutes as (mm) format
    const second = easternNow.getSeconds(); // seconds as (ss) format
    const dayOfWeek = easternNow.getDay(); // day of the week (0-6) format

    return { year, month, dayOfMonth, hour, minute, second, dayOfWeek };
};

export type MarketTimeSegment = 'open' | 'premarket' | 'postmarket' | 'closed' | 'holiday' | 'loading';

// Gets around platform-specific date formatting issues.
type SymbolicDateFormatter = (data?: { year: number; month: number; day: number; isCurrentYear: boolean; monthNameShort: string; year2d: string }) => any;
const DateDataNone = { year: 0, month: 0, day: 0, isCurrentYear: false, monthNameShort: 'Unknown Month', year2d: 'XX' };
export const FormatIsoDateSymbolic = <TOut>(src: string, format: SymbolicDateFormatter): TOut => {
    if (!src) return format(DateDataNone);
    try {
        const isoDatePattern = /(\d{4})-(\d{2})-(\d{2})/;
        const cYear = new Date().getFullYear();
        const [year, month, day] =
            src
                .match(isoDatePattern)
                ?.slice(1)
                .map((x) => parseInt(x)) || [];
        if (!year) return format(DateDataNone);
        const monthNameShort = Ts((s) => s.general.monthsShort)[month - 1];
        const year2d = year.toString().slice(2);
        return format({ year, month, day, isCurrentYear: cYear === year, monthNameShort, year2d });
    } catch (e) {
        console.log(`Unable to process date ${src}: ${e.stack}`);
        return format(DateDataNone);
    }
};

/** @deprecated This should only be used for development purposes in order to manipulate the market timing. Please use market time segment from redux `<Store>.market.status` to get official application market time segments */
export const GetMarketTime = (dt?: number | Date | string): MarketTimeSegment => {
    const { hour, minute, dayOfWeek } = GetNewYorkTimeChunks(dt || undefined);

    // For debugging
    if (GetPremarketSimulatedHours()) {
        SetCacheTime('_mkt_seg');
        return 'premarket';
    }
    if (GetMarketSimulatedHours()) {
        SetCacheTime('_mkt_seg');
        return 'open';
    }
    if (GetPostmarketSimulatedHours()) {
        SetCacheTime('_mkt_seg');
        return 'postmarket';
    }
    if (GetClosedSimulatedHours()) {
        SetCacheTime('_mkt_seg');
        return 'closed';
    }
    if (GetMaintenanceSimulatedHours()) {
        SetCacheTime('_mkt_seg');
        return 'closed';
    }
    if (GetWeekendSimulatedHours()) {
        SetCacheTime('_mkt_seg');
        return 'closed';
    }
    if (GetNonWithdrawableSimulatedHours()) {
        SetCacheTime('_mkt_seg');
        return 'closed';
    }

    const holiday = GetCurrentHoliday();
    if (holiday === 'loading') return 'loading';
    if (holiday && (!holiday.halfDay || (holiday.halfDay && hour >= 13))) {
        SetCacheTime('_mkt_seg');
        return 'holiday';
    }

    const isWeekDay = dayOfWeek > 0 && dayOfWeek < 6;
    if (!isWeekDay) {
        SetCacheTime('_mkt_seg');
        return 'closed';
    }

    const isBefore = (tHour: number, tMinute: number) => hour < tHour || (hour === tHour && minute < tMinute);

    const val = (() => {
        if (isBefore(8, 0)) return 'closed'; // 8:00 AM Eastern (New York)
        else if (isBefore(9, 30)) return 'premarket'; // 9:30 AM
        else if (isBefore(16, 0)) return 'open'; // 4:00 PM
        else if (isBefore(17, 0)) return 'postmarket'; // 5:00 PM
        else return 'closed';
    })();

    SetCacheTime('_mkt_seg');
    return val;
};

/** @deprecated Please use IsNonEditableTradeTime */
export const IsNonEditableTime = () => IsNonEditableTradeTime();

export const IsNonEditableTradeTime = (tif?: ApiTimeInForce): boolean => {
    const isGtc = ['GTC', 'GoodTillCancel'].includes(tif);

    // For debugging
    if (GetClosedSimulatedHours() || GetHalfHolidaySimulatedHours() || GetHolidaySimulatedHours() || GetWeekendSimulatedHours()) return true;
    if (!isGtc && GetPostmarketSimulatedHours()) return true;

    const { hour, minute } = GetNewYorkTimeChunks();
    // GTC orders can be edited until 5pm EST, DAY orders can only be edited until 4pm EST
    const startHour = isGtc ? 18 : 17;
    const isAfterStart = hour >= startHour;
    const isBeforeEnd = hour === 7 ? minute <= 56 : hour < 7;

    return isAfterStart || isBeforeEnd;
};

/** @deprecated Please use IsBetaMaintenanceTime */
export const IsMaintenanceTime = () => IsBetaMaintenanceTime();

// Beta (BLServer) is unavailable to process trades from 12AM to 2AM EST. When we stop using Beta we won't have to worry
// about this anymore
export const IsBetaMaintenanceTime = () => {
    // For debugging
    if (GetMaintenanceSimulatedHours()) return true;
    const { hour } = GetNewYorkTimeChunks();
    return hour >= 0 && hour < 2; // Between 12AM and 2AM, New York time
};

export const useIsMaintenanceTime = (ac?: AssetClass) => {
    const [segment] = useMarketTimeSegmentV2()
    return useMemo(() => {
        if(ac.family !== 'equities' || ac.type === 'mutual-fund') return false;
        return IsBetaMaintenanceTime()
    }, [segment, ac.family, ac.type]);
    
}

export const NonWithdrawableTimeRange = [14, 7]; // In hours, New York time

export const IsNonWithdrawableTime = () => {
    // For debugging
    if (GetNonWithdrawableSimulatedHours() || GetHalfHolidaySimulatedHours() || GetHolidaySimulatedHours() || GetWeekendSimulatedHours()) return true;

    const { hour } = GetNewYorkTimeChunks();
    return hour >= NonWithdrawableTimeRange[0] || hour <= NonWithdrawableTimeRange[1]; // After 2PM or before 7AM, New York time
};

export const NonDepositableTimeRange = [17, 18]; // In hours, New York time

export const IsNonDepositableTime = () => {
    const { hour } = GetNewYorkTimeChunks();
    return hour >= NonDepositableTimeRange[0] && hour <= NonDepositableTimeRange[1]; // After 5PM and before 6AM, New York time
};

export const GetFundingUnlockedTimeCountdown = (direction: 'withdraw' | 'deposit') => {
    const timeRange = direction === 'withdraw' ? NonWithdrawableTimeRange : NonDepositableTimeRange;
    if (direction === 'deposit') return TodayAtTime(timeRange[1], 0);
    else {
        const { hour } = GetNewYorkTimeChunks();
        if (hour > timeRange[0]) return addDays(TodayAtTime(timeRange[1], 0), 1);
        else if (hour < timeRange[1]) return TodayAtTime(timeRange[1], 0);
    }
    return null;
};

export const IsWeekend = (dt?: number | Date | string) => {
    // For debugging
    if (GetWeekendSimulatedHours()) return true;

    const { dayOfWeek, hour } = GetNewYorkTimeChunks(dt || undefined);
    return WeekendDays.has(dayOfWeek) || (dayOfWeek === 1 && hour < 8);
};

let _holidays: MarketHoliday[] = [];
export const SetHolidayCache = (holidays: MarketHoliday[]) => (_holidays = holidays);
export const GetCurrentHoliday = (): MarketHoliday | null | 'loading' => {
    // For debugging
    if (GetHolidaySimulatedHours()) return { id: 'simulated-holiday', name: 'Simulated Holiday', date: '2022-01-10T00:00:00Z', halfDay: false };
    if (GetHalfHolidaySimulatedHours()) return { id: 'simulated-half-day', name: 'Simulated Half Day', date: '2022-01-10T00:00:00Z', halfDay: true };

    if (!_holidays?.length) return 'loading';
    const now = new Date();
    return _holidays.find((h) => isSameDay(new Date(h?.date), now));
};

export const IsHoliday = (d: Date): boolean => !!(_holidays?.length && _holidays.find((h) => isSameDay(new Date(h?.date), d)));

export const GetCurrentRelevantTimeFrame = (): 'previous-day' | 'today' => {
    if (IsWeekend() || !!GetCurrentHoliday()) return 'previous-day';
    const segment = GetMarketTime();
    if (segment === 'premarket' || segment === 'closed') return 'previous-day';
    return 'today';
};

export const IsMarketOpen = (dt?: number | Date | string, useExtendedHours?: boolean): boolean => {
    const { dayOfMonth: domToday } = GetNewYorkTimeChunks();
    const { dayOfMonth: domDtPassed } = GetNewYorkTimeChunks(dt);
    if (domToday !== domDtPassed) return false;
    const segment = GetMarketTime(dt ? new Date(dt) : undefined);
    const closedSegments = new Set(['closed', 'holiday']);
    return useExtendedHours ? !closedSegments.has(segment) : segment === 'open';
};

export const CompareDates = (then: string | Date, now?: string | Date) => new Date(then).getTime() - (now ? new Date(now) : new Date()).getTime();

export const MinutesSinceClose = () => {
    const { hour, minute } = GetNewYorkTimeChunks();
    const hoursSince = hour - 16;
    return hoursSince * 60 + minute;
};

export const MinutesSincePreMarketOpen = () => {
    const { hour, minute } = GetNewYorkTimeChunks();
    const hoursSince = hour - 8;
    return hoursSince * 60 + minute;
};
