// @ts-strict-ignore
import { Typography } from '@mui/material';
import { Flex } from 'components';
import { useText } from 'phoenix/hooks/UseText';
import React, { useEffect } from 'react';
import { DocumentLink } from 'phoenix/onboarding/OnboardingTypes';
import { useFuturesOnboardingStore } from '../../Store';
import { Check } from '@mui/icons-material';
import { useColors } from 'hooks/UseColors';
import { OnboardingErrorPopover } from 'screens/OnboardingScreens/Base/OnboardingControls';
import { T } from 'phoenix/assets/lang/T';

export const FuturesOnboarding_Agreements_Form = () => {
    const { data, setValue, requiredLinks, clickedLinks } = useFuturesOnboardingStore();
    const text = useText((t) => t.futuresOnboarding);
    const colors = useColors();

    const part1Docs: DocumentLink[] = [{ text: text.documents.privacyPolicy, url: text.documents.blobStorageUrls.privacyPolicies }];

    const part2Docs: DocumentLink[] = [
        { text: text.documents.commoditiesFuturesRiskDisclosure, url: text.documents.blobStorageUrls.commodityFuturesTradingComissionRiskDisclosure },
        { text: text.documents.commoditiesOptionsRiskDisclosure, url: text.documents.blobStorageUrls.optionsDisclosureStatement },
        {
            text: text.documents.addlDisclosures,
            url: text.documents.blobStorageUrls.acknowledgementOfAdditionalDisclosures,
            subText: [
                text.documents.subText.nfaRiskDisclosure,
                text.documents.subText.addlRiskDisclosure,
                text.documents.subText.materialConflicts,
                text.documents.subText.electronicTrading,
                text.documents.subText.noticeToOmnibus,
                text.documents.subText.underlyingOrSpot,
                text.documents.subText.clearedSwaps
            ]
        },
        { text: text.documents.virtualCurrencyRiskDisclosure, url: text.documents.blobStorageUrls.virtualCurrencyRiskStatements }
    ];

    const part3Docs: DocumentLink[] = [
        { text: text.documents.stoneXCustomerAgreement, url: text.documents.blobStorageUrls.futuresAndExchangeTradedOptionsCustomerAgreement },
        { text: text.documents.platformAgreement, url: text.documents.blobStorageUrls.platformUserAgreement },
        { text: text.documents.arbitrationAgreement, url: text.documents.blobStorageUrls.arbitrationAgreement }
    ];

    const part4Docs: DocumentLink[] = [{ text: text.documents.marketDataSubscriptionTerms, url: text.documents.blobStorageUrls.futuresMarketDataSubscriptionTerms }];

    useEffect(() => {
        if (!requiredLinks?.length) {
            const _requiredLinks = part1Docs
                .map((d) => d.url)
                .concat(part2Docs.map((d) => d.url))
                .concat(part3Docs.map((d) => d.url))
                .concat(part4Docs.map((d) => d.url))
                .sort((a, b) => (a > b ? 1 : 0));
            setValue('requiredLinks', _requiredLinks);
            setValue('clickedLinks', []);
        }
    }, [requiredLinks]);

    const checkStyle: React.CSSProperties = { color: colors.green, width: '20px', height: '20px' };

    const handleClick = (url: string) => {
        if (!clickedLinks.includes(url)) setValue('clickedLinks', [...clickedLinks, url]);
        window.open(url, '_blank');
    };
    const icon = (url: string) => {
        const hasClicked = clickedLinks.includes(url);
        return (
            <Flex row align='center' style={{ position: 'relative', marginRight: '1rem' }}>
                {hasClicked ? (
                    <Check style={checkStyle} />
                ) : (
                    <OnboardingErrorPopover errors={[{ key: 'required', error: T((t) => t.webRegistration.errors.required) }]} />
                )}
            </Flex>
        );
    };

    const documentSection = (docsArray: DocumentLink[]) => {
        return (
            <Flex column fullWidth>
                <ul style={{ listStyle: 'none' }}>
                    {docsArray?.map((d) => (
                        <li style={{ cursor: 'pointer', paddingBottom: '.5rem' }} onClick={() => handleClick(d.url)}>
                            <Flex row align='center'>
                                {icon(d.url)}
                                <Typography style={{ fontWeight: 'bold', fontSize: '14px', textDecoration: 'underline' }}>{d.text}</Typography>
                            </Flex>
                            {d?.subText && (
                                <Flex row style={{ marginTop: '.5rem' }}>
                                    <ul>
                                        {d?.subText.map((s) => (
                                            <li>{s}</li>
                                        ))}
                                    </ul>
                                </Flex>
                            )}
                        </li>
                    ))}
                </ul>
            </Flex>
        );
    };

    return (
        <Flex column style={{ padding: '0' }}>
            <Flex column style={{ padding: '1rem', marginBottom: '2rem' }}>
                <Flex row style={{ marginBottom: '1rem' }}>
                    <Typography variant='h6'>{text.agreements.titleText}</Typography>
                </Flex>
                <Flex row>
                    <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                        {text.agreements.part1Title}
                    </Typography>
                </Flex>
                {documentSection(part1Docs)}
                <Flex row>
                    <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                        {text.agreements.part2Title}
                    </Typography>
                </Flex>
                {documentSection(part2Docs)}
                <Flex row>
                    <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                        {text.agreements.part3Title}
                    </Typography>
                </Flex>
                {documentSection(part3Docs)}
                <Flex row>
                    <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                        {text.agreements.part4Title}
                    </Typography>
                </Flex>
                {documentSection(part4Docs)}
            </Flex>
        </Flex>
    );
};
