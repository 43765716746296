import { Button, Typography } from '@mui/material';
import { AccountBalanceRounded, AttachMoneyRounded } from '@mui/icons-material';
import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import { Flex, StyledIcon } from 'components';
import { DisclosureFooter } from 'components/DisclosureFooter/DisclosureFooter';
import { Routes } from 'util/Routes';
import { SetPageNotFound } from 'hooks/UsePageNotFound';
import { UseSecondaryButtonStyles, UseSubmitButtonStyles } from '../../theming';
import { useText } from 'phoenix/hooks/UseText';
import { useColors } from 'hooks/UseColors';
import { Link } from 'react-router-dom';
import animationData from 'phoenix/assets/animations/NotFound.json';
import { useAppTheme } from 'hooks/UseAppTheme';

const NotFoundScreen = React.memo(({ security }: { security?: boolean }) => {
    const SIZE = 300;
    const colors = useColors();
    const text = useText((s) => s.notFoundSceen);
    const [appTheme, setAppTheme] = useAppTheme();
    const searchButtonStyles = UseSubmitButtonStyles(colors)();
    const secondaryButtonStyles = UseSecondaryButtonStyles(colors)();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        SetPageNotFound(true);
        return () => {
            SetPageNotFound(false);
        };
    }, []);

    return (
        <Flex center column style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
            <Lottie height={SIZE} {...defaultOptions} width={SIZE * 1.1} />
            <Flex center column style={{ width: '100%', marginTop: 20 }}>
                <Typography style={{ width: '100%', textAlign: 'center', marginBottom: 25, fontSize: 25, color: appTheme === 'dark' ? 'white' : 'black' }} variant='h6'>
                    {security ? text.securityHeader : text.header}
                </Typography>
                <Typography style={{ width: '100%', textAlign: 'center', marginBottom: 5, fontSize: 15, color: 'grey' }} variant='body2'>
                    {text.otherOptions}
                </Typography>
            </Flex>
            <Flex justify='space-between' style={{ marginTop: 10 }}>
                <Link to={Routes.portfolio()}>
                    <Button classes={secondaryButtonStyles} style={{ padding: 10, width: 200, height: 45 }}>
                        <StyledIcon IconComponent={AccountBalanceRounded} size={20} style={{ color: colors.notAllowedTextColor }} />
                        <span style={{ marginLeft: 5 }}>{text.portfolioButton}</span>
                    </Button>
                </Link>
                <Link to={Routes.positions()}>
                    <Button classes={searchButtonStyles} style={{ marginLeft: 15, padding: 10, height: 45, width: 200 }}>
                        <StyledIcon IconComponent={AttachMoneyRounded} size={20} style={{ color: 'white' }} />
                        <span style={{ marginLeft: 5 }}>{text.positionsButton}</span>
                    </Button>
                </Link>
            </Flex>
            <DisclosureFooter style={{ maxWidth: 750 }} />
        </Flex>
    );
});

export default NotFoundScreen;
