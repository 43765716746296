import React, { useEffect } from "react"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Typography } from "@mui/material";
import { Flex } from "components";
import { FormatNumber } from "phoenix/util";
import { GetWatchlistsAction } from "phoenix/redux/actions";
import { useAppDispatch } from "AppRoot";
import { useSnexStore } from "phoenix/hooks/UseSnexStore";
import { BaseScreenLayout } from "components/Layouts/BaseScreenLayout";




export const NeilDebugScreen = (): JSX.Element => {

  return (
    <BaseScreenLayout sidebar={<></>}>
      <></>
    </BaseScreenLayout>
  )
   
}









