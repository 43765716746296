import { Badge, CircularProgress, Typography } from '@mui/material';
import React, { MouseEvent, ReactNode, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '..';
import { NoUserImageB64 } from 'phoenix/constants/NoUserImageB64';
import { GetUserInfoAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { MakePlaceholderContent, MoneyGreen } from 'phoenix/util';
import './UserAvatar.scss';
import { useMessagesStore } from 'phoenix/stores/MessagesStore';

interface UserAvatarProps {
    size?: number;
    style?: React.CSSProperties;
    children?: ReactNode;
    userId?: string;
    unknown?: boolean;
    spinning?: boolean;
    onClick?: (e?: MouseEvent<HTMLDivElement>) => void;
}

export const UserAvatar = React.memo((props: UserAvatarProps) => {
    const dispatch = useDispatch();
    const info = useSelector((s: GlobalState) => {
        if (props.unknown) return null;
        else if (props.userId) return s.user.byId[props.userId]?.info;
        return s.user.myInfo;
    });

    useEffect(() => {
        if (props.userId) dispatch(GetUserInfoAction(props.userId));
    }, [props.userId]);

    const size = props.size || 40;
    const sizeStyles = { height: size, width: size, margin: 3 };
    const { unread: unreadCount } = useMessagesStore();

    const { styles, content }: { styles: React.CSSProperties; content?: any } = useMemo(() => {
        if (info?.data?.image) return { styles: { background: `transparent url(data:image/jpeg;base64,${info?.data?.image})`, backgroundSize: 'contain' } };
        else if (info?.data?.name && !props.children) {
            const { color, initials } = MakePlaceholderContent(info?.data?.name, false);
            return {
                styles: { background: color, display: 'flex', justifyContent: 'center', alignItems: 'center' },
                content: (
                    <Typography style={{ fontWeight: 400, color: 'white' }} variant='h5'>
                        {initials}
                    </Typography>
                )
            };
        } else return { styles: { background: `transparent url(data:image/jpeg;base64,${NoUserImageB64})`, backgroundSize: 'contain' } };
    }, [info?.data?.image]);

    return (
        <Flex column onClick={props?.onClick}>
            <Badge badgeContent={unreadCount || 0} className='stonex-header-nav-badge' color='primary' invisible={(unreadCount || 0) < 1} tabIndex={0}>
                <div
                    className='stonex-avatar'
                    style={{
                        ...sizeStyles,
                        ...styles,
                        ...props.style
                    }}
                >
                    {props.children ? props.children : content}
                </div>
            </Badge>
            {props.spinning ? (
                <CircularProgress
                    size={size + 4}
                    style={{
                        position: 'relative',
                        marginTop: -(size + 5),
                        color: MoneyGreen,
                        marginLeft: 1,
                        zIndex: -1
                    }}
                />
            ) : (
                props.children
            )}
        </Flex>
    );
});
