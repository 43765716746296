// @ts-strict-ignore
import { Flex } from "components"
import { useText } from "phoenix/hooks/UseText"
import React from "react"
import { OnboardingInputRow, OnboardingLottieForm, OnboardingTextField } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFuturesOnboardingStore } from "../../Store"
import { getEmploymentInfoErrors } from "./Validation"
import animationData from 'phoenix/assets/animations/Employee.json'


export const FuturesOnboarding_EmploymentInfo_Form2 = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding.employmentInfo)

    const handleChange = (key: string, value: string) => {
        console.log({key, value})
        setValue(key, value)
    }
    const getErrors = (key: string) => {
        return getEmploymentInfoErrors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
    }

    return(
        <OnboardingLottieForm data={animationData} loop={false}>
            <Flex column center fullWidth  style={{gap: '1rem', }}>
                <OnboardingInputRow noPadding>
                <OnboardingTextField label={text.name} value={data.employmentInfo?.name || ''} 
                    name='data.employmentInfo.name' handleChange={handleChange} errors={getErrors('data.employmentInfo.name')}/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingTextField label={text.jobTitle} value={data.employmentInfo?.jobTitle || ''} 
                    name='data.employmentInfo.jobTitle' handleChange={handleChange} errors={getErrors('data.employmentInfo.jobTitle')}/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingTextField label={text.years} value={data.employmentInfo?.years || ''} 
                    name='data.employmentInfo.years' handleChange={handleChange} errors={getErrors('data.employmentInfo.years')}/>
            </OnboardingInputRow>
            </Flex> 
        </OnboardingLottieForm>
    )
}