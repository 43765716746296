// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { AffiliationIsValid } from "../Affiliations/Validation"
import { ContactInfoIsValid } from "../ContactInfo/Validation"
import { EmploymentInfoIsValid } from "../EmploymentInfo/Validation"
import { FinancialInfo1IsValid, FinancialInfo2IsValid } from "../FinancialInfo/Validation"
import { PersonalInfoIsValid } from "../PersonalInfo/Validation"
import { RegulatoryInfoIsValid } from "../RegulatoryInfo/Validation"
import { TradingExperienceIsValid } from "../TradingExperience/Validation"
import { FuturesOnboarding_Review_Form } from "./Form"
import { getReviewErrors, ReviewIsValid } from "./Validation"

export const futuresOnboarding_review_step: OnboardingStep = {
    label: "Review",
    isComplete: () => ReviewIsValid(),
    isEnabled: () => useFuturesOnboardingStore.getState().isWelcomed,
    isCurrent: () => ['review'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'review'
}

export const futuresOnboarding_review_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        useFuturesOnboardingStore.getState().setValue('isReviewed', true)
        useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'agreements')
    },
    isPrimary: true,
    isEnabled: () =>  RegulatoryInfoIsValid() && AffiliationIsValid() && TradingExperienceIsValid() && EmploymentInfoIsValid() && FinancialInfo1IsValid() && FinancialInfo2IsValid() 
        && PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_review_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => 
            useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'regulatoryInfo'),
    isPrimary: false,
    isEnabled: () => true
}


export const futuresOnboarding_review_screen: OnboardingScreen = {
    id: "review",
    validate: getReviewErrors,
    buttons: [futuresOnboarding_review_prevButton, futuresOnboarding_review_nextButton],
    title: T(t => t.futuresOnboarding.review.title),
    subtitles: [T(t=> t.futuresOnboarding.review.subTitle)],
    body: <FuturesOnboarding_Review_Form/>
}