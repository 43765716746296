// @ts-strict-ignore
import _ from 'lodash';
import { GetConfig } from '../constants/Config';
import { SetRelayValueAction } from '../redux/actions/RelayActions';
import { useSnexStore } from './UseSnexStore';

export const useRelay = <TValue>(topic: string): [TValue, (val: TValue) => void] => {
    if (!topic) return [null, _.noop];
    const update = (value: TValue) => SetRelay(topic, value);
    const value = useSnexStore((s) => s.relay[topic]);
    return [value, update];
};

export const SetRelay = <TValue>(topic: string, value: TValue) => {
    if (GetRelay(topic) !== value) GetConfig().Store.dispatch(SetRelayValueAction(topic, value));
};
export const GetRelay = <TValue>(topic: string): TValue => GetConfig().Store.getState().relay[topic] as any;

(window as any).Rx = { set: SetRelay, get: GetRelay };
