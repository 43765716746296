import { T } from "phoenix/assets/lang/T"
import { OnboardingInputError } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"

export const CertificationsAreValid = () => getCertificationsErrors()?.length < 1
export const getCertificationsErrors = () => {
    const _errors = []  as OnboardingInputError[]
    const requiredCerts = useFuturesOnboardingStore.getState().requiredCerts
    const clickedCerts = useFuturesOnboardingStore.getState().clickedCerts
    const requiredText = T(t => t.webRegistration.errors.required)

    if(!requiredCerts.length) _errors.push({key: 'linksLoaded', error: requiredText})
    requiredCerts.forEach(r => {
        if(!clickedCerts.includes(r)) _errors.push({key: `${r}`, error: requiredText})
    })

    if(useFuturesOnboardingStore.getState().acceptArbitration === undefined) _errors.push({key: 'arbitrationAcceptance', error: requiredText})

    return _errors.filter(e => !!e);

}