import { OnboardingInputError, ValidateRequired } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"

export const RegulatoryInfoIsValid = () => getRegulatoryInfoErrors()?.length < 1
export const getRegulatoryInfoErrors = () => {
    const keys = [
       'data.regulatoryInfo.registrationExemption',
       'data.regulatoryInfo.bankruptcy',
       'data.regulatoryInfo.disputeOrArbitration',
       'data.regulatoryInfo.unsatisfiedDebitBalance',
    ]
    const store = useFuturesOnboardingStore.getState()
    const _errors = []  as OnboardingInputError[]
    keys.forEach(key => {
        switch(key) {
            case 'data.regulatoryInfo.registrationExemption': 
            case 'data.regulatoryInfo.bankruptcy':
            case 'data.regulatoryInfo.disputeOrArbitration':
            case 'data.regulatoryInfo.unsatisfiedDebitBalance':
                _errors.push(ValidateRequired(store, key))
                break
                
    }})
    return _errors.filter(e => !!e);
}