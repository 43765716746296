import React, { useMemo } from 'react';
import { useColors } from 'hooks/UseColors';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { PaddedLabel } from 'components/PaddedLabel/PaddedLabel';
import { useText } from 'phoenix/hooks/UseText';

interface AssetClassLabelProps {
    thingId: string;
    style?: React.CSSProperties;
    size?: 'small' | 'large';
    textAlign?: 'left' | 'center' | 'right';
}

export const AssetClassLabel = React.memo((props: AssetClassLabelProps) => {
    const text = useText((s) => s.general.assetClassLabels);
    const colors = useColors();

    const assetClass = useMemo(() => QualifiedId.Family(props.thingId), [props.thingId]);

    const { bg, fg } = useMemo(() => {
        switch (assetClass) {
            case 'futures':
                return { fg: colors.futuresColor, bg: colors.futuresBackdropColor };
            case 'cryptos':
                return { fg: colors.cryptosColor, bg: colors.cryptosLighterColor };
            case 'equities':
                return { fg: colors.primaryItemColor, bg: colors.primaryItemBackdropColor };
            default:
                return { fg: 'transparent', bg: 'transparent' };
        }
    }, [assetClass, colors]);

    return <PaddedLabel label={text[assetClass]} backgroundColor={bg} color={fg} size={props.size} textAlign={props.textAlign} />;
});
