import { OnboardingInputError, ValidateRequired } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"

export const AffiliationIsValid = () => getAffiliationErrors()?.length < 1
export const getAffiliationErrors = () => {
    const keys = [
        'data.affiliationsInfo.politicalOrGovernment',
        'data.affiliationsInfo.employeeOfStoneX',
        'data.affiliationsInfo.employeeName',
        'data.affiliationsInfo.employeeRelationship',
        'data.affiliationsInfo.regulatoryMember',
        'data.affiliationsInfo.regulatoryBody',
        'data.affiliationsInfo.registrationNumber',
    ]
    const store = useFuturesOnboardingStore.getState()
    const _errors = []  as OnboardingInputError[]
    keys.forEach(key => {
        switch(key) {
            case 'data.affiliationsInfo.politicalOrGovernment':
            case 'data.affiliationsInfo.employeeOfStoneX':
            case 'data.affiliationsInfo.regulatoryMember':
                _errors.push(ValidateRequired(store, key))
                break
            case 'data.affiliationsInfo.employeeName':
            case 'data.affiliationsInfo.employeeRelationship':
                if(!useFuturesOnboardingStore.getState().data?.affiliationsInfo?.employeeOfStoneX) break
                _errors.push(ValidateRequired(store, key))
                break
            case 'data.affiliationsInfo.regulatoryBody':
            case 'data.affiliationsInfo.registrationNumber':
                if(!useFuturesOnboardingStore.getState().data?.affiliationsInfo?.regulatoryMember) break
                _errors.push(ValidateRequired(store, key))
                break
                
    }})
    return _errors.filter(e => !!e);
}