// @ts-strict-ignore
import { Urls } from 'phoenix/constants';
import { ApiData } from 'phoenix/models';
import { SnexError } from 'phoenix/models/SnexError';
import { MakePositionGroupKey, MergePositions } from 'phoenix/redux/actions';
import { ApiPosition, OptionSymbol, PositionsSummary, QuantitySummary, RealizedPnlItem } from 'phoenix/redux/models';
import { SnexApiClosedPositionGroup, SnexClosedPositionGroup } from 'phoenix/redux/models/Positions/ClosedPosition';
import { PnlStats, PnlStatsInfo } from 'phoenix/redux/models/Positions/PnlStats';
import { SnexApiResponse } from 'phoenix/redux/models/SnexApiResponse';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { GroupBySelect } from 'phoenix/util';
import uuid from 'uuid-random';
import { createClearable as create } from 'phoenix/stores/ZustandStore'
export interface SelectedOptionPosition {
    byExpiration: Record<
        string,
        {
            quantity?: number;
            quantitySummary?: QuantitySummary & { isLong?: boolean };
        }
    >;
    byContract: Record<
        string,
        {
            quantity?: number;
            quantitySummary?: QuantitySummary;
        }
    >;
}

export type PositionsStoreData = {
    isLoading: boolean;
    pristine: boolean;
    closedPristine: boolean;
    positions?: ApiPosition[]; // For lists, communicate unloaded using undefined or null
    closedPositions?: SnexClosedPositionGroup[];
    selectedOptionPositions?: SelectedOptionPosition;
    errors?: SnexError[];
};

export type PositionsStoreMethods = {
    find: (
        criteria: Partial<{
            qsi: string;
            accountNumber?: string;
        }>
    ) => ApiPosition[];

    findOne: (
        criteria: Partial<{
            qsi: string;
            accountNumber: string;
        }>
    ) => ApiPosition | undefined;

    findOption: (criteria: { baseSymbol: string; accountNumber: string }) => ApiPosition[];

    loadClosed: (year?: number) => Promise<SnexClosedPositionGroup[]>;

    load: () => Promise<ApiPosition[]>;

    hasPosition: (
        criteria: Partial<{
            qsi: string;
            accountNumber: string;
        }>
    ) => boolean;

    isLong: (
        criteria: Partial<{
            qsi: string;
            accountNumber: string;
        }>
    ) => boolean;

    loadSelectedOptionPositions?: (qsi: string) => void;
    resetSelectedOptionPositions?: () => void;
    clearAll: () => void;
    setLoaded: (loaded?: boolean) => void;
};

export type PositionsStore = PositionsStoreData & PositionsStoreMethods;

// The ProcessPositions function is a temporary solution to emulate the redux store behavior;
// This will be replaced with a proper fix whenever the Positions data model is updated to v2
const ProcessPositions = (res: PositionsSummary[]): PositionsSummary[] => {
    return (
        res?.map((s) => ({
            ...s,
            // Merge positions with the same sec number and acct number up into one
            positions: Object.entries(GroupBySelect(s.positions, (p) => MakePositionGroupKey(p))).map((e) => {
                const positions = e[1];

                const merged = MergePositions(positions);

                if (merged.productType !== 'Options') return merged;
                if (merged.securityType === 'Futures') {
                    const fOptSym = new OptionSymbol(merged.secMasterOptionSymbol);
                    merged.optionSymbol = fOptSym;
                    merged.optionOccSymbol = fOptSym.toOccSymbol();
                    merged.optionOsiSymbol = fOptSym.toOsiSymbol();
                    merged.id = `${merged.secMasterOptionSymbol}-${merged.accountNumber}-${uuid()}`;
                    return merged;
                }
                const splitOptionSymbol = merged.secMasterOptionSymbol?.split(/ +/);
                merged.secMasterOptionSymbol =
                    splitOptionSymbol?.length > 1 ? `${merged.symbol}${splitOptionSymbol[1]}` : merged.secMasterOptionSymbol?.replace(/\s*/, '');

                let optSym = null;
                if (OptionSymbol.IsOptionSymbol(merged.symbol)) optSym = new OptionSymbol(merged.symbol);
                else {
                    optSym = new OptionSymbol(merged.secMasterOptionSymbol || merged.description, merged.symbol);
                }
                merged.optionSymbol = optSym;
                merged.optionOccSymbol = optSym.toOccSymbol();
                merged.optionOsiSymbol = optSym.toOsiSymbol();
                merged.id = `${merged.securityNumber}-${merged.accountNumber}-${uuid()}`;
                return merged;
            })
        })) || []
    );
};

export const AddUnrealizedPnLToPositions = (positions: ApiPosition[], pnlStats: PnlStats[], debug?: boolean): ApiPosition[] => {
    return positions.map((pos) => {
        // Find the PnL stats for this position's symbol and account number.
        const optSym = new OptionSymbol(pos.optionOsiSymbol || pos.secMasterOptionSymbol);
        let pnlStat: PnlStatsInfo;
        if (optSym.isOption) {
            pnlStat = pnlStats.find((p) => p.qsi === optSym.osiSymbol)?.byAccount.find((p) => p.accountNumber === pos.accountNumber);
        } else {
            pnlStat = pnlStats.find((p) => p.qsi === pos.symbol)?.byAccount.find((p) => p.accountNumber === pos.accountNumber);
        }

        if (pnlStat) {
            pos.averageUnitCost = pnlStat.averageUnitCost;
            pos.costBasis = pnlStat.costBasis;
        }
        return pos;
    });
};

const search = (o: Partial<{ qsi: string; accountNumber?: string }>): ApiPosition[] => {
    // Get positions.
    let results = usePositionsStore.getState().positions || [];

    // Filter to accountNumber if it exits.
    if (o.accountNumber) results = results.filter((p) => p.accountNumber === o.accountNumber);

    // Filter to symbol if it exists.
    if (o.qsi)
        results = results.filter((p) => {
            // This is because of Positons V1, Replace Eventually
            if (p?.secMasterOptionSymbol) return p?.secMasterOptionSymbol?.replace(/\s/g, '') === o.qsi.replace(/\s/g, '');
            else return p.symbol === o.qsi;
        });

    return results;
};

const searchOption = (o: { baseSymbol: string; accountNumber: string }): ApiPosition[] => {
    let results = usePositionsStore.getState().positions || [];

    results = results.filter((p) => {
        const optSym = new OptionSymbol(p.secMasterOptionSymbol || p.symbol);
        return optSym.underlyingSymbol === o.baseSymbol && p.accountNumber === o.accountNumber;
    });

    return results;
};

export const usePositionsStore = create()<PositionsStore>((set, get) => {
    return {
        isLoading: false,
        pristine: true,
        closedPristine: true,
        positions: [],
        closedPositions: [],
        selectedOptionPositions: undefined,

        errors: null,

        load: async () => {
            set({ isLoading: true });
            const getPositionsAsync = SnexAxios.ApiGet<SnexApiResponse<PositionsSummary[]>>(Urls.positions.all())
                .withMutex(() => 'all-positions', true)
                .run();

            const getPnlAsync = SnexAxios.ApiGet<SnexApiResponse<PnlStats[]>>(Urls.positions.pnlStats())
                .withMutex(() => 'pnl-stats', true)
                .run();

            const response = await getPositionsAsync;
            const pnlResponse = await getPnlAsync;

            const processed = ProcessPositions(response.data);
            const flat = processed.flatMap((s) => s.positions);

            const finalPositions = AddUnrealizedPnLToPositions(flat, pnlResponse.data);
            set({ positions: finalPositions, isLoading: false, pristine: false, errors: response.errors });

            return flat;
        },
        loadClosed: async (year: number = new Date().getFullYear()) => {
            set({ isLoading: true });
            const response = await SnexAxios.ApiGet<SnexApiClosedPositionGroup[]>(Urls.positions.closed(year))
                .withMutex(() => `closed-positions-${year}`, true)
                .run();

            const flat = response?.map((g) => ({ ...g, name: null, items: new ApiData<RealizedPnlItem[]>(g.items || []) }));

            set({ closedPositions: flat, closedPristine: false, isLoading: false, errors: null });

            return flat;
        },

        find: (o) => search(o),

        findOne: (o) => search(o)?.[0] || undefined,

        findOption: (o) => searchOption(o),

        hasPosition: (o) => !!search(o)?.length,

        // Same logic for isLong as is used in OrderCellLeg.tsx
        isLong: (o) => {
            // Search for symbol.
            const results = search(o);

            // Save the result of search.
            const holdsSecurity = !!results?.length;

            // If the security is held, check for quantity to determine long or short.
            return holdsSecurity ? results.reduce((sum, p) => sum + p.quantity, 0) > 0 : false;
        },

        loadSelectedOptionPositions: (qsi: string) => {
            const { positions } = get();

            const contractPositions = positions.filter((p) => p.secMasterOptionSymbol !== null && p.symbol === qsi);

            // If there are no contract positions with this symbol, then we don't need to continue.
            if (contractPositions?.length <= 0) return;

            const selectedOptionPositions: SelectedOptionPosition = {
                byExpiration: {},
                byContract: {}
            };

            contractPositions?.forEach((contract) => {
                if (!contract.secMasterOptionSymbol || !contract.date) return;
                const option = new OptionSymbol(contract.secMasterOptionSymbol.replace(/\s/g, ''));
                const date = option.expDate.toDateString();
                selectedOptionPositions.byExpiration = {
                    ...selectedOptionPositions.byExpiration,
                    [date]: {
                        quantity: (selectedOptionPositions.byExpiration?.[date]?.quantity || 0) + contract.quantity,
                        quantitySummary: {
                            total: (selectedOptionPositions.byExpiration?.[date]?.quantitySummary?.total || 0) + contract?.quantitySummary?.total,
                            cash: (selectedOptionPositions.byExpiration?.[date]?.quantitySummary?.cash || 0) + contract?.quantitySummary?.cash,
                            margin: (selectedOptionPositions.byExpiration?.[date]?.quantitySummary?.margin || 0) + contract?.quantitySummary?.margin,
                            short: (selectedOptionPositions.byExpiration?.[date]?.quantitySummary?.short || 0) + contract?.quantitySummary?.short,
                            isLong: selectedOptionPositions.byExpiration?.[date]?.quantitySummary?.isLong ?? contract?.quantitySummary?.total > 0
                        }
                    }
                };

                selectedOptionPositions.byContract = {
                    ...selectedOptionPositions.byContract,
                    [option.originalSymbol]: {
                        quantity: contract.quantity,
                        quantitySummary: contract.quantitySummary
                    }
                };
            });

            set({ selectedOptionPositions });
        },

        resetSelectedOptionPositions: () => {
            set({ selectedOptionPositions: undefined });
        },

        clearAll: () =>
            set({
                isLoading: false,
                pristine: true,
                closedPristine: true,
                positions: [],
                closedPositions: [],
                selectedOptionPositions: undefined,
                errors: null
            }),

        setLoaded: (loaded: boolean) =>
            set({
                isLoading: loaded,
                pristine: loaded ? false : get().pristine,
                errors: get().errors
            })
    };
});

// Globally exported convenience functions must be prefixed with the store they operate on to reduce confusion
export const PositionsStore_Load = (): Promise<ApiPosition[]> => usePositionsStore?.getState()?.load();
