// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { ContactInfoIsValid } from "../ContactInfo/Validation"
import { PersonalInfoIsValid } from "../PersonalInfo/Validation"
import { FuturesOnboarding_FinancialInfo1_Form } from "./Form1"
import { FuturesOnboarding_FinancialInfo2_Form } from "./Form2"
import { FinancialInfo1IsValid, FinancialInfo2IsValid, getFinancialInfo1Errors, getFinancialInfo2Errors } from "./Validation"

export const futuresOnboarding_financialInfo_step: OnboardingStep = {
    label: "Financial Info",
    isComplete: () => FinancialInfo1IsValid() && FinancialInfo2IsValid(),
    isEnabled: () => PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed,
    isCurrent: () => ['financialInfo1, financialInfo2'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'financialInfo1'
}

export const futuresOnboarding_financialInfo1_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'financialInfo2')
    },
    isPrimary: true,
    isEnabled: () => FinancialInfo1IsValid() && PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_financialInfo1_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'personalInfo'),
    isPrimary: false,
    isEnabled: () => true
}

export const futuresOnboarding_financialInfo2_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'employmentInfo')
    },
    isPrimary: true,
    isEnabled: () => FinancialInfo2IsValid() && FinancialInfo1IsValid() && PersonalInfoIsValid() && ContactInfoIsValid() 
        && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_financialInfo2_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'financialInfo1'),
    isPrimary: false,
    isEnabled: () => true
}


export const futuresOnboarding_financialInfo1_screen: OnboardingScreen = {
    id: "financialInfo1",
    validate: getFinancialInfo1Errors,
    buttons: [futuresOnboarding_financialInfo1_prevButton, futuresOnboarding_financialInfo1_nextButton],
    title: T(t => t.futuresOnboarding.financialInfo.title),
    subtitles: null,
    body: <FuturesOnboarding_FinancialInfo1_Form/>
}

export const futuresOnboarding_financialInfo2_screen: OnboardingScreen = {
    id: "financialInfo2",
    validate: getFinancialInfo2Errors,
    buttons: [futuresOnboarding_financialInfo2_prevButton, futuresOnboarding_financialInfo2_nextButton],
    title: T(t => t.futuresOnboarding.financialInfo.title),
    subtitles: null,
    body: <FuturesOnboarding_FinancialInfo2_Form/>
}