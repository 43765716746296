// @ts-strict-ignore
import { StandardQuote } from '../constants/ReduxSelectors';
import { SecurityChartData, SecurityQuote } from '../redux/models';

export type QuoteParam = Partial<SecurityQuote & SecurityChartData & StandardQuote>;
const getPrice = (q: QuoteParam | any = {}): number | null => {
    if (isExtendedHours(q)) {
        return q?.extendedPrice || q?.latestPrice || q?.price || q?.ask || null;
    } else {
        return q?.value || q?.latestPrice || q?.price || q?.last || q?.ask || null;
    }
};

const getChange = (q: QuoteParam | any = {}, sumExtendedHoursWithTradingDayChange = false): number | null => {
    if (isExtendedHours(q)) {
        if (sumExtendedHoursWithTradingDayChange) {
            if (q?.extendedChange && q?.change) {
                return q?.change + q?.extendedChange;
            } else return q?.change;
        }
        return q?.extendedChange || q?.change;
    } else return q?.change;
};

const getChangePercent = (
    q: QuoteParam | any = {},
    sum = false,
    /** @deprecated data must be corrected / normalized in the API / data layer. Context: if true, this will multiply the value by 100. */ withMultiplier = true
): number | null => {
    const quoteChangePercent = q?.percentChange || q?.changePercent;
    const pct = (() => {
        if (isExtendedHours(q)) {
            if (q?.extendedChangePercent && !isNaN(quoteChangePercent)) {
                return (q?.extendedChangePercent + quoteChangePercent) * (withMultiplier ? 100 : 1);
            }
            return quoteChangePercent * (withMultiplier ? 100 : 1);
        } else {
            return quoteChangePercent * (withMultiplier ? 100 : 1);
        }
    })();
    if (pct === null || pct === undefined) {
        return (getChange(q, sum) / (getPrice(q) - getChange(q, sum))) * 100;
    }
    return pct;
};

const getChangePercentBase1 = (q: QuoteParam | any = {}, sumExtendedHoursWithTradingDayChange = false) => {
    const quoteChangePercent = q?.percentChange || q?.changePercent;
    const pct = (() => {
        if (isExtendedHours(q)) {
            if (q?.extendedChangePercent) {
                if(sumExtendedHoursWithTradingDayChange && !isNaN(quoteChangePercent)) {
                    return q?.extendedChangePercent + quoteChangePercent
                }
                return q?.extendedChangePercent;
            }
            return quoteChangePercent;
        } else {
            return quoteChangePercent;
        }
    })();
    const s = sumExtendedHoursWithTradingDayChange;
    if (pct === null || pct === undefined) {
        return (getChange(q, s) / (getPrice(q) - getChange(q, s))) * 100;
    }
    return pct;
};

const getTime = (q: QuoteParam | any = {}) => {
    if (isExtendedHours(q)) {
        return q?.extendedPriceTime || q?.timestamp || q?.lastTradeTime || q?.time;
    } else return q?.lastTradeTime || q?.time || q?.timestamp;
};

const isExtendedHours = (q: QuoteParam | any = {}) => {
    return (
        (!isNaN(q?.extendedPriceTime) && q?.extendedPriceTime >= (!isNaN(q?.lastTradeTime) ? q?.lastTradeTime : q?.timestamp)) ||
        (!isNaN(q?.extendedPriceTime) && isNaN(q?.lastTradeTime))
    );
};

export const QuoteAttribute = {
    getPrice,
    getChange,
    /**
     * @deprecated Please use getChangePercentBase1
     */
    getChangePercent,
    getChangePercentBase1,
    getTime,
    isExtendedHours
};
