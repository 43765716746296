// @ts-strict-ignore
import { AppColorTheme } from './ColorVariants/AppColorTheme';
import shared from './shared';

export const TmpLightTheme: AppColorTheme = {
    _name: 'LIGHT',
    disclosureTextColor: '#A7A7A7',
    areaChartNegativeColor: '#f7e0e3',
    areaChartPositiveColor: '#e0f6e6',
    blurredAdornmentColor: '#a9a9a9',
    blurredBackgroundColor: '#ffffffea',
    boxShadow: 'rgba(51, 96, 148, 0.09)',
    boxShadowLight: 'rgba(51, 96, 148, 0.1)',
    cardBackgroundColor: '#fff',
    cardLighterBackgroundColor: '#fff',
    cardTitleBackgroundColor: '#efefef',
    cardSecondaryBackgroundColor: '#fff',
    cashAndEquivsWrapperHover: 'rgb(218,240,216)',
    cellHoverBackgroundColor: 'rgba(51, 96, 148, 0.05)',
    chartVerticalCrosshairColor: '#e0e0e0',
    colorOverlay: 'rgba(255, 255, 255, 0.7)',
    cryptosColor: '#4500D7',
    cryptosLighterColor: '#4500D71A',
    defaultOnboardingButtonBackgroundColor: '#f7fafc',
    defaultOnboardingButtonBackgroundHoverColor: '#e5e5e5',
    defaultOnboardingButtonColor: 'rgb(52, 96, 148)',
    disabledButtonText: 'rgba(0,0,0,0.3)',
    disclosuresListButtonHoverColor: 'rgba(0, 0, 0, 0.04)',
    dividerColor: 'rgb(245, 245, 245)',
    donutChartSegments: [
        '#2fcd8e', // Minty green
        '#ea9d1e', // Gold
        '#2b69ff', // Robin's Egg Blue
        '#5E2CA8', // Roman's Purple
        '#AFF3DC', // Light Minty Green
        '#f9379d', // Pink
        '#666666', // Devil's Food Gray
        '#f8342e', // Red
        '#DACE50', // Golden Graham
        '#89C6FF', // Light Blue
        '#D8FF6D' // Light Green
    ],
    fadedTextColor: '#596269',
    focusedAdornmentColor: '#000',
    futuresBackdropColor: '#28bcd638',
    futuresColor: '#28BBD6',
    equitiesBackdropColor: 'rgba(0, 132, 255, 0.2)',
    equitiesColor: '#0071DB',
    generalTextColor: '#000000',
    generalTextReverseColor: '#fff',
    greyBackgroundColor: 'rgb(248, 248, 248)',
    holdingsGold: 'rgb(233, 156, 35)',
    holdingsGoldBackground: 'rgba(233, 156, 35, .2)',
    inputBorderActiveColor: '#000',
    inputBorderColor: 'rgba(0,0,0,0.15)',
    mainBackgroundColor: '#fff',
    mainBackgroundReverseColor: '#000',
    negativeValueColor: 'rgb(178, 41, 46)',
    negativeValueLighterColor: 'rgba(178, 41, 46, 0.16)',
    negativeValueChartLighterColor: 'rgba(178, 41, 46, .3)',
    neutralValueColor: 'rgb(218, 206, 80)', // Golden Graham
    neutralValueLighterColor: 'rgba(218, 206, 80, 0.16)',
    moneyGreenLight: '#F3FBF5',
    moneyGreenLighter: '#E8F7EB',
    notAllowedBackgroundColor: '#f7fafc',
    notAllowedBorderColor: 'rgba(13, 93, 163, 0.2)',
    notAllowedTextColor: '#0d5da3',
    onboardingButtonBackground: 'rgb(2, 179, 52)',
    onboardingButtonColor: 'white',
    onboardingCardBackground: '#ffffff',
    onboardingDisabledButtonBackground: '#bfbfbf',
    onboardingDisabledButtonColor: '#9f9f9f',
    optionsLabelBlue: '#398AE6',
    optionsLabelBlueLight: '#398AE61F',
    optionsLabelCoral: '#E45F42',
    optionsLabelCoralLight: '#E45F421F',
    optionsLabelOrange: '#E99C23',
    optionsLabelOrangeLight: '#E99C231F',
    positiveValueColor: 'rgb(2, 179, 52)',
    positiveValueDarkerColor: 'rgb(0,150,8)',
    positiveValueLighterColor: 'rgba(2, 179, 52, 0.16)',
    positiveValueChartLighterColor: 'rgba(0,150,8, .3)',
    primaryItemBackdropColor: 'rgba(50, 103, 168, 0.15)',
    primaryItemColor: 'rgb(51, 96, 148)',
    primaryItemColorTints: new Array(10)
        .fill(null)
        .map((_, i) => i !== 0 && `rgba(51, 96, 148, .${i})`)
        .filter((x) => x),
    primaryItemLightColor: '#345f948f',
    requiredBorderColor: 'rgb(178, 41, 46)',
    scrollbarColor: 'rgb(233, 233, 233)',
    secondaryButtonBackgroundColor: '#f7fafc',
    secondaryButtonBackgroundHoverColor: '#f7fafc',
    secondarySolidButtonBackgroundColor: '#f7fafc',
    secondarySolidButtonBackgroundHoverColor: '#f7fafc',
    segmentedControlActiveColor: '#336094',
    segmentedControlBackgroundColor: '#f0f7ff',
    selectedOnboardingButtonBackgroundColor: 'rgb(52, 96, 148)',
    selectedOnboardingButtonBackgroundHoverColor: '#346094',
    selectedOnboardingButtonColor: 'white',
    selectedOnboardingButtonHoverColor: 'white',
    selectListItemColor: 'rgba(51, 96, 148, 0.1)',
    selectListSelectedItemColor: '#346094',
    stickyCard: '#ffc',
    fundingSourcesBackgroundColor: '#f0f0f0',
    ...shared
};
