// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { ContactInfoIsValid } from "../ContactInfo/Validation"
import { FuturesOnboarding_PersonalInfo_Form } from "./Form"
import { getPersonalInfoErrors, PersonalInfoIsValid } from "./Validation"

export const futuresOnboarding_personalInfo_step: OnboardingStep = {
    label: "Personal Info",
    isComplete: () => PersonalInfoIsValid(),
    isEnabled: () => ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed,
    isCurrent: () => ['personalInfo'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'personalInfo'
}

export const futuresOnboarding_personalInfo_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'financialInfo1')
    },
    isPrimary: true,
    isEnabled: () => PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_personalInfo_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'contactInfo'),
    isPrimary: false,
    isEnabled: () => true
}

export const futuresOnboarding_personalInfo_screen: OnboardingScreen = {
    id: "personalInfo",
    validate: getPersonalInfoErrors,
    buttons: [futuresOnboarding_personalInfo_prevButton, futuresOnboarding_personalInfo_nextButton],
    title: T(t => t.futuresOnboarding.personalInfo.title),
    subtitles: null,
    body: <FuturesOnboarding_PersonalInfo_Form/>
}