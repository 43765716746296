// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { ContactInfoIsValid } from "../ContactInfo/Validation"
import { EmploymentInfoIsValid } from "../EmploymentInfo/Validation"
import { FinancialInfo1IsValid, FinancialInfo2IsValid } from "../FinancialInfo/Validation"
import { PersonalInfoIsValid } from "../PersonalInfo/Validation"
import { FuturesOnboarding_TradingExperience_Form } from "./Form"
import { getTradingExperienceErrors, TradingExperienceIsValid } from "./Validation"

export const futuresOnboarding_tradingExperience_step: OnboardingStep = {
    label: "Trading Experience",
    isComplete: () => TradingExperienceIsValid(),
    isEnabled: () => EmploymentInfoIsValid() && FinancialInfo1IsValid() && FinancialInfo2IsValid() && PersonalInfoIsValid() && ContactInfoIsValid() 
        && useFuturesOnboardingStore.getState().isWelcomed,
    isCurrent: () => ['tradingExperience'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'tradingExperience'
}

export const futuresOnboarding_tradingExperience_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'affiliations'),
    isPrimary: true,
    isEnabled: () => TradingExperienceIsValid() && EmploymentInfoIsValid() && FinancialInfo1IsValid() && FinancialInfo2IsValid() 
        && PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_tradingExperience_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => {
        if(useFuturesOnboardingStore.getState().data?.employmentInfo?.status.toLowerCase() === 'employed') {
            useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'employmentInfo2')
        } else {
            useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'employmentInfo')
        }
       
    },
    isPrimary: false,
    isEnabled: () => true
}


export const futuresOnboarding_tradingExperience_screen: OnboardingScreen = {
    id: "tradingExperience",
    validate: getTradingExperienceErrors,
    buttons: [futuresOnboarding_tradingExperience_prevButton, futuresOnboarding_tradingExperience_nextButton],
    title: T(t => t.futuresOnboarding.tradingExperience.title),
    subtitles: null,
    body: <FuturesOnboarding_TradingExperience_Form/>
}