// @ts-strict-ignore
import React from "react"
import animationData from 'phoenix/assets/animations/Money.json'
import { Flex } from "components"
import { OnboardingDropdown, OnboardingInputRow, OnboardingLottieForm } from "screens/OnboardingScreens/Base/OnboardingControls"
import { AllowedFuturesCountryOptions } from "screens/OnboardingScreens/_constants/DropdownOptions/CountryOptions"
import { useFuturesOnboardingStore } from "../../Store"
import { useText } from "phoenix/hooks/UseText"
import { getFinancialInfo2Errors } from "./Validation"

export const FuturesOnboarding_FinancialInfo2_Form = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding.financialInfo)
    const handleChange = (key: string, value: string) => {
        setValue(key, value)
    }
    const getErrors = (key: string) => {
        return getFinancialInfo2Errors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
    }
    
    return(
        <OnboardingLottieForm data={animationData} loop={false}>
             <Flex column center fullWidth style={{gap: '1rem'}}>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.financialInfo.annualRevenue' options={text.annualRevenueOptions} 
                        label={text.annualRevenue} value={data.financialInfo?.annualRevenue} handleChange={handleChange} 
                        errors={getErrors('data.financialInfo.annualRevenue')}/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.financialInfo.netWorth' options={text.netWorthOptions} 
                        label={text.netWorth} value={data.financialInfo?.netWorth} handleChange={handleChange} 
                        errors={getErrors('data.financialInfo.netWorth')}/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.financialInfo.workingCapital' options={text.workingCapitalOptions} 
                        label={text.workingCapital} value={data.financialInfo?.workingCapital} handleChange={handleChange} 
                        errors={getErrors('data.financialInfo.workingCapital')}/>
                </OnboardingInputRow>
             </Flex>
        </OnboardingLottieForm>
    )
}