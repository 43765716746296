// @ts-strict-ignore
import { ReduxAction } from '../../../models'
import { QualifiedSecurityId } from '../../../models/QualifiedSecurityId'
import { SetCacheTime } from '../../../util/CacheManager'
import { ErrorLogger } from '../../../util/ErrorLogger'
import { Actions } from '../../actions'
import { GlobalState } from '../../GlobalState'
import { ApiPosition, PositionsSummary } from '../../models'
import { SummaryScreenBundle, SummaryScreenPartTwoBundle } from '../../models/MobileScreens/SummaryScreenBundle'
import { asData, orNew } from '../ReducerUpdateHelpers'
import { MobileStateUpdates } from './MobileStateUpdates'
import { isEqual } from 'lodash'

export const SummaryScreenReducer = (state: GlobalState = new GlobalState(), action: ReduxAction): GlobalState => {
    switch (action.type) {
        case Actions.MobileScreens.LoadSummaryScreen.Success: return HandlePartOneBundle(state, action.data as SummaryScreenBundle)
        case Actions.MobileScreens.LoadSummaryScreenPartTwo.Success: return HandlePartTwoBundle(state, action.data as SummaryScreenPartTwoBundle)
    }
}

const HandlePartOneBundle = (state: GlobalState, bundle: SummaryScreenBundle) => {
    SetCacheTime('movers')

    state.accountChart.chartIsDeficient = bundle.chartIsDeficient
    state.accountChart.deficientAccounts = bundle.deficientAccounts

    state.accountChart.aggregate['1d'] = orNew(state.accountChart.aggregate['1d']).succeeded(bundle.chart)

    state.accountChart.byAccount = {
        ...state.accountChart.byAccount,
        ...bundle.accountCharts.reduce((lookup, { accountNumber, data }) => ({
            ...lookup,
            [accountNumber]: {
                ...(state.accountChart.byAccount[accountNumber] || {}),
                '1d': orNew(state.accountChart.byAccount[accountNumber]?.['1d']).succeeded({ accountNumber, data })
            }
        }), {})
    }

    const currentAccountList = state.accounts.all.data || [];
    const newAccountList = bundle.accounts || [];

    const areAccountListsDifferent = newAccountList.length !== currentAccountList.length ||
        newAccountList.some(newAccount => {
            const currentAccount = currentAccountList.find(acc => acc.accountNumber === newAccount.accountNumber);
            return !currentAccount || !isEqual(currentAccount, newAccount);
        });

    if (areAccountListsDifferent) {
        state.accounts.all = orNew(state.accounts.all).succeeded(bundle.accounts);
    }

    state.securities.top.equities.movers = orNew(state.securities.top.equities.movers).succeeded(bundle.topMovers);

    // API oops: quotesAndCharts doesn't include top movers quotes when it should
    MobileStateUpdates.addQuotesAndCharts(state, bundle.topMovers.map(m => ({ symbol: m.symbol, quote: m })));

    if (bundle.frontMonthMovers) {
        state.securities.top.futures.movers = orNew(state.securities.top.futures.movers).succeeded(bundle.frontMonthMovers);
        MobileStateUpdates.addQuotesAndCharts(state, bundle.frontMonthMovers?.map(m => ({ symbol: m.symbol, quote: m })));
    }

    MobileStateUpdates.addQuotesAndCharts(state, bundle.quotesAndCharts);

    MobileStateUpdates.addLogos(state, bundle.logos);
    MobileStateUpdates.addMetadata(state, bundle.metadata);
    MobileStateUpdates.addValuations(state, bundle.valuations);

    state.mobileScreenLoading.summaryScreen = state.mobileScreenLoading.summaryScreen.succeeded();

    return { ...state };
}


const HandlePartTwoBundle = (state: GlobalState, bundle: SummaryScreenPartTwoBundle) => {
    SetCacheTime('all-positions')

    const sum = bundle.summary
    const accountLevelSums = bundle.accountLevelSummaries;
    
    if (accountLevelSums?.length) {
        state.accountSummary.byNumber = accountLevelSums.reduce((final, current) => ({
            ...final,
            [current.accountNumber]: asData(current)
        }), {});
    }
    state.accountSummary.aggregate = orNew(state.accountSummary.aggregate).succeeded(sum);

    state.accountSummary.aggregateBreakdown = orNew(state.accountSummary.aggregateBreakdown).succeeded({
        positionSummary: sum?.positionSummary,
        industrySummary: sum?.industries
    });

    MobileStateUpdates.addLogos(state, bundle.logos);
    MobileStateUpdates.addMetadata(state, bundle.metadata);
    MobileStateUpdates.addBuyingPower(state, bundle.buyingPower);

    // Hacking in legacy positions here
    const p: PositionsSummary = { positions: bundle.positions };
    const updatedPositionData = p?.positions?.length ? [p] : []
    state.positions.all = asData(updatedPositionData);

    // Report positions that we didn't come with quotes
    const qsisWithoutQuotes = bundle?.positions ? bundle.positions.map(p => QualifiedSecurityId.FromPosition(p)).filter(qsi => {
        const qsiStr = qsi.toString();
        if (!qsiStr || qsi.type === 'Unknown') return false; // "Cash & Equivalents" don't count
        return !bundle.quotesAndCharts.find(qc => qc.symbol === qsiStr);
    }) : [];
    if (qsisWithoutQuotes?.length) {
        ErrorLogger.RecordMessage(`No quotes received from part two bundle: ${qsisWithoutQuotes.map(q => q.id).join(', ')}`);
    }

    MobileStateUpdates.addQuotesAndCharts(state, bundle.quotesAndCharts);
    return { ...state };
}

