// @ts-strict-ignore
import { LogOnboardingStarted } from "components/Onboarding/OnboardingLogging"
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { LogFuturesOnboardingStarted } from "../../Base"
import { useFuturesOnboardingStore } from "../../Store"
import { FuturesOnboarding_ContactInfo_Form } from "./Form"
import { ContactInfoIsValid } from "./Validation"

export const futuresOnboarding_contactInfo_step: OnboardingStep = {
    label: "Contact Info",
    isComplete: () => ContactInfoIsValid(),
    isEnabled: () => useFuturesOnboardingStore.getState().isWelcomed,
    isCurrent: () => ['contactInfo'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'contactInfo'
}

export const futuresOnboarding_contactInfo_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        LogFuturesOnboardingStarted()
        useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'personalInfo')
    },
    isPrimary: true,
    isEnabled: () => ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_contactInfo_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'welcome'),
    isPrimary: false,
    isEnabled: () => true
}

export const futuresOnboarding_contactInfo_screen: OnboardingScreen = {
    id: "contactInfo",
    validate: NoValidation,
    buttons: [futuresOnboarding_contactInfo_prevButton, futuresOnboarding_contactInfo_nextButton],
    title: T(t => t.futuresOnboarding.contactInfo.title),
    subtitles: null,
    body: <FuturesOnboarding_ContactInfo_Form/>
}