import { OnboardingInputError } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { getAffiliationErrors } from "../Affiliations/Validation"
import { getContactInfoErrors } from "../ContactInfo/Validation"
import { getEmploymentInfoErrors } from "../EmploymentInfo/Validation"
import { getFinancialInfo1Errors } from "../FinancialInfo/Validation"
import { getPersonalInfoErrors } from "../PersonalInfo/Validation"
import { getRegulatoryInfoErrors } from "../RegulatoryInfo/Validation"
import { getTradingExperienceErrors } from "../TradingExperience/Validation"

export const ReviewIsValid = () => getReviewErrors()?.length < 1 && !!useFuturesOnboardingStore.getState()?.isReviewed 
export const getReviewErrors = () => {
    const _errors = []  as OnboardingInputError[]
    
    _errors.concat(getContactInfoErrors())
    _errors.concat(getPersonalInfoErrors())
    _errors.concat(getFinancialInfo1Errors())
    _errors.concat(getEmploymentInfoErrors())
    _errors.concat(getTradingExperienceErrors())
    _errors.concat(getAffiliationErrors())
    _errors.concat(getRegulatoryInfoErrors())


    return _errors.filter(e => !!e);
}