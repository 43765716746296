// @ts-strict-ignore

import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { FuturesOnboarding_Welcome_Form } from "./Form"

export const futuresOnboarding_welcome_step: OnboardingStep = {
    label: "Welcome",
    isComplete: () => useFuturesOnboardingStore.getState().isWelcomed,
    isEnabled: () => true,
    isCurrent: () => ['welcome'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'welcome'
}

export const futuresOnboarding_welcome_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        useFuturesOnboardingStore.getState().setValue('isWelcomed', true)
        useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'contactInfo')
    },
    isPrimary: true,
    isEnabled: () => true
}

export const futuresOnboarding_welcome_screen: OnboardingScreen = {
    id: "welcome",
    validate: NoValidation,
    buttons: [futuresOnboarding_welcome_nextButton],
    title: null,
    subtitles: null,
    body: <FuturesOnboarding_Welcome_Form />
}