// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { useXstreamDispatch } from 'phoenix/xstream/XstreamProvider';
import { XS } from 'phoenix/xstream/XS';

interface AutoSubscribeProps {
    onVisibleChange?: (visible: boolean) => void;
    subscribe: () => any;
    children?: any;
}

export const AutoSubscribe = React.memo((props: AutoSubscribeProps) => {
    const [ref, visible] = useInView();
    const [subscription, setSubscription] = useState(null);

    const sub = () => {
        if (subscription) unsub();
        setSubscription(props.subscribe());
    };

    const unsub = () => {
        XS.stop(subscription);
    };

    // Unsub when we're out of view, and re-sub when we're in view. Also notify parent
    // if they want us to
    useEffect(() => {
        if (visible) sub();
        else if (!visible && subscription) unsub();
        if (props.onVisibleChange) props.onVisibleChange(visible);
    }, [visible]);

    // Unsub when the subscribe function changes. If we're already visible, create the new
    // subscription on the spot
    useEffect(() => {
        if (visible) sub();
        return () => {
            if (subscription) unsub();
        };
    }, [props.subscribe]);

    // Unsub on unmount. Note: sometimes React just doesn't like to unmount :)
    useEffect(() => {
        return () => {
            if (subscription) unsub();
        };
    }, []);

    return <span ref={ref}>{props.children}</span>;
});

interface AutoSubscribeStockQuotesProps {
    onVisibleChange?: (visible: boolean) => void;
    children?: any;
    symbol: string;
    namespace: string;
    unless?: boolean;
}

export const AutoSubscribeStockQuotes = React.memo((props: AutoSubscribeStockQuotesProps) => {
    const dispatch = useDispatch();
    const xdispatch = useXstreamDispatch();

    useEffect(() => {
        dispatch(GetSecurityQuoteAction(props.symbol));
    }, [props.symbol]);

    const sub = useCallback(
        () =>
            OptionSymbol.IsOptionSymbol(props.symbol)
                ? xdispatch(XS.OptionQuotes.start([new OptionSymbol(props.symbol).osiSymbol], props.namespace))
                : xdispatch(XS.Quotes.start(props.symbol, props.namespace)),
        [props.symbol]
    );

    useEffect(() => {
        return () => {
            if (props.namespace) XS.stopNs(props.namespace);
        };
    }, []);

    if (props.unless) return props.children;

    return (
        <AutoSubscribe subscribe={sub} onVisibleChange={props.onVisibleChange}>
            {props.children}
        </AutoSubscribe>
    );
});
