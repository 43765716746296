import { Flex } from "components"
import { OnboardingInputRow } from "components/Onboarding"
import { useText } from "phoenix/hooks/UseText"
import React from "react"
import { OnboardingLottieForm, OnboardingYesNo } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFuturesOnboardingStore } from "../../Store"
import animationData from 'phoenix/assets/animations/Checklist.json'
import { getRegulatoryInfoErrors } from "./Validation"

export const FuturesOnboarding_RegulatoryInfo_Form = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding.regulatoryInfo)

    const getErrors = (key: string) => {
        return getRegulatoryInfoErrors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
    }
    
    
    return (
        <OnboardingLottieForm data={animationData} loop={true}>
            <Flex column center fullWidth style={{gap: '1rem'}}>
                <OnboardingInputRow noPadding>
                    <OnboardingYesNo value={data.regulatoryInfo?.registrationExemption} 
                        question={text.registrationExemption}
                        buttonWidth={'auto'}
                        handleChange={(val) => setValue('data.regulatoryInfo.registrationExemption', val)} 
                        errors={ getErrors('data.regulatoryInfo.registrationExemption') }/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingYesNo value={data.regulatoryInfo?.bankruptcy} 
                        question={text.bankruptcy}
                        buttonWidth={'auto'}
                        handleChange={(val) => setValue('data.regulatoryInfo.bankruptcy', val)} 
                        errors={ getErrors('data.regulatoryInfo.bankruptcy') }/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingYesNo value={data.regulatoryInfo?.disputeOrArbitration} 
                        question={text.disputeOrArbitration}
                        buttonWidth={'auto'}
                        handleChange={(val) => setValue('data.regulatoryInfo.disputeOrArbitration', val)} 
                        errors={ getErrors('data.regulatoryInfo.disputeOrArbitration') }/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingYesNo value={data.regulatoryInfo?.unsatisfiedDebitBalance} 
                        question={text.unsatisfiedDebitBalance}
                        buttonWidth={'auto'}
                        handleChange={(val) => setValue('data.regulatoryInfo.unsatisfiedDebitBalance', val)} 
                        errors={ getErrors('data.regulatoryInfo.unsatisfiedDebitBalance') }/>
                </OnboardingInputRow>
            </Flex>
        </OnboardingLottieForm>
    )
}