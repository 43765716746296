import { InfoMatrixGridletModel } from 'components/InfoMatrix/InfoMatrix';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { ApiOrderType } from 'phoenix/models/ApiTradeRequest';
import { AssetClass } from 'phoenix/models/AssetClasses/AssetClass';
import { EquityOptionsAssetClass } from 'phoenix/models/AssetClasses/EquityOptionsAssetClass';
import { OptionSymbol, Order } from 'phoenix/redux/models';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { SecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { AppColorTheme } from 'phoenix/theming/ColorVariants/AppColorTheme';
import { ChangeColor, FormatNumber, isNotANumber, SafeFormatLocale } from 'phoenix/util';
import { QualifiedId } from 'phoenix/util/QualifiedId';
import { noteAndNotHeldToAlgoStrategy } from 'util/Utils';
import { MultiLegFillRow } from './FillsTables';

export type GetActionSummaryProps = {
    assetClass: AssetClass;
    isPartiallyFilled?: boolean;
    isPrimaryOrder: boolean;
    meta: SecurityMetadataV2;
    order: Order;
    text: Snex1LanguagePack;
};

export const getMultiLegOrderPrice = (
    text: Snex1LanguagePack['tradeTicket']['input'],
    spreadInstructions?: string // Possible values: COMB MKT, COMB EVEN, COMB (price) CR, COMB (price) DB
): {
    price?: number;
    creditDebit?: Pick<Snex1LanguagePack['tradeTicket']['input']['options'], 'credit' | 'debit' | 'even'> | Snex1LanguagePack['tradeTicket']['input']['marketPrice'];
} => {
    if (!spreadInstructions) return {};
    const { credit, debit, even } = text.options;

    const [, value, creditDebit = null] = spreadInstructions?.split(' ') || [];

    const words = { CR: credit, DB: debit };

    switch (value) {
        case 'MKT':
            return { creditDebit: text.marketPrice };
        case 'EVEN':
            return { creditDebit: even };
        default:
            return { price: Number(value), creditDebit: words[creditDebit as 'CR' | 'DB'] || '' };
    }
};

export function getActionSummary({ assetClass, isPrimaryOrder, isPartiallyFilled, meta, order, text }: GetActionSummaryProps): string {
    if (!order) return '';
    const isMultiLeg = order?.complexOrders?.length && order?.complexOrders?.length > 0;
    const multiLegOrderPrice = getMultiLegOrderPrice(text.tradeTicket.input, order?.spreadInstructions);
    const actionSummaryDetails = !isPrimaryOrder || isMultiLeg ? { ...order, orderType: 'limit' as ApiOrderType } : order;
    const limitPrice = order?.limitPrice ? assetClass.formatPrice(order?.limitPrice, meta) : '';
    const stopPrice = order?.stopPrice ? assetClass.formatPrice(order?.stopPrice, meta) : '';
    const quantity = isPartiallyFilled ? assetClass.formatQuantity(order?.leavesQuantity || 0) : assetClass.formatOrderQuantity(order);
    const formattedPrice = multiLegOrderPrice?.price ? assetClass.formatPrice(multiLegOrderPrice.price) : '';

    const multiLegActionSummary =
        isMultiLeg &&
        (isPrimaryOrder
            ? text.orders.multiLegAction(order)
            : `${isNotANumber(multiLegOrderPrice?.price as number) ? '' : `${formattedPrice} `}${multiLegOrderPrice?.creditDebit}`);

    const actionSummary = isMultiLeg ? multiLegActionSummary || '' : text.orders.actionSummary({ order: actionSummaryDetails, limitPrice, quantity, stopPrice });

    return actionSummary;
}

function getMultiLegFill(text: Snex1LanguagePack, order?: Order): MultiLegFillRow | null {
    const { formatPrice, formatQuantity } = EquityOptionsAssetClass;
    if (!order || !order?.filledQuantity) return null;
    return {
        description: text.general.symbolName(order?.symbol, 'long', order?.filledQuantity),
        orderNumber: order?.orderNumber,
        orderType: text.orders.action(order?.action),
        price: formatPrice(order?.fills?.[0]?.price),
        quantity: formatQuantity(order?.filledQuantity),
        symbol: order?.symbol,
        timestamp: order?.completedDate ? SafeFormatLocale({ date: order?.completedDate, formatString: 'M/dd/yy, h:mm a', locale: text.general.dateFnsLocale }) : ''
    };
}

export function getMultiLegFills(order: Order, text: Snex1LanguagePack): MultiLegFillRow[] {
    if (!order) return [];
    const secondLeg = order?.complexOrders?.[0];

    const fills: MultiLegFillRow[] = [getMultiLegFill(text, order), getMultiLegFill(text, secondLeg)].filter((x): x is MultiLegFillRow => !!x);

    return fills;
}

export const getFormattedCost = (order: Order, lang: Snex1LanguagePack, secMeta?: SecurityMetadataV2, decimalPlaces?: number): string => {
    const isMultiLeg = !!order.complexOrders && order.complexOrders?.length > 0;
    const hasLimit = !!order?.limitPrice;
    const hasStop = !!order?.stopPrice;
    const isLimit = hasLimit && !hasStop;
    const isStop = !hasLimit && hasStop;
    const isMarketOrder = (!isLimit && !hasStop && !isMultiLeg) || (isMultiLeg && !!order?.spreadInstructions && order?.spreadInstructions.includes('MKT'));

    if (order.orderStatus !== 'Open') {
        const rawCost = Math.abs(
            isMultiLeg && !!order?.averageTradePriceAmount && !!order.unitQuantity ? order?.averageTradePriceAmount / order.unitQuantity : order.cost || 0
        );

        return FormatNumber.toLocaleDecimal({ decimalPlaces, value: rawCost || NaN });
    }
    if (isMarketOrder || isStop) return lang.tradeTicket.input.marketPrice;
    else {
        const rawLimitPrice = isMultiLeg ? getMultiLegOrderPrice(lang.tradeTicket.input, order?.spreadInstructions)?.price : order.limitPrice;
        const unitQuantity = order.unitQuantity || 1 / (secMeta?.sizing?.multiplier || 1);
        const figure = unitQuantity && !!rawLimitPrice && !!order.orderQuantity ? (rawLimitPrice * order.orderQuantity) / unitQuantity : NaN;
        return FormatNumber.toMoney(figure);
    }
};

export const getTif = (order: Order, lang: Snex1LanguagePack): string | undefined => {
    return (() => {
        switch (order.timeInForce) {
            case 'DAY':
                return 'Day';
            case 'Day':
                return 'Day';
            case 'GoodTillCancel':
                return 'GTC';
            case 'GTXPre':
                return 'Pre-Market Enabled';
            case 'GTXPost':
            case 'FillOrKill':
                return lang.tradeTicket.input.timesInForce.fok;
            case 'NTE':
                return 'Post-Market Enabled';
        }
    })();
};

export const getStatusColor = (order: Order, colors?: AppColorTheme): string | undefined => {
    const assetColor = () => (FuturesSymbol.IsFuturesSymbol(order?.symbol) ? colors?.futuresColor : colors?.grayDark);

    return (() => {
        switch (order.orderStatus) {
            case 'Open':
                return colors?.primaryItemColor;
            case 'Working':
                return assetColor();
            case 'Completed':
            case 'Filled':
                return ChangeColor(1);
            case 'Rejected':
                return ChangeColor(-1);
            default:
                return undefined;
        }
    })();
};

const getIsMultiLeg = (order: Order) => order?.complexOrders?.length;
const getIsFilled = (order: Order) => order?.orderStatus?.toLocaleLowerCase() === 'filled';

// Concat multi-leg order props, or return regular orders as normal
const concatOrders = (order: Order, format: (order: Order) => string) => {
    return getIsMultiLeg(order) ? [order, ...(order.complexOrders || [])].map((o) => format(o)).join('\n') : format(order);
};

export type InfoGridMatrixGridletModelProps = {
    assetClass: AssetClass;
    hasAdvancedRouting: boolean;
    order: Order;
    securityMetadata?: SecurityMetadataV2;
    text: Snex1LanguagePack;
};

export const getInfoMatrixGridletModels = ({
    assetClass,
    hasAdvancedRouting,
    order,
    securityMetadata,
    text
}: InfoGridMatrixGridletModelProps): InfoMatrixGridletModel[] => {
    const description = securityMetadata?.info?.securityName || securityMetadata?.info?.description || '';
    const limitPrice = getIsMultiLeg(order) ? getMultiLegOrderPrice(text.tradeTicket.input, order?.spreadInstructions)?.price || 0 : order.limitPrice || 0;
    const formatValue = (v: number) => assetClass.formatPrice(v, securityMetadata);

    if (assetClass.family === 'futures') {
        return getFuturesInfoGridlet({ description, formatValue, order, text, secMeta: securityMetadata, limitPrice });
    } else if (assetClass.family === 'equities') {
        return getEquitiesInfoGridlet({ formatValue, order, text, limitPrice, description, hasAdvancedRouting, securityMetadata });
    } else if (assetClass.family === 'cryptos') {
        return getCryptosInfoGridlet({ formatValue, order, text, limitPrice, description, hasAdvancedRouting });
    }

    return [];
};

export type AssetInfoGridMatrixGridletModelProps = Omit<InfoGridMatrixGridletModelProps, 'assetClass' | 'hasAdvancedRouting'> & {
    description: string;
    formatValue: (v: number) => string;
    hasAdvancedRouting?: boolean;
    secMeta?: SecurityMetadataV2;
    limitPrice?: number;
};

export const getFuturesInfoGridlet = ({ description, formatValue, secMeta, limitPrice, order, text }: AssetInfoGridMatrixGridletModelProps): InfoMatrixGridletModel[] => {
    const timeInForce = getTif(order, text);
    const futuresSymbolInfo = new FuturesSymbol(order?.symbol);
    const isFilled = getIsFilled(order);
    const isRejected = order.orderStatus.toLowerCase() === 'rejected'
    const expDate = order.expirationDate ? SafeFormatLocale({ date: order.expirationDate, formatString: 'dd MMM yyyy', locale: text.general.dateFnsLocale }) : '';

    if (futuresSymbolInfo.isTimeSpread) {
        const { buySideContract, sellSideContract, buySideFillPrice, sellSideFillPrice } = getFuturesTimeSpreadBuySellInfo(order, secMeta);

        return [
            { label: text.orders.securityName, value: description },
            { label: text.orders.placedBy, value: order.enteredBy },
            {
                label: text.orders.spreadSymbol,
                value: concatOrders(order, (order) =>
                    QualifiedId.RemovePrefix(
                        OptionSymbol.IsOptionSymbol(order.securityName) ? new OptionSymbol(order.securityName)?.cqgSymbol || '' : order.securityName || ''
                    )
                )
            },
            !isFilled
                ? {}
                : {
                      label: text.orders.fillPrice,
                      value: order?.averageUnitPrice,
                      formatter: formatValue
                  },
            {
                label: text.orders.limitPrice,
                value: limitPrice,
                formatter: formatValue
            },
            {
                label: text.tradeTicket.input.stopPrice,
                value: order.stopPrice || undefined,
                formatter: formatValue
            },
            { label: text.tradeTicket.input.timeInForce, value: timeInForce },
            { label: text.orders.commission, value: order.commissionAmount, type: 'money' },
            { label: text.orders.orderNumber, value: concatOrders(order, (order) => QualifiedId.RemovePrefix(`${order.orderNumber}`)), type: 'ellipsis' },
            { label: text.orders.accountNumber, value: QualifiedId.RemovePrefix(`${order.accountNumber}`) },
            {
                label: text.orders.expirationDate,
                value: expDate
            },
            {
                label: text.orders.buySide,
                value: `${order.orderQuantity} ${QualifiedId.RemovePrefix(buySideContract)} ${isFilled ? '@ ' + formatValue(buySideFillPrice) : ''}`
            },
            {
                label: text.orders.sellSide,
                value: `${order.orderQuantity} ${QualifiedId.RemovePrefix(sellSideContract)} ${isFilled ? '@ ' + formatValue(sellSideFillPrice) : ''}`
            },
            isRejected?  { label: 'Rejected Reason', value: order.rejectionReason || (order.orderWarnings||[]).join('. ')} : {},
        ].filter((x) => x.value !== undefined) as InfoMatrixGridletModel[];
    } else {
        return [
            { label: text.orders.securityName, value: description },
            { label: text.orders.placedBy, value: order.enteredBy },
            {
                label: text.orders.contractSymbol,
                value: concatOrders(order, (order) =>
                    QualifiedId.RemovePrefix(
                        (OptionSymbol.IsOptionSymbol(order.securityName) ? new OptionSymbol(order.securityName)?.cqgSymbol : order.securityName) || ''
                    )
                )
            },
            !isFilled
                ? {}
                : {
                      label: text.orders.fillPrice,
                      value: order?.averageUnitPrice,
                      formatter: formatValue
                  },
            {
                label: text.tradeTicket.input.limitPrice,
                value: limitPrice,
                formatter: formatValue
            },
            {
                label: text.tradeTicket.input.stopPrice,
                value: order.stopPrice || undefined,
                formatter: formatValue
            },
            { label: text.tradeTicket.input.timeInForce, value: timeInForce },
            { label: text.orders.commission, value: order.commissionAmount, type: 'money' },
            { label: text.orders.orderNumber, value: concatOrders(order, (order) => QualifiedId.RemovePrefix(`${order.orderNumber}`)), type: 'ellipsis' },
            { label: text.orders.accountNumber, value: QualifiedId.RemovePrefix(`${order.accountNumber}`) },
            { label: text.orders.expirationDate, value: expDate },
            isRejected?  { label: 'Rejected Reason', value: order.rejectionReason || (order.orderWarnings||[]).join('. ')} : {},
        ].filter((x) => x.value !== undefined) as InfoMatrixGridletModel[];
    }
};

type EquitiesInfoGridMatrixGridletModelProps = Omit<InfoGridMatrixGridletModelProps, 'assetFamily'> & { description: string; limitPrice: number };

export const getEquitiesInfoGridlet = ({
    description,
    formatValue,
    hasAdvancedRouting,
    order,
    text,
    limitPrice,
    securityMetadata
}: AssetInfoGridMatrixGridletModelProps): InfoMatrixGridletModel[] => {
    const timeInForce = getTif(order, text);
    const isOption = OptionSymbol.IsOptionSymbol(order?.symbol);
    const isOpen = order.orderStatus === 'Open';
    const leavesQuantity = order.leavesQuantity || 0;
    const filledQuantity = order.orderQuantity || 0 - leavesQuantity;
    const isPartiallyFilled = isOpen && !!order?.orderQuantity && order?.orderQuantity > 1 && filledQuantity > 0;
    const action = order?.action?.toLowerCase();
    const isBuyAction = action === 'buy' || action === 'buytoopen' || action === 'buytoclose';

    const singleOrderTotalCostLabel =
        order.orderStatus === 'Open'
            ? isBuyAction
                ? text.tradeTicket.input.estimatedTotalCost
                : text.tradeTicket.input.estimatedTotalCredit
            : isBuyAction
            ? text.tradeTicket.input.totalCost
            : text.tradeTicket.input.totalGain;

    const multiLegTotalCostLabel = !!order?.averageTradePriceAmount && order?.averageTradePriceAmount < 0 ? text.tradeTicket.input.totalGain : singleOrderTotalCostLabel;
    const totalCostLabel = getIsMultiLeg(order) ? multiLegTotalCostLabel : singleOrderTotalCostLabel;

    const filteredDescription: string = isOption ? (description || '').replace(/^(CALL|PUT)\s+/i, '') : '';

    const totalCostValue = () => {
        if (order.orderStatus !== 'Open' || !isPartiallyFilled) return getFormattedCost(order, text, securityMetadata, 2);
        if (!limitPrice) return undefined;
        if (isPartiallyFilled)
            return FormatNumber.toMoney(leavesQuantity * limitPrice + (!order?.fills ? 0 : order?.fills?.map((f) => f.price).reduce((acc, v) => acc + v, 0)));

        return FormatNumber.toMoney(leavesQuantity * limitPrice + (order.averageUnitPrice ?? 0) * filledQuantity);
    };

    const result = [
        { label: text.orders.securityName, value: isOption ? filteredDescription : description },
        { label: text.orders.placedBy, value: order.enteredBy },
        { label: totalCostLabel, value: totalCostValue() },
        { label: text.tradeTicket.input.limitPrice, value: limitPrice, formatter: formatValue },
        { label: text.tradeTicket.input.stopPrice, value: order.stopPrice || undefined, formatter: formatValue },
        { label: text.tradeTicket.input.timeInForce, value: timeInForce },
        { label: text.orders.commission, value: order.commissionAmount, type: 'money' },
        { label: 'CUSIP', value: order.cusip },
        { label: 'ISIN', value: order.isin },
        { label: text.orders.orderNumber, value: concatOrders(order, (order) => order.orderNumber) },
        { label: text.orders.accountNumber, value: QualifiedId.Format(order.accountNumber) },
        ...(hasAdvancedRouting && order.notHeld !== null && !['GTXPost', 'NTE', 'GTXPre'].includes(order?.timeInForce)
            ? [{ label: text.orders.strategy, value: noteAndNotHeldToAlgoStrategy(text, order.floorNote, order.notHeld) }]
            : []),
        ...(order?.averageTradePriceAmount ? [{ label: text.orders.averageTradePrice, value: FormatNumber.toMoney(order?.averageTradePriceAmount) }] : []),
        ...(order?.onClose ? [{ label: text.orders.onClose, value: 'True' }] : []),
        ...(['GoodTillCancel'].includes(order?.timeInForce) && ['Filled', 'Canceled', 'CancelPartialFill'].includes(order.orderStatus)
            ? [
                  {
                      label: text.orders.completedDate,
                      value:
                          !!order.completedDate &&
                          SafeFormatLocale({ date: order.completedDate, formatString: 'dd MMM yyyy, h:mm a', locale: text.general.dateFnsLocale })
                  }
              ]
            : [])
    ].filter((x) => !!x.value) as InfoMatrixGridletModel[];

    return result;
};

export const getCryptosInfoGridlet = ({ description, formatValue, order, text, limitPrice }: AssetInfoGridMatrixGridletModelProps): InfoMatrixGridletModel[] => {
    const timeInForce = getTif(order, text);
    const isFilled = getIsFilled(order);
    const isOpen = order.orderStatus === 'Open';
    const totalCostLabel = isOpen ? text.tradeTicket.input.estimatedTotalCredit : text.tradeTicket.input.totalCost;
    const totalGainLabel = isOpen ? text.tradeTicket.input.estimatedTotalCredit : text.tradeTicket.input.totalGain;
    const isBuy = order?.action === 'Buy';
    const costGain = order.quantityQualifier === 'Shares' ? (!isOpen ? order.cost : order.orderQuantity || 0 * (order.limitPrice || 0)) : order.orderQuantity;

    return [
        { label: text.orders.securityName, value: description },
        { label: text.orders.placedBy, value: order.enteredBy },
        isBuy ? { label: totalCostLabel, value: costGain, formatter: formatValue } : { label: totalGainLabel, value: costGain, formatter: formatValue },
        !isFilled ? {} : { label: text.orders.filledAmount, value: order?.filledQuantity, formatter: formatValue },
        { label: text.tradeTicket.input.limitPrice, value: limitPrice || undefined, formatter: formatValue },
        { label: text.tradeTicket.input.stopPrice, value: order.stopPrice || undefined, formatter: formatValue },
        !isFilled
            ? {}
            : {
                  label: text.orders.fillPrice,
                  value: order?.averageUnitPrice,
                  formatter: formatValue
              },
        { label: text.orders.orderNumber, value: QualifiedId.RemovePrefix(`${order.orderNumber}`), type: 'ellipsis' },
        { label: text.orders.accountNumber, value: QualifiedId.Format(order.accountNumber) }
    ].filter((x) => x.value !== undefined) as InfoMatrixGridletModel[];
};

type FuturesTimeSpreadBuySellInfo = {
    buySideContract: string;
    sellSideContract: string;
    buySideFillPrice: number;
    sellSideFillPrice: number;
};

export function getFuturesTimeSpreadBuySellInfo(order: Order, meta?: SecurityMetadataV2): FuturesTimeSpreadBuySellInfo {
    const futuresSymbolInfo = new FuturesSymbol(order?.symbol);
    // If selling, the actions are reversed from the symbology
    // Example: Buying ES FTS -Z23,+H24 => -Z23 is the sell side, +H24 is the buy side
    // But when selling ES FTS -Z23, +24 => -Z23 is the buy side, +H24 is the sell side

    const buyIndication = meta?.timeSpread?.buyIndication;
    const buySymbol = order.action?.toLowerCase() === 'buy' ? '+' : '-';

    let buySideContract: string | undefined, sellSideContract: string | undefined;
    if (buyIndication) {
        [buySideContract, sellSideContract] =
            buyIndication === 'BuyNearSellFar'
                ? [futuresSymbolInfo.nearMonthContract, futuresSymbolInfo.farMonthContract]
                : [futuresSymbolInfo.farMonthContract, futuresSymbolInfo.nearMonthContract];
    } else {
        buySideContract = futuresSymbolInfo.nearMonthDirection === buySymbol ? futuresSymbolInfo.nearMonthContract || '' : futuresSymbolInfo.farMonthContract || '';
        sellSideContract = buySideContract === futuresSymbolInfo.nearMonthContract ? futuresSymbolInfo.farMonthContract || '' : futuresSymbolInfo.nearMonthContract || '';
    }

    if (!buySideContract) buySideContract = '';
    if (!sellSideContract) sellSideContract = '';

    const buySideFillPrice = order?.fills?.find((f) => f?.symbol === buySideContract)?.price || 0;
    const sellSideFillPrice = order?.fills?.find((f) => f?.symbol === sellSideContract)?.price || 0;

    return {
        buySideContract,
        sellSideContract,
        buySideFillPrice,
        sellSideFillPrice
    };
}
