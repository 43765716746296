// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { AffiliationIsValid } from "../Affiliations/Validation"
import { AgreementsAreValid } from "../Agreements/Validation"
import { ContactInfoIsValid } from "../ContactInfo/Validation"
import { EmploymentInfoIsValid } from "../EmploymentInfo/Validation"
import { FinancialInfo1IsValid, FinancialInfo2IsValid } from "../FinancialInfo/Validation"
import { PersonalInfoIsValid } from "../PersonalInfo/Validation"
import { RegulatoryInfoIsValid } from "../RegulatoryInfo/Validation"
import { ReviewIsValid } from "../Review/Validation"
import { TradingExperienceIsValid } from "../TradingExperience/Validation"
import { FuturesOnboarding_Certifications_Form } from "./Form"
import { CertificationsAreValid, getCertificationsErrors } from "./Validation"


export const futuresOnboarding_certifications_step: OnboardingStep = {
    label: "Certifications",
    isComplete: () => CertificationsAreValid(),
    isEnabled: () => AgreementsAreValid() && ReviewIsValid() && RegulatoryInfoIsValid() && AffiliationIsValid() && TradingExperienceIsValid() && EmploymentInfoIsValid() && FinancialInfo1IsValid() && FinancialInfo2IsValid() 
    && PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed,
    isCurrent: () => ['certification'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'certification'
}

export const futuresOnboarding_certifications_nextButton: OnboardingButton = {
    label: "Accept and Continue",
    onClick: () => {
        useFuturesOnboardingStore.getState().setValue('submitting', true)
        useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'submitting')
    },
    isPrimary: true,
    isEnabled: () =>  CertificationsAreValid() && AgreementsAreValid() && ReviewIsValid() && RegulatoryInfoIsValid() && AffiliationIsValid() && TradingExperienceIsValid() && EmploymentInfoIsValid() && FinancialInfo1IsValid() && FinancialInfo2IsValid() 
    && PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_certifications_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => 
            useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'agreements'),
    isPrimary: false,
    isEnabled: () => true
}


export const futuresOnboarding_certifications_screen: OnboardingScreen = {
    id: "certification",
    validate: getCertificationsErrors,
    buttons: [futuresOnboarding_certifications_prevButton, futuresOnboarding_certifications_nextButton],
    title: T(t => t.futuresOnboarding.agreements.clientCertTitle),
    subtitles: null,
    body: <FuturesOnboarding_Certifications_Form/>
}