// @ts-strict-ignore
import { Flex, LoadingSpinner } from "components"
import { T } from "phoenix/assets/lang/T"
import { Urls } from "phoenix/constants"
import { useAsyncEffect } from "phoenix/hooks/UseAsyncEffect"
import React from "react"
import { OnboardingEngine } from "../Base/OnboardingEngine"
import { OnboardingViewModel } from "../../../phoenix/onboarding/OnboardingTypes"
import { futuresOnboarding_affiliations_screen, futuresOnboarding_affiliations_step } from "./Screens/Affiliations/Index"
import { futuresOnboarding_agreements_screen, futuresOnboarding_agreements_step } from "./Screens/Agreements/Index"
import { futuresOnboarding_certifications_screen, futuresOnboarding_certifications_step } from "./Screens/Certifications/Index"
import { futuresOnboarding_contactInfo_screen, futuresOnboarding_contactInfo_step } from "./Screens/ContactInfo/Index"
import { futuresOnboarding_employmentInfo2_screen, futuresOnboarding_employmentInfo_screen, futuresOnboarding_employmentInfo_step } from "./Screens/EmploymentInfo/Index"
import { futuresOnboarding_financialInfo1_screen, futuresOnboarding_financialInfo2_screen, futuresOnboarding_financialInfo_step } from "./Screens/FinancialInfo/Index"
import { futuresOnboarding_personalInfo_screen, futuresOnboarding_personalInfo_step } from "./Screens/PersonalInfo/Index"
import { futuresOnboarding_regulatoryInfo_screen, futuresOnboarding_regulatoryInfo_step } from "./Screens/RegulatoryInfo/Index"
import { futuresOnboarding_review_screen, futuresOnboarding_review_step } from "./Screens/Review/Index"
import { futuresOnboarding_tradingExperience_screen, futuresOnboarding_tradingExperience_step } from "./Screens/TradingExperience/Index"
import { futuresOnboarding_welcome_screen, futuresOnboarding_welcome_step } from "./Screens/Welcome/Index"
import { useFuturesOnboardingStore } from "./Store" 
import moment from "moment-timezone"
import { futuresOnboarding_submitting_screen } from "./Screens/Submitting/Index"
import { XBackgroundScreen } from "components/XBackgroundScreen/XBackgroundScreen"
import { LogOnboardingError, LogOnboardingStarted, LogOnboardingSuccess } from "components/Onboarding/OnboardingLogging"
import { SnexAxios } from "phoenix/stores/AxiosHelpers"
import { OnboardingData } from "phoenix/redux/models/FuturesOnboarding/OnboardingData"
import { AllowedFuturesCountryOptions } from "../_constants/DropdownOptions/CountryOptions"

export const FuturesOnboardingViewModel: OnboardingViewModel = {
    steps: [futuresOnboarding_welcome_step, futuresOnboarding_contactInfo_step, futuresOnboarding_personalInfo_step, 
        futuresOnboarding_financialInfo_step, futuresOnboarding_employmentInfo_step, futuresOnboarding_tradingExperience_step,
        futuresOnboarding_affiliations_step, futuresOnboarding_regulatoryInfo_step, futuresOnboarding_review_step,
        futuresOnboarding_agreements_step, futuresOnboarding_certifications_step],
    screens: [futuresOnboarding_welcome_screen, futuresOnboarding_contactInfo_screen, futuresOnboarding_personalInfo_screen,
        futuresOnboarding_financialInfo1_screen, futuresOnboarding_financialInfo2_screen, futuresOnboarding_employmentInfo_screen,
        futuresOnboarding_employmentInfo2_screen, futuresOnboarding_tradingExperience_screen, futuresOnboarding_affiliations_screen,
        futuresOnboarding_regulatoryInfo_screen, futuresOnboarding_review_screen, futuresOnboarding_agreements_screen,
        futuresOnboarding_certifications_screen, futuresOnboarding_submitting_screen],
    headerText: T(t => t.futuresOnboarding.sfi),
    stepsDisabled: () => useFuturesOnboardingStore.getState().submitting
}

export const FuturesOnboarding = () => {
    const {data, selectedScreenId, setValue, loaded} = useFuturesOnboardingStore()
    const updateSelectedScreen = (value: string) => {
        console.log(`setting screen to ${value}`)
        setValue('selectedScreenId', value) 
    }

    useAsyncEffect(async () => {
        if (!data?.contactInfo?.email) {
            try {
                const _data = await SnexAxios.ApiGet<OnboardingData>(Urls.futuresOnboarding.getFuturesOnboarding()).run()
                if(_data) {
                    if(_data.personalInfo?.dob) {
                        _data.personalInfo.dob = moment(_data?.personalInfo?.dob).format("MM/DD/YYYY")
                    }
                    if(!_data.personalInfo?.country || !AllowedFuturesCountryOptions.some(x => x.value.toUpperCase() === _data.personalInfo?.country?.toUpperCase())) {
                        _data.personalInfo.country = 'US'
                    }
                    setValue('data', _data)
                    setValue('isWelcomed', true)
                }
            }
            catch(e)
            {
                console.log({e})
                LogFuturesOnboardingFailed(e)
            }
            finally
            {
                setValue('loaded', true)
            }
        }
    }, [data]);

    const loadingScreen = <XBackgroundScreen>
        <Flex column center fullWidth style={{height: '100%'}}>
                <LoadingSpinner />
        </Flex>
    </XBackgroundScreen>
    
    return (
        !useFuturesOnboardingStore || !loaded ? loadingScreen :
        <OnboardingEngine viewModel={FuturesOnboardingViewModel} selectedScreenId={selectedScreenId} setScreenId={updateSelectedScreen }/>  
    )
}  

const getContextForLogging = () => {
    const store = useFuturesOnboardingStore.getState()?.data
    const context = {profileId: store?.contactInfo?.email, firstName: store?.contactInfo?.firstName, lastName: store?.contactInfo?.lastName,
        email: store?.contactInfo?.email}
    return context
}

export const LogFuturesOnboardingStarted = async () => {
    await LogOnboardingStarted({category: 'FuturesOnboarding'}, getContextForLogging())
}

export const LogFuturesOnboardingFailed = async (error: Error) => {
    await LogOnboardingError(error, {category: 'FuturesOnboarding'}, getContextForLogging())
}

export const LogFuturesOnboardingSuccess = async (context?: { [key: string]: any }) => {
    await LogOnboardingSuccess({category: 'FuturesOnboarding'}, {...context, ...getContextForLogging()})
}