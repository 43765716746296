import { useAppDispatch } from 'AppRoot';
import { ErrorBoundary, errorWrap } from 'components/ErrorBoundary/ErrorBoundary';
import { TelemetryCategories } from 'constants/Telemetry/TelemetryCategories';
import { useFeatureFlag } from 'phoenix/hooks/UseFeatureFlag';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ApiData } from 'phoenix/models';
import { useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetSecurityQuoteAction } from 'phoenix/redux/actions';
import { SecurityLogo } from 'phoenix/redux/models/SecurityLogo/SecurityLogo';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { GetSecurityTypeFromStoreV2 } from 'phoenix/util';
import { GetVariant } from 'phoenix/util/Variant';
import { TelemetryProvider } from 'providers/TelemetryContext';
import React, { useEffect, useMemo } from 'react';
import { Card } from '../Card';
import CryptoTradeTicket from './Crypto/CryptoTradeTicket';
import { EquityTradeTicket } from './Equity/EquityTradeTicket';
import { FundTradeTicket } from './Fund/FundTradeTicket';
import { FutureTradeTicket } from './Futures/FutureTradeTicket';
import './index.css';
import { OptionTradeTicket } from './Option/OptionTradeTicket';
import { GetTradeTicketQuote } from './Shared/helpers';
import { SecurityUnavailableForTrading } from './Shared/SecurityUnavailableForTrading';
import { TradeLoadingPage } from './Shared/TradeLoadingPage';
import { TradeTicketViewModel } from './Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from './Store/useTradeTicketViewModel';

interface TradeTicketProps {
    card?: boolean;
    logo?: ApiData<SecurityLogo>;
    style?: React.CSSProperties;
}

const ConditionalCard = ({ condition, children, className }: { condition: boolean; children: JSX.Element; className?: string }) =>
    condition ? <Card className={className}>{children}</Card> : <>{children}</>;

const TradeTicketComponent = (props: TradeTicketProps) => {
    const { card = true, logo, style } = props;
    const { loading: loadingFromStore, symbol = '' } = useTradeTicketViewModel<TradeTicketViewModel>();
    const dispatch = useAppDispatch();
    const secType = GetSecurityTypeFromStoreV2(symbol);
    const apiQuote = useSnexStore((s) => s.securityQuote.bySymbol[symbol]); // Use for loading initial request state, Xstream state doesn't work well for this
    const quote = GetTradeTicketQuote(symbol);
    const { loadData, getMetadataBySymbol, loading: metaLoading, symbolLoaded } = useSecurityMetadataV2();
    const meta = getMetadataBySymbol(symbol);
    const text = useText((t) => t);
    const assetClass = useAssetClassMetaV2(symbol);
    const loading = loadingFromStore || apiQuote?.pristine || !apiQuote || apiQuote?.loading || !meta || !symbolLoaded(symbol) || metaLoading[symbol] || !symbol;
    const flaggedOff = !useFeatureFlag(assetClass.flags?.web?.trade || '') && !!assetClass.flags?.web?.trade;
    const completelyUnknown = useMemo(() => !loading && !secType, [loading, secType]);
    const { load: loadPositions } = usePositionsStore();

    useEffect(() => {
        if (symbol) {
            dispatch(GetSecurityQuoteAction(symbol));
            const run = async () => {
                await loadPositions();
                await loadData(symbol);
            };
            run();
        }
    }, [assetClass?.derivative, dispatch, loadData, loadPositions, symbol]);

    // hide trade ticket if not allowed by variant
    if (!GetVariant()?.showTradeTicket) return null;

    if (loading || metaLoading[symbol.toUpperCase()]) return <TradeLoadingPage />;
    if (completelyUnknown || flaggedOff) {
        return (
            <div id={'trade-ticket'} className={`trade-ticket ${assetClass.family}`}>
                <Card>
                    <SecurityUnavailableForTrading {...{ logo, quote, symbol, text }} />
                </Card>
            </div>
        );
    }

    return (
        <div id={'trade-ticket'} className={`trade-ticket ${assetClass.family}`} style={style}>
            <ConditionalCard condition={card}>
                <ErrorBoundary>
                    <Content />
                </ErrorBoundary>
            </ConditionalCard>
        </div>
    );
};

export const TradeTicket = React.memo(TelemetryProvider(TradeTicketComponent, TelemetryCategories.trade));

const CardContentComponent = () => {
    const { symbol = '' } = useTradeTicketViewModel<TradeTicketViewModel>();
    const secType = GetSecurityTypeFromStoreV2(symbol);

    switch (secType) {
        case 'mutual-fund':
            return errorWrap(<FundTradeTicket />, false, true);
        case 'option':
            return errorWrap(<OptionTradeTicket />, false, true);
        case 'future':
            return errorWrap(<FutureTradeTicket />, false, true);
        case 'crypto':
            return errorWrap(<CryptoTradeTicket />, false, true);
        case null:
            return errorWrap(<TradeLoadingPage />, false, true);
        case 'equity':
        case 'adr':
        default:
            return errorWrap(<EquityTradeTicket />, false, true);
    }
};

const Content = React.memo(CardContentComponent);
Content.displayName = 'ContentMemo';
