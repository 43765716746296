export const disclosures = [
    [
        {
            text: 'Fundamental data, earnings and estimates information, analysts ratings, content and news provided by Finnhub, IEX Cloud and StoneX.'
        }
    ], [
        {
            text: 'Check the background of StoneX Securities Inc. on '
        }, {
            text: 'FINRA\'s BrokerCheck.',
            url: 'https://brokercheck.finra.org/firm/summary/18456'
        }
    ], [
        {
            text: 'Check the background of the FCM division of StoneX Financial Inc. on '
        }, {
            text: 'NFA\'s BASIC system.',
            url: 'https://www.nfa.futures.org/BasicNet/basic-search-landing.aspx'
        }
    ], [
        {
            text: 'All investments involve risk, including loss of principal. '
        }, {
            text: 'Past performance of any security, futures, option, or strategy is not indicative of future success.',
            weight: 'bold'
        }, {
            text: ' Consider your personal financial situation, including your risk tolerance, before investing.'
        }
    ], [
        {
            text: 'Securities products and services are offered through StoneX Securities Inc., a registered broker-dealer and member '
        }, {
            text: 'FINRA',
            url: 'https://www.finra.org/#/'
        }, {
            text: '/'
        }, {
            text: 'SIPC',
            url: 'https://www.sipc.org/'
        }, {
            text: '. StoneX One accounts opened through StoneX Securities are introduced to and custodied at StoneX Financial Inc. (SFI), and all customer orders will be transmitted to SFI for execution, clearance and settlement.'
        }
    ],
    [
        {
            text: 'SFI is a member of '
        }, {
            text: 'FINRA',
            url: 'https://www.finra.org/#/'
        }, {
            text: '/'
        },
        {
            text: 'NFA',
            url: 'https://www.nfa.futures.org/'
        }, {
            text: '/'
        }, {
            text: 'SIPC',
            url: 'https://www.sipc.org/'
        }, {
            text: ' and registered with the MSRB. SFI is registered with both the U.S. Securities and Exchange Commission (SEC) as a Broker-Dealer and with the U.S. Commodity Futures Trading Commission (CFTC) as a Futures Commission Merchant and Commodity Trading Advisor. Exchange based futures and options products and services are offered solely through the FCM division of SFI.'
        }
    ], [
        {
            text: 'StoneX Securities and SFI are wholly-owned subsidiaries of StoneX Group Inc. (NASDAQ: SNEX).'
        }
    ], [
        {
            text: 'News, commentary and events are from third-party sources unaffiliated with StoneX. StoneX does not endorse or adopt their content. StoneX makes no guarantees that information supplied is accurate, complete, or timely, and does not provide any warranties regarding results obtained from their use.'
        }
    ], [
        {
            text: 'Performance data shown represents past performance and is no guarantee of future results. Securities and Market Values are priced real-time and based on Last Sale or a consolidated market quote unless otherwise indicated. If the current market price is unavailable or the market is closed, the most recent closing market price will be displayed.'
        }
    ], [
        {
            text: 'All data displayed on this page is for informational purposes only. Your statement is the official record of holdings and balances in your account.'
        }
    ], [
        {
            text: 'The price of a given security may increase or decrease based on market conditions and customers may lose money, including their original investment. The product features described below are meant for informational purposes only and are not intended to serve as a recommendation to a customer to buy, hold or sell any security or any other asset.'
        }
    ], [
        {
            text: 'Commission-free trading refers to $0 commissions for StoneX One self-directed individual cash or margin brokerage accounts that trade U.S. listed and certain OTC securities electronically. Other fees such as trading (non-commission) fees, wire transfer fees, and paper statement fees may apply to your brokerage account. Please see the StoneX One fee schedule to learn more. '
        }
    ], [
        {
            text: 'Exchange Traded Products (ETPs, ETFs, ETNs)',
            weight: 'bold'
        }
    ], [
        {
            text: 'Investors should consider the investment objectives and unique risk profile of any Exchange Traded Product (ETP), including any Exchange-Traded Fund (ETF) and any Exchange-Traded Notes (ETNs), carefully before investing. A prospectus contains this and other information about the ETP and should be read carefully before investing. Customers should visit the relevant ETP\'s details page to access a link to the prospectus.'
        }
    ], [
        {
            text: 'ETPs that are designed to provide investment results that generally correspond to the performance of their respective underlying indices may not be able to exactly replicate the performance of the indices because of expenses and other factors. ETP shares are bought and sold at market price, which may be higher or lower than their NAV. ETPs are required to distribute portfolio profits to shareholders at year end. These profits may be generated by portfolio rebalancing or the need to meet diversification requirements. ETNs are subject to the credit risk of the underlying issuer. If the issuer defaults on the note, investors may lose some or all of their investment. ETP trading will also generate tax consequences. Additional regulatory guidance on Exchange Traded Products can be found at the '
        }, {
            text: 'SEC Website',
            url: 'https://www.sec.gov/investor/alerts/etfs.pdf'
        }, {
            text: ' and at the '
        }, {
            text: 'FINRA website',
            url: 'https://www.finra.org/investors/insights/exchange-traded-notes-avoid-unpleasant-surprises'
        }, {
            text: '.'
        }
    ], [
        {
            text: 'StoneX Securities and SFI are wholly-owned subsidiaries of StoneX Group Inc. (NASDAQ: SNEX).'
        }
    ], [
        {
            text: 'News, commentary and events are from third-party sources unaffiliated with StoneX. StoneX does not endorse or adopt their content. StoneX makes no guarantees that information supplied is accurate, complete, or timely, and does not provide any warranties regarding results obtained from their use.'
        }
    ], [
        {
            text: 'Performance data shown represents past performance and is no guarantee of future results. Securities and Market Values are priced real-time and based on Last Sale or a consolidated market quote unless otherwise indicated. If the current market price is unavailable or the market is closed, the most recent closing market price will be displayed.'
        }
    ], [
        {
            text: 'All data displayed on this page is for informational purposes only. Your statement is the official record of holdings and balances in your account.'
        }
    ], [
        {
            text: 'The price of a given security may increase or decrease based on market conditions and customers may lose money, including their original investment. The product features described below are meant for informational purposes only and are not intended to serve as a recommendation to a customer to buy, hold or sell any security or any other asset.'
        }
    ], [
        {
            text: 'Commission-free trading refers to $0 commissions for StoneX One self-directed individual cash or margin brokerage accounts that trade U.S. listed and certain OTC securities electronically. Other fees such as trading (non-commission) fees, wire transfer fees, and paper statement fees may apply to your brokerage account. Please see the StoneX One fee schedule to learn more.'
        }
    ], [
        {
            text: 'Exchange Traded Products (ETPs, ETFs, ETNs)',
            weight: 'bold'
        }
    ], [
        {
            text: 'Investors should consider the investment objectives and unique risk profile of any Exchange Traded Product (ETP), including any Exchange-Traded Fund (ETF) and any Exchange-Traded Notes (ETNs), carefully before investing. A prospectus contains this and other information about the ETP and should be read carefully before investing. Customers should visit the relevant ETP\'s details page to access a link to the prospectus.'
        }
    ], [
        {
            text: 'ETPs that are designed to provide investment results that generally correspond to the performance of their respective underlying indices may not be able to exactly replicate the performance of the indices because of expenses and other factors. ETP shares are bought and sold at market price, which may be higher or lower than their NAV. ETPs are required to distribute portfolio profits to shareholders at year end. These profits may be generated by portfolio rebalancing or the need to meet diversification requirements. ETNs are subject to the credit risk of the underlying issuer. If the issuer defaults on the note, investors may lose some or all of their investment. ETP trading will also generate tax consequences. Additional regulatory guidance on Exchange Traded Products can be found at the '
        }, {
            text: 'SEC Website',
            url: 'https://www.sec.gov/investor/alerts/etfs.pdf'
        }, {
            text: ' and at the '
        }, {
            text: 'FINRA website',
            url: 'https://www.finra.org/investors/insights/exchange-traded-notes-avoid-unpleasant-surprises'
        }, {
            text: '.'
        }
    ], [
        {
            text: 'Leveraged and inverse ETPs may involve greater risk and not be suitable for all investors, particularly for buy-and-hold investors. Volatility linked ETPs pose special risks tied to market volatility that can significantly impact the pricing of the product and your ability to trade them during times of extreme market volatility. These types of ETPs generally reset daily and are not designed to, and will not necessarily, track the underlying index or benchmark over a longer period of time. Investing in such ETPs may increase exposure to volatility through the use of leverage, short sales of securities, derivatives and other complex investment strategies.'
        }
    ], [
        {
            text: 'Mutual Funds',
            weight: 'bold'
        }, {
            text: 'Investors should consider the investment objectives, risks, charges and expenses of the mutual fund and its investment options. This information is available in the mutual fund\'s prospectus. Investors should carefully read the prospectus before making investment decisions. For Mutual funds, the NAV is a daily calculation occurring after market close. This process may take 2-to-4 hours before a final NAV is made available to the public. Mutual fund values for Day Change and Price Change will be reset to zero approximately (4) hours before market open on Monday, and approximately (1) hour before market open Tuesday through Friday. After market open, Day Change and Price Change will appear if there is a valid value to present. If there is no quote, Day Change and/or Price Change values will show N/A.'
        }
    ], [
        {
            text: 'Margin',
            weight: 'bold'
        }, {
            text: 'Margin trading involves interest charges and risks, including the potential to lose more than deposited or the need to deposit additional collateral in a falling market. Before using margin, customers must determine whether this type of trading strategy is right for them given their specific investment objectives, experience, risk tolerance, and financial situation. For more information, please see '
        }, {
            text: 'StoneX Financial\'s Margin Disclosure Statement',
            url: 'https://snex1storage.blob.core.windows.net/$web/docs/MarginDisclosureStatement.pdf'
        }, {
            text: '.'
        }
    ], [
        {
            text: 'Online Trading',
            weight: 'bold'
        }
    ], [
        {
            text: 'System response, execution prices, speed, liquidity, market data, and account access times are affected by many factors, including market volatility, size and type of order, market conditions, system performance, and other factors. Please read the '
        }, {
            text: 'the Risks Associated with Electronic Trading',
            url: 'https://snex1storage.blob.core.windows.net/$web/docs/RisksAssociatedWithElectronicTrading.pdf'
        }, {
            text: ' for more information.'
        }
    ], [
        {
            text: 'Increased market volatility can result in record setting volume as well as extreme market conditions (sometimes called “fast markets” or “break out” market conditions). It is important for investors to understand the risks associated with such extreme market conditions as there may be delays in executing your order or providing trade status reports to you. In addition, if you place a market order in a Fast Market, there may be a significant difference in the quote you receive prior to or at the time you place the order and the execution price you receive.'
        },
    ], [
        {
            text: 'Options',
            weight: 'bold'
        }
    ], [
        {
            text: 'Options involve risks and are not suitable for all investors as the special risks inherent to options trading may expose investors to potentially rapid and substantial losses. Options trading privileges are subject to review and approval. Please read '
        }, {
            text: 'Characteristics and Risks of Standardized Options',
            url: 'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document'
        }, {
            text: ' before investing in options.'
        }
    ], [
        {
            text: ' StoneX Securities, StoneX Financial and their affiliates do not provide legal, tax, or accounting advice. Clients should consult their legal and/or tax advisors before making any financial decisions. This material is not intended as a recommendation, offer or solicitation for the purchase or sale of any security or investment strategy.'
        }
    ], [
        {
            text: 'Features and Balances Definitions',
            weight: 'bold'
        }
    ], [
        {
            text: 'Portfolio Value - The total market value of all positions in the account(s), including cash balances, minus any outstanding debit balances and any amount required to cover short in the money option positions. For securities priced daily, such as mutual funds, Today\'s Profit/Loss is calculated using the prior business day\'s market closing price. For securities priced real-time, Today\'s Profit/Loss is calculated using the last available price.'
        }
    ], [
        {
            text: 'Buying Power - The amount of total cash in the account available to purchase securities plus all available margin.'
        }
    ], [
        {
            text: 'Cash & Equivalents - The amount available to purchase securities in a Cash account without adding money to the account. Buy orders will reduce this value (at the time the order is placed), and Sell orders will increase this value (at the time the order settles).'
        }
    ], [
        {
            text: 'Long Market Value - Long market value indicates the net value of positions held long in the account. For securities priced daily, such as mutual funds, Today\'s Profit/Loss is calculated using the prior business day\'s market closing price. For securities priced real-time, Today\'s Profit/Loss is calculated using the last available price.'
        }
    ], [
        {
            text: 'Short Market Value - Short market value indicates the net value of positions held short in the account. This figure is reduced by the value of any in the money covered options and does not include shares held as Cash or Equivalents or Margin positions. For securities priced daily, such as mutual funds, Today\'s Profit/Loss is calculated using the prior business day\'s market closing price. For securities priced real-time, Today\'s Profit/Loss is calculated using the last available price.'
        }
    ], [
        {
            text: 'Amount Invested (Summary and Account Sections) - Amount invested is the aggregate amount you paid to purchase the securities position(s), including any expenses associated with the transaction(s).'
        }
    ], [
        {
            text: 'Today\'s Profit/Loss - Today\'s Profit/Loss reflects the current market value (current price * number of shares) of settled securities minus the market value at the market open. This includes settled positions only and does not include any pending activity (debits/credits) within the selected accounts. The percentage change reflects a ratio of the today\'s profile/loss value in relation to the market value of the position(s) at the market\'s open. For securities priced daily, such as mutual funds, Today\'s Profit/Loss is calculated using the prior business day\'s market closing price. For securities priced real-time, Today\'s Profit/Loss is calculated using the last available price.'

        }
    ], [
        {
            text: 'Total Profit/Loss - The total profit/loss is calculated by subtracting the Total Cost from the current market value (current price * number of shares) of the position.'
        }
    ], [
        {
            text: 'Total Cost (Positions Section) - Total Cost is the aggregate amount you paid to purchase the position(s), including any expenses associated with the transaction(s).'
        }
    ], [
        {
            text: 'Open Profit/Loss also known as Unrealized Profit/Loss (Summary and Account Sections) - This is the potential profit/loss you could experience by selling your investment. A profit or loss becomes realized when the investment is actually sold.'
        }
    ], [
        {
            text: 'Sectors and Movers - The TOP MOVERS/GAINERS/LOSERS and SECTORS lists are generated using data sourced from third-party data providers. A list of companies in a specific business sector may display a company if the source data indicates that the company derives a portion of its revenues from the relevant sector. The list is provided for informational purposes only and is not investment advice or a recommendation to buy, hold, or sell any security. Lists are not personalized recommendations to you or to any other customer, and the securities listed may not be suitable for you. You should not buy or sell any security without first determining it is appropriate for your portfolio or investment strategy. StoneX One does not make any warranty or guarantee relating to the accuracy, timeliness or completeness of any third-party information, and the provision of this information does not constitute a recommendation.'
        }
    ], [
        {
            text: 'Watchlist - A watchlist is a list of investments you want to track. If the current market price is unavailable or the market is closed, the most recent closing market price will be displayed. For securities priced daily, such as mutual funds, the prior business day\'s market closing price is displayed.'
        }
    ], [
        {
            text: 'Implied Volatility (applicable for options trading section only) - Implied volatility represents the expected volatility of a stock over the life of the option. As expectations change, option premiums react appropriately. Implied volatility is directly influenced by the supply and demand of the underlying options and by the market\'s expectation of the share price\'s direction.'
        }
    ],
    [
        {
            text: 'Crypto Trading and Account Information',
            weight:'bold',
        }
    ],
    [
        {
            text: 'All cryptocurrency trading activities facilitated through StoneX One Pro are conducted via ETANA, a trusted third-party service provider. Please be aware that when you engage in cryptocurrency trading, you are interacting directly with ETANA, and all trades are executed on their systems.'
        }
    ],
    [
        {
            text: 'Furthermore, your cryptocurrency accounts are opened and maintained at ETANA. This means that ETANA is responsible for holding, securing, and managing your crypto assets. StoneX One Pro serves as a conduit to ETANA’s services, providing you with access to their trading environment.'
        }
    ],
    [
        {
            text: 'Please note the following key points:'
        }
    ],
    [
        {
            text: '1. '
        },
        {
            text: 'Execution of Trades: ',
            weight: 'bold'
        },
        {
            text: 'All cryptocurrency trades initiated on StoneX One Pro are executed by ETANA. We do not execute any trades on our systems.'
        }
    ],
    [
        {
            text: '2. '
        },
        {
            text: 'Account Management: ',
            weight: 'bold'
        },
        {
            text: 'Your cryptocurrency accounts, including all deposits and withdrawals, are managed by ETANA.'
        }
    ],
    [
        {
            text: '3. '
        },
        {
            text: 'Security and Custody: ',
            weight: 'bold'
        },
        {
            text: 'ETANA is responsible for the security and custody of your cryptocurrency assets. StoneX Financial does not hold or secure any cryptocurrency assets directly.'
        }
    ],
    [
        {
            text: '4. '
        },
        {
            text: 'Fees and Charges: ',
            weight: 'bold'
        },
        {
            text: 'Any fees or charges related to cryptocurrency trading will be determined and collected by ETANA. Please refer to ETANA’s fee schedule for detailed information.'
        }
    ],
    [
        {
            text: '5. '
        },
        {
            text: 'Regulatory Oversight: ',
            weight: 'bold'
        },
        {
            text: 'ETANA operates under its own regulatory framework. It is essential to review ETANA’s terms of service, privacy policy, and any regulatory disclosures they provide.'
        }
    ],
    [
        {
            text: 'By using StoneX One Pro for cryptocurrency trading, you acknowledge and agree to these terms. It is important to conduct your own due diligence and understand the risks involved in cryptocurrency trading. For any issues or questions related to your crypto accounts or trades, please contact ETANA directly.'
        }
    ],
    [
        {
            text: 'Disclaimer: ',
            weight: 'bold'
        },
        {
            text:'Cryptocurrency trading involves significant risk and may result in the loss of your invested capital. Please trade responsibly.'
        }
    ], 
    [
        {
            text: 'Market Intelligence Disclaimer',
            weight: 'bold',
            size: 'large'
        }
    ],
    [
        {
            text: '© StoneX Group Inc. All intellectual property rights reserved in this report and its contents.'
        }
    ],
    [
        {
            text: 'Nothing contained herein shall be construed as conferring on Subscriber or any third party any unlimited license or right, by implication, estoppel or otherwise, under any law (whether common law or statutory law), rule or regulation including, without limitation those related to copyright or other intellectual property rights.'
        }
    ],
    [
        {
            text: 'This report and its contents shall not be deemed to be financial advice. This report and its contents therein is made available to Subscriber for informational purposes and are not a substitute for obtaining professional advice from a qualified person, firm, or corporation, where required. The accuracy, completeness, adequacy or timeliness of this report and its contents is not warranted or guaranteed. Subscriber acknowledges that the report and its contents may be work product utilising, incorporating or referring to information from third parties.'
        }
    ],
    [
        {
            text: 'Any unauthorized use of this report and its content is wholly prohibited. This report and its contents is for use by Subscriber only in accordance with its subscription and it may not copy, modify, reproduce, republish, distribute, display, or transmit for commercial, non-profit or public purposes all or any portion thereof, and any other purpose for its benefit or the benefit of any third party. For the avoidance of doubt Subscriber may not use this report and any of its contents therein to transmit any false, misleading, fraudulent or illegal communications.'
        }
    ],
    [
        {
            text: 'StoneX Group Inc. disclaims all liability from the reliance, use of this report and its contents for any purpose howsoever arising from the subscription, Subscriber\'s use and any third party.'
        }
    ]
]

export const proDisclosures = [
    [
        {
            text: 'Fundamental data, earnings and estimates information, analysts ratings, content and news provided by Finnhub, IEX Cloud and StoneX.'
        }
    ],
    [
        {
            text: 'Check the background of StoneX Financial Inc. on '
        }, {
            text: 'FINRA\'s BrokerCheck.',
            url: 'https://brokercheck.finra.org/firm/summary/45993'
        }
    ],
    [
        {
            text: 'Check the background of the FCM division of StoneX Financial Inc. on '
        }, {
            text: 'NFA\'s BASIC system.',
            url: 'https://www.nfa.futures.org/BasicNet/basic-search-landing.aspx'
        }
    ],
    [
        {
            text: 'All investments involve risk, including loss of principal. '
        }, {
            text: 'Past performance of any security, futures, option, or strategy is not indicative of future success.',
            weight: 'bold'
        }, {
            text: ' Consider your personal financial situation, including your risk tolerance, before investing.'
        }
    ],
    [
        {
            text: 'Securities products and services are offered through StoneX Financial Inc., a registered broker-dealer and member SFI is a member of '
        },
        {
            text: 'FINRA',
            url: 'https://www.finra.org/#/'
        },
        {
            text: '/'
        },
        {
            text: 'NFA',
            url: 'https://www.nfa.futures.org/'
        },
        {
            text: '/'
        },
        {
            text: 'SIPC',
            url: 'https://www.sipc.org/'
        },
        {
            text: 'and registered with the MSRB. SFI is registered with both the U.S. Securities and Exchange Commission (SEC) as a Broker-Dealer and with the U.S. Commodity Futures Trading Commission (CFTC) as a Futures Commission Merchant and Commodity Trading Advisor. Exchange based futures and options products and services are offered solely through the FCM division of SFI.'
        }
    ],
    [
        {
            text: 'SFI is a wholly-owned subsidiary of StoneX Group Inc. (NASDAQ: SNEX).'
        }
    ],
    [
        {
            text: 'News, commentary and events are from third-party sources unaffiliated with StoneX. StoneX does not endorse or adopt their content. StoneX makes no guarantees that information supplied is accurate, complete, or timely, and does not provide any warranties regarding results obtained from their use.'
        }
    ],
    [
        {
            text: 'Securities and Market Values are priced real-time and based on Last Sale or a consolidated market quote unless otherwise indicated. If the current market price is unavailable or the market is closed, the most recent closing market price will be displayed.'
        }
    ],
    [
        {
            text: 'All data displayed on this page is for informational purposes only. Your statement is the official record of holdings and balances in your account.'
        }
    ],
    [
        {
            text: 'The price of a given security may increase or decrease based on market conditions and customers may lose money, including their original investment. The product features described below are meant for informational purposes only and are not intended to serve as a recommendation to a customer to buy, hold or sell any security or any other asset.'
        }
    ],
    [
        {
            text: 'StoneX Financial Inc. and its affiliates do not provide legal, tax, or accounting advice. Clients should consult their legal and/or tax advisors before making any financial decisions. This material is not intended as a recommendation, offer or solicitation for the purchase or sale of any security or investment strategy. '
        }
    ],
    [
        {
            text: 'Exchange Traded Products (ETPs, ETFs, ETNs)',
            weight: 'bold'
        }
    ],
    [
        {
            text: 'Investors should consider the investment objectives and unique risk profile of any Exchange Traded Product (ETP), including any Exchange-Traded Fund (ETF) and any Exchange-Traded Notes (ETNs), carefully before investing. A prospectus contains this and other information about the ETP and should be read carefully before investing. Customers should visit the relevant ETP\'s details page to access a link to the prospectus.'
        }
    ],
    [
        {
            text: 'ETPs that are designed to provide investment results that generally correspond to the performance of their respective underlying indices may not be able to exactly replicate the performance of the indices because of expenses and other factors. ETP shares are bought and sold at market price, which may be higher or lower than their NAV. ETPs are required to distribute portfolio profits to shareholders at year end. These profits may be generated by portfolio rebalancing or the need to meet diversification requirements. ETNs are subject to the credit risk of the underlying issuer. If the issuer defaults on the note, investors may lose some or all of their investment. ETP trading will also generate tax consequences. Additional regulatory guidance on Exchange Traded Products can be found at the '
        },
        {
            text: 'SEC Website',
            url: 'https://www.sec.gov/investor/alerts/etfs.pdf'
        },
        {
            text: ' and at the '
        },
        {
            text: 'FINRA website',
            url: 'https://www.finra.org/investors/insights/exchange-traded-notes-avoid-unpleasant-surprises'
        },
        {
            text: '.'
        }
    ],
    [
        {
            text: 'Leveraged and inverse ETPs may involve greater risk and not be suitable for all investors, particularly for buy-and-hold investors. Volatility linked ETPs pose special risks tied to market volatility that can significantly impact the pricing of the product and your ability to trade them during times of extreme market volatility. These types of ETPs generally reset daily and are not designed to, and will not necessarily, track the underlying index or benchmark over a longer period of time. Investing in such ETPs may increase exposure to volatility through the use of leverage, short sales of securities, derivatives and other complex investment strategies.'
        }
    ],
    [
        {
            text: 'All data displayed on this page is for informational purposes only. Your statement is the official record of holdings and balances in your account.'
        }
    ],
    [
        {
            text: 'The price of a given security may increase or decrease based on market conditions and customers may lose money, including their original investment. The product features described below are meant for informational purposes only and are not intended to serve as a recommendation to a customer to buy, hold or sell any security or any other asset.'
        }
    ],
    [
        {
            text: 'Commission-free trading refers to $0 commissions for StoneX One self-directed individual cash or margin brokerage accounts that trade U.S. listed and certain OTC securities electronically. Other fees such as trading (non-commission) fees, wire transfer fees, and paper statement fees may apply to your brokerage account. Please see the StoneX One fee schedule to learn more.'
        }
    ],
    [
        {
            text: 'Exchange Traded Products (ETPs, ETFs, ETNs)',
            weight: 'bold'
        }
    ],
    [
        {
            text: 'Investors should consider the investment objectives and unique risk profile of any Exchange Traded Product (ETP), including any Exchange-Traded Fund (ETF) and any Exchange-Traded Notes (ETNs), carefully before investing. A prospectus contains this and other information about the ETP and should be read carefully before investing. Customers should visit the relevant ETP\'s details page to access a link to the prospectus.'
        }
    ],
    [
        {
            text: 'ETPs that are designed to provide investment results that generally correspond to the performance of their respective underlying indices may not be able to exactly replicate the performance of the indices because of expenses and other factors. ETP shares are bought and sold at market price, which may be higher or lower than their NAV. ETPs are required to distribute portfolio profits to shareholders at year end. These profits may be generated by portfolio rebalancing or the need to meet diversification requirements. ETNs are subject to the credit risk of the underlying issuer. If the issuer defaults on the note, investors may lose some or all of their investment. ETP trading will also generate tax consequences. Additional regulatory guidance on Exchange Traded Products can be found at the '
        }, {
            text: 'SEC Website',
            url: 'https://www.sec.gov/investor/alerts/etfs.pdf'
        }, {
            text: ' and at the '
        }, {
            text: 'FINRA website',
            url: 'https://www.finra.org/investors/insights/exchange-traded-notes-avoid-unpleasant-surprises'
        }, {
            text: '.'
        }
    ],
    [
        {
            text: 'Leveraged and inverse ETPs may involve greater risk and not be suitable for all investors, particularly for buy-and-hold investors. Volatility linked ETPs pose special risks tied to market volatility that can significantly impact the pricing of the product and your ability to trade them during times of extreme market volatility. These types of ETPs generally reset daily and are not designed to, and will not necessarily, track the underlying index or benchmark over a longer period of time. Investing in such ETPs may increase exposure to volatility through the use of leverage, short sales of securities, derivatives and other complex investment strategies.'
        }
    ],
    [
        {
            text: 'Mutual Funds',
            weight: 'bold'
        },
        {
            text: 'Investors should consider the investment objectives, risks, charges and expenses of the mutual fund and its investment options. This information is available in the mutual fund\'s prospectus. Investors should carefully read the prospectus before making investment decisions. For Mutual funds, the NAV is a daily calculation occurring after market close. This process may take 2-to-4 hours before a final NAV is made available to the public. Mutual fund values for Day Change and Price Change will be reset to zero approximately (4) hours before market open on Monday, and approximately (1) hour before market open Tuesday through Friday. After market open, Day Change and Price Change will appear if there is a valid value to present. If there is no quote, Day Change and/or Price Change values will show N/A.'
        }
    ],
    [
        {
            text: 'Margin',
            weight: 'bold'
        }, {
            text: 'Margin trading involves interest charges and risks, including the potential to lose more than deposited or the need to deposit additional collateral in a falling market. Before using margin, customers must determine whether this type of trading strategy is right for them given their specific investment objectives, experience, risk tolerance, and financial situation. For more information, please see '
        }, {
            text: 'StoneX Financial\'s Margin Disclosure Statement',
            url: 'https://snex1storage.blob.core.windows.net/$web/docs/MarginDisclosureStatement.pdf'
        }, {
            text: '.'
        }
    ],
    [
        {
            text: 'Online Trading',
            weight: 'bold'
        }
    ],
    [
        {
            text: 'System response, execution prices, speed, liquidity, market data, and account access times are affected by many factors, including market volatility, size and type of order, market conditions, system performance, and other factors. Please read '
        }, {
            text: 'the Risks Associated with Electronic Trading',
            url: 'https://snex1storage.blob.core.windows.net/$web/docs/RisksAssociatedWithElectronicTrading.pdf'
        }, {
            text: ' for more information.'
        }
    ],
    [
        {
            text: 'Increased market volatility can result in record setting volume as well as extreme market conditions (sometimes called “fast markets” or “break out” market conditions). It is important for investors to understand the risks associated with such extreme market conditions as there may be delays in executing your order or providing trade status reports to you. In addition, if you place a market order in a Fast Market, there may be a significant difference in the quote you receive prior to or at the time you place the order and the execution price you receive.'
        },
    ],
    [
        {
            text: 'Options',
            weight: 'bold'
        }
    ],
    [
        {
            text: 'Options involve risks and are not suitable for all investors as the special risks inherent to options trading may expose investors to potentially rapid and substantial losses. Options trading privileges are subject to review and approval. Please read'
        }, {
            text: 'Characteristics and Risks of Standardized Options',
            url: 'https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document'
        }, {
            text: ' before investing in options.'
        }
    ],
    [
        {
            text: 'Features and Balances Definitions',
            weight: 'bold'
        }
    ],
    [
        {
            text: 'Portfolio Value - The total market value of all positions in the account(s), including cash balances, minus any outstanding debit balances and any amount required to cover short in the money option positions. For securities priced daily, such as mutual funds, Today\'s Profit/Loss is calculated using the prior business day\'s market closing price. For securities priced real-time, Today\'s Profit/Loss is calculated using the last available price.'
        }
    ],
    [
        {
            text: 'Buying Power - The amount of total cash in the account available to purchase securities plus all available margin.'
        }
    ],
    [
        {
            text: 'Cash & Equivalents - The amount available to purchase securities in a Cash account without adding money to the account. Buy orders will reduce this value (at the time the order is placed) and Sell orders will increase this value (at the time the order settles).'
        }
    ],
    [
        {
            text: 'Long Market Value - Long market value indicates the net value of positions held long in the account. For securities priced daily, such as mutual funds, Today\'s Profit/Loss is calculated using the prior business day\'s market closing price. For securities priced real-time, Today\'s Profit/Loss is calculated using the last available price.'
        }
    ],
    [
        {
            text: 'Short Market Value - Short market value indicates the net value of positions held short in the account. This figure is reduced by the value of any in the money covered options and does not include shares held as Cash or Equivalents or Margin positions. For securities priced daily, such as mutual funds, Today\'s Profit/Loss is calculated using the prior business day\'s market closing price. For securities priced real-time, Today\'s Profit/Loss is calculated using the last available price.'
        }
    ],
    [
        {
            text: 'Amount Invested (Summary and Account Sections) - Amount invested is the aggregate amount you paid to purchase the securities position(s), including any expenses associated with the transaction(s).'
        }
    ],
    [
        {
            text: 'Today\'s Profit/Loss - Today\'s Profit/Loss reflects the current market value (current price * number of shares) of settled securities minus the market value at the market open. This includes settled positions only and does not include any pending activity (debits/credits) within the selected accounts. The percentage change reflects a ratio of the today\'s profile/loss value in relation to the market value of the position(s) at the market\'s open. For securities priced daily, such as mutual funds, Today\'s Profit/Loss is calculated using the prior business day\'s market closing price. For securities priced real-time, Today\'s Profit/Loss is calculated using the last available price.'
        }
    ],
    [
        {
            text: 'Total Profit/Loss - The total profit/loss is calculated by subtracting the Total Cost from the current market value (current price * number of shares) of the position.'
        }
    ],
    [
        {
            text: 'Total Cost (Positions Section) - Total Cost is the aggregate amount you paid to purchase the position(s), including any expenses associated with the transaction(s).'
        }
    ],
    [
        {
            text: 'Unrealized Profit/Loss (Summary and Account Sections) - Unrealized Profit/Loss is the potential profit/loss you could experience by selling your investment. A profit or loss becomes realized when the investment is actually sold.'
        }
    ],
    [
        {
            text: 'Sectors and Movers - The TOP MOVERS/GAINERS/LOSERS and SECTORS lists are generated using data sourced from third-party data providers. A list of companies in a specific business sector may display a company if the source data indicates that the company derives a portion of its revenues from the relevant sector. The list is provided for informational purposes only and is not investment advice or a recommendation to buy, hold, or sell any security. Lists are not personalized recommendations to you or to any other customer, and the securities listed may not be suitable for you. You should not buy or sell any security without first determining it is appropriate for your portfolio or investment strategy. StoneX does not make any warranty or guarantee relating to the accuracy, timeliness or completeness of any third-party information, and the provision of this information does not constitute a recommendation.'
        }
    ],
    [
        {
            text: 'Watchlist - A watchlist is a list of investments you want to track. If the current market price is unavailable or the market is closed, the most recent closing market price will be displayed. For securities priced daily, such as mutual funds, the prior business day\'s market closing price is displayed.'
        }
    ],
    [
        {
            text: 'Implied Volatility (applicable for options trading section only) - Implied volatility represents the expected volatility of a stock over the life of the option. As expectations change, option premiums react appropriately. Implied volatility is directly influenced by the supply and demand of the underlying options and by the market\'s expectation of the share price\'s direction.'
        }
    ],
    [
        {
            text: 'Crypto Trading and Account Information',
            weight:'bold',
        }
    ],
    [
        {
            text: 'All cryptocurrency trading activities facilitated through StoneX One Pro are conducted via ETANA, a trusted third-party service provider. Please be aware that when you engage in cryptocurrency trading, you are interacting directly with ETANA, and all trades are executed on their systems.'
        }
    ],
    [
        {
            text: 'Furthermore, your cryptocurrency accounts are opened and maintained at ETANA. This means that ETANA is responsible for holding, securing, and managing your crypto assets. StoneX One Pro serves as a conduit to ETANA’s services, providing you with access to their trading environment.'
        }
    ],
    [
        {
            text: 'Please note the following key points:'
        }
    ],
    [
        {
            text: '1. '
        },
        {
            text: 'Execution of Trades: ',
            weight: 'bold'
        },
        {
            text: 'All cryptocurrency trades initiated on StoneX One Pro are executed by ETANA. We do not execute any trades on our systems.'
        }
    ],
    [
        {
            text: '2. '
        },
        {
            text: 'Account Management: ',
            weight: 'bold'
        },
        {
            text: 'Your cryptocurrency accounts, including all deposits and withdrawals, are managed by ETANA.'
        }
    ],
    [
        {
            text: '3. '
        },
        {
            text: 'Security and Custody: ',
            weight: 'bold'
        },
        {
            text: 'ETANA is responsible for the security and custody of your cryptocurrency assets. StoneX Financial does not hold or secure any cryptocurrency assets directly.'
        }
    ],
    [
        {
            text: '4. '
        },
        {
            text: 'Fees and Charges: ',
            weight: 'bold'
        },
        {
            text: 'Any fees or charges related to cryptocurrency trading will be determined and collected by ETANA. Please refer to ETANA’s fee schedule for detailed information.'
        }
    ],
    [
        {
            text: '5. '
        },
        {
            text: 'Regulatory Oversight: ',
            weight: 'bold'
        },
        {
            text: 'ETANA operates under its own regulatory framework. It is essential to review ETANA’s terms of service, privacy policy, and any regulatory disclosures they provide.'
        }
    ],
    [
        {
            text: 'By using StoneX One Pro for cryptocurrency trading, you acknowledge and agree to these terms. It is important to conduct your own due diligence and understand the risks involved in cryptocurrency trading. For any issues or questions related to your crypto accounts or trades, please contact ETANA directly.'
        }
    ],
    [
        {
            text: 'Disclaimer: ',
            weight: 'bold'
        },
        {
            text:'Cryptocurrency trading involves significant risk and may result in the loss of your invested capital. Please trade responsibly.'
        }
    ],
    [
        {
            text: 'Market Intelligence Disclaimer',
            weight: 'bold',
            size: 'large'
        }
    ],
    [
        {
            text: '© StoneX Group Inc. All intellectual property rights reserved in this report and its contents.'
        }
    ],
    [
        {
            text: 'Nothing contained herein shall be construed as conferring on Subscriber or any third party any unlimited license or right, by implication, estoppel or otherwise, under any law (whether common law or statutory law), rule or regulation including, without limitation those related to copyright or other intellectual property rights.'
        }
    ],
    [
        {
            text: 'This report and its contents shall not be deemed to be financial advice. This report and its contents therein is made available to Subscriber for informational purposes and are not a substitute for obtaining professional advice from a qualified person, firm, or corporation, where required. The accuracy, completeness, adequacy or timeliness of this report and its contents is not warranted or guaranteed. Subscriber acknowledges that the report and its contents may be work product utilising, incorporating or referring to information from third parties.'
        }
    ],
    [
        {
            text: 'Any unauthorized use of this report and its content is wholly prohibited. This report and its contents is for use by Subscriber only in accordance with its subscription and it may not copy, modify, reproduce, republish, distribute, display, or transmit for commercial, non-profit or public purposes all or any portion thereof, and any other purpose for its benefit or the benefit of any third party. For the avoidance of doubt Subscriber may not use this report and any of its contents therein to transmit any false, misleading, fraudulent or illegal communications.'
        }
    ],
    [
        {
            text: 'StoneX Group Inc. disclaims all liability from the reliance, use of this report and its contents for any purpose howsoever arising from the subscription, Subscriber\'s use and any third party.'
        }
    ]
]

