// @ts-strict-ignore
import { ItemUpdate } from 'lightstreamer-client-web';
import { GetAllOpenOrdersAction } from 'phoenix/redux/actions/OrdersActions';
import { MessageStore_IncrementUnread } from 'phoenix/stores/MessagesStore';
import { Dispatch } from 'redux';
import { LiveDataGroups, Urls } from '../../constants';
import { LiveDataNamespaces } from '../../constants/LiveDataNamespaces';
import { GetJwtClaim, Jwt, Lightstreamer, SymbolUrlChunk } from '../../util';
import { Order } from '../models';
import { ClientMdcEventName } from '../models/Messages/ClientMessageDeliveryControls';
import { SnexMessage } from '../models/Messages/SnexMessage';
import { SnexOrderUpdateMessagePayload } from '../models/Messages/SnexOrderUpdateMessagePayload';
import { Actions } from './Constants';
import { ReduxApiDelete, ReduxApiGet, ReduxApiPost, ReduxApiPut } from './Helpers';
import { ReduxPollingStart, ReduxPollingStop } from './PollingHelpers';
import { ReduxLiveSubscribe } from './StreamingHelpers';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { QualifiedId } from 'phoenix/util/QualifiedId';

export const SearchMessagesAction = (
    filters?: Partial<{
        page: number;
        size: number;
    }>,
    append = false
) =>
    ReduxApiGet(Urls.messages.search(filters), Actions.Messages.Search)
        .onSuccess((res: SnexMessage[], dispatch) => {
            // @ts-ignore
            dispatch(CountUnreadAction());
            return res;
        })
        .showToasts()
        .withLoading() // <-- Please don't remove, needed by mobile app
        .withPassthrough({ passthrough: { append } })
        .run();

export const GetMessageAction = (messageId: string) =>
    ReduxApiGet(Urls.messages.getOne(messageId), Actions.Messages.GetOne)
        .useStoredExotic((s) => s.messages.all.data?.find((m) => m.id === messageId))
        .withLoading()
        .showToasts()
        .withSubject(messageId)
        .run();

export const MarkAsReadAction = (messageId: string) =>
    ReduxApiPut(messageId, Actions.Messages.MarkRead)
        .withSubject(messageId)
        .withBatching('mark-read', (ids) => SymbolUrlChunk(ids).map((chunk) => Urls.messages.markRead(chunk)), 500)
        .run();

export const CountUnreadAction = () => ReduxApiGet(Urls.messages.countUnread(), Actions.Messages.CountUnread).run();
export const StartPollingUnreadCountAction = () => ReduxPollingStart('unread-msg', CountUnreadAction, 60);
export const StopPollingUnreadCountAction = () => ReduxPollingStop('unread-msg');

export const GetUnreadMessageStatsAction = (since?: Date) => ReduxApiGet(Urls.messages.countStats(since), Actions.Messages.GetUnreadStats).withoutOutput().run();

export const GenerateMessagesAction = () => ReduxApiPost(Urls.messages.generate(), Actions.Messages.Generate).withMutex().run();
export const GeneratePushMessageAction = () => ReduxApiPost(Urls.messages.generate(), Actions.Messages.GeneratePush).withMutex().run();

export const BroadcastMessageAction = (message: { title: string; message: string }) =>
    ReduxApiPost(Urls.messages.broadcast(), Actions.Messages.Broadcast, message).showToasts().run();

export type LiveMessageOptions = Partial<{
    fetchOpenOrdersOnRebook: boolean;
    fetchPositionsOnOrderUpdates: boolean;
}>;

export const SubscribeToLiveMessages = (userSub?: string, options?: LiveMessageOptions) => {
    const opts = {
        fetchPositionsOnOrderUpdates: true, // for back compat
        ...options
    };
    const subEffective = userSub || GetJwtClaim(Lightstreamer.GetCurrentUserId(), 'sub');
    return ReduxLiveSubscribe(
        LiveDataGroups.Messages,
        [`msg:${subEffective}`],
        Actions.Messages.LiveStream,
        [
            'id',
            'recipVulcanId',
            'senderId',
            'senderName',
            'title',
            'message',
            'subjectSymbol',
            'subjectUserId',
            'time',
            'type',
            'payloadType',
            'disposition',
            'appPermalink',
            'externalLink',
            'imageUrl',
            'readDate',
            'data'
        ],
        (item: ItemUpdate, dispatch: Dispatch) => {
            try {
                const message = SnexMessage.FromLiveData(item);

                MessageStore_IncrementUnread();

                if (message.data?.type === 'OrderUpdate' && opts.fetchPositionsOnOrderUpdates) {
                    const order = message.data as SnexOrderUpdateMessagePayload & Order;
                    if (order?.orderStatus?.toLowerCase()?.includes('fill')) {
                        // only refresh positions on fill update..
                        const isEquity = QualifiedId.Family(order.accountNumber) === 'equities'
                        // @ts-ignore
                        if (isEquity)
                            setTimeout(() => usePositionsStore.getState().load(), 10000); // Equities take a while to recognize position changes, so a delay is needed
                        else {
                            usePositionsStore.getState().load(); // Other assets do not have this problem
                        }
                    }
                }

                return message;
            } catch (e) {
                console.log('MESSAGE UPDATE ERROR', e);
            }
        },
        {
            mode: 'DISTINCT',
            includeSnapshot: false,
            maxFrequencySeconds: 1,
            namespace: LiveDataNamespaces.Messages,
            allEvents: true
        }
    );
};

export const SubscribeToLiveMessagesWithJwt = (jwt?: string, options?: LiveMessageOptions) => {
    // Okta tokens need to use `cdsid:<CDSID>`; Vulcan tokens just use the token's subject.
    const iss = Jwt.GetIssuer(jwt);
    const sub = Jwt.TryGetSubject(jwt);
    const cds = Jwt.TryGetClaim(jwt, 'cds_id');
    const userId = iss === 'okta' ? `cdsid:${cds}` : sub;
    return SubscribeToLiveMessages(userId, options);
};

export const DeleteMessagesAction = (ids: string[]) =>
    ReduxApiDelete<unknown, unknown, string[]>(ids.join(','), Actions.Messages.Delete)
        .withBatching('msg:delete', (idLists) => Urls.messages.delete(idLists.flatMap((l) => l.split(','))), 100)
        .withSubject(ids)
        .showToasts()
        .run();

export const DeleteAllMessagesAction = () => ReduxApiDelete(Urls.messages.deleteAll(), Actions.Messages.DeleteAll).run();

export const EmitToastMessageAction = (title?: string, message?: string) => ({ type: Actions.Messages.emitToast, data: { title, message } });

export const EmitMessageAction = (
    title?: string,
    message?: string,
    titleAccessibility?: { accessibilityLabel: string },
    messageAccessibility?: { accessibilityLabel: string }
) => ({ type: Actions.Messages.EmitMessage, data: { title, message, titleAccessibility, messageAccessibility } });

export const GetClientMessageDeliveryControlsAction = () =>
    ReduxApiGet(Urls.messages.clientControls(), Actions.Messages.GetClientControls).withLoading().withMutex().run();

export const UpdateClientMessageDeliveryControlsAction = (messageType: string, event: ClientMdcEventName, onOff: 'on' | 'off') =>
    ReduxApiPut(Urls.messages.clientControlsUpdate(messageType, event, onOff), Actions.Messages.UpdateClientControls).showToasts().withMutex().run();

/**
 * @deprecated Please use `getSystemMessageControls` from `MessagesStore` instead.
 */
export const GetSystemMessageDeliveryControlsAction = () =>
    ReduxApiGet(Urls.messages.systemControls(), Actions.Messages.GetSystemControls)
        .withMutex()
        .useStored((s) => s.messages.systemControls.data, 'msg-system-controls', 60)
        .run();

export const GetAdminMessageDeliveryControlsAction = () => ReduxApiGet(Urls.messages.adminControls(), Actions.Messages.GetAdminControls).withLoading().run();
