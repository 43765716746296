// @ts-strict-ignore
import { Chip, Skeleton, Stack, Typography } from '@mui/material';
import { Flex } from 'components';
import { MediaPost } from 'components/OneProNetwork/components/deskAlerts/deskAlerts';
import { InFrameVideoPlayer } from 'components/OneProNetwork/components/inFrameVideoPlayer/inFrameVideoPlayer';
import { useColors } from 'hooks/UseColors';
import { useAsyncEffect } from 'phoenix/hooks/UseAsyncEffect';
import { useOneProNetworkStore } from 'phoenix/stores/OneProNetworkStore/OneProNetworkStore';
import { Author, Post } from 'phoenix/stores/OneProNetworkStore/OneProNetworkTypes';
import { FormatNumber } from 'phoenix/util';
import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { LongFormIcon } from 'components/OneProNetwork/assets/longFormIcon';
import { AuthorAvatar } from 'components/OneProNetwork/components/avatar/AuthorAvatar';
import style from './style.module.scss';
import { Urls } from 'phoenix/constants';
import { NewsContent } from 'phoenix/redux/models/News/NewsContent';
import { SnexAxios } from 'phoenix/stores/AxiosHelpers';
import { renderHtml } from 'components/OneProNetwork/components/postsSection/postsSection';
import { BaseScreenLayout } from 'components/Layouts/BaseScreenLayout';

type OneProAuthorScreenProps = {
    authorId: string;
};

const BioDetail = ({ title, value, format }: { title: string; value: string; format?: string }): JSX.Element => {
    const getFormattedValue = () => {
        switch (format) {
            case 'number':
                return FormatNumber.toCommas(parseFloat(value));
            case 'currency':
                return FormatNumber.toMoneyOpt2(parseFloat(value));
            case 'date':
                return moment(value).format('MM/DD/YYYY');
            case 'string':
            default:
                return value;
        }
    };

    return (
        <Flex row fullWidth style={{ gap: 8, fontSize: 12 }}>
            <div style={{ opacity: 0.75 }}>{title}:</div>
            <div>{getFormattedValue()}</div>
        </Flex>
    );
};

const AuthorContent = ({ posts, isLoading }: { posts?: Post[]; isLoading?: boolean }): JSX.Element => {
    const colors = useColors();

    const ref = useRef<HTMLDivElement>(null);

    const handleOpen = async (post: Post) => {
        console.log({mediaType: post?.media?.type})
        switch (post?.media?.type) {
            case 'html': {
                    const c = await SnexAxios.ApiGet<NewsContent>(Urls.news.articleContent(post?.media?.content[0])).run()
                    if (c.contentType === 'Url') await window.open(c.content, '_blank')
                    else renderHtml(c.content)
                    break;
                }
            default:
                window.open(post?.media?.content[0], '_blank', 'noopener,noreferrer');
                break;
        }
    };

    return (
        <div style={{ width: '100%' }}>
            {isLoading ? (
                <>
                    {[1, 2, 3].map((x, idx) => (
                        <Flex
                            key={`skeleton_${idx}`}
                            column
                            fullWidth
                            style={{ paddingBottom: 24, marginBottom: 24, borderBottom: `1px solid ${colors.inputBorderColor}` }}
                        >
                            <Flex row fullWidth style={{ gap: 12 }}>
                                <Skeleton variant={'circular'} width={48} height={48} />
                                <Flex column style={{ gap: 6, flex: 1 }}>
                                    <Skeleton variant={'rectangular'} width={175} height={16} />
                                    <Skeleton variant={'rectangular'} width={'100%'} height={16} />
                                    <Skeleton variant={'rectangular'} width={'100%'} height={16} />
                                    <Skeleton variant={'rectangular'} width={'100%'} height={16} />
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                </>
            ) : (
                posts?.map((p) => (
                    <Flex ref={ref} column key={`post-${p.id}`} style={{ width: '100%', paddingBottom: 24, borderBottom: `1px solid ${colors.inputBorderColor}` }}>
                        <MediaPost type={'default'} post={p} hideTags />
                        {p?.media?.type === 'video' && <InFrameVideoPlayer containerRef={ref} loading={isLoading} post={p} />}
                        {p?.media?.type !== 'video' && (
                            <Flex
                                row
                                align={p.thumbnailUri ? 'flex-end' : 'flex-start'}
                                className={style['feed-video-container']}
                                fullWidth
                                onClick={() => handleOpen(p)}
                                style={{
                                    backgroundImage: `url(${p.thumbnailUri})`,
                                    backgroundColor: colors.cardBackgroundColor,
                                    height: p.thumbnailUri ? 100 : 'auto'
                                }}
                            >
                                <div className={style['feed-video-shaded-overlay']} style={{ alignItems: p.thumbnailUri ? 'flex-end' : 'flex-start' }}>
                                    <Flex row align={'center'} style={{ gap: 12 }}>
                                        <LongFormIcon width={24} />
                                        <Typography style={{ fontSize: 16 }}>{p?.title}</Typography>
                                    </Flex>
                                </div>
                            </Flex>
                        )}
                        <Flex row fullWidth style={{ gap: 8 }}>
                            {p?.tags?.length && (
                                <Stack direction={'row'} spacing={1}>
                                    {p?.tags?.map((t, tidx) => (
                                        <Chip key={tidx} style={{ fontSize: 10, height: 16, borderRadius: 8 }} label={t} />
                                    ))}
                                </Stack>
                            )}
                        </Flex>
                    </Flex>
                ))
            )}
        </div>
    );
};

export const OneProAuthorScreen = (): JSX.Element => {
    const { authorId } = useParams<OneProAuthorScreenProps>();
    const { highLevel, mainScreen, findAuthorById, findAuthorsFeed, findAuthorsFeaturedPosts } = useOneProNetworkStore();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [author, setAuthor] = useState<Author>(null);
    const [content, setContent] = useState<Post[]>([]);

    const colors = useColors();

    useAsyncEffect(async () => {
        setIsLoading(true);
        await highLevel.load();
        await mainScreen.load();
        const _author = findAuthorById(authorId);
        const _posts = findAuthorsFeaturedPosts(authorId);
        const _feed = findAuthorsFeed(authorId);

        const _content = _posts.concat(_feed).sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

        setAuthor(_author);
        setContent(_content);
        setIsLoading(false);
    }, []);

    const sidebarRef = useRef<HTMLDivElement>(null);
    const authorImgSize = sidebarRef?.current?.clientWidth ? Math.round(sidebarRef?.current?.clientWidth * 0.3) : 0;

    const avatarContent = useMemo(
        () =>
            isLoading ? (
                <Skeleton variant={'rectangular'} width={authorImgSize} height={authorImgSize} style={{ borderRadius: 17 }} />
            ) : (
                <AuthorAvatar size={authorImgSize} borderRadius={17} url={author?.imageUri} borderColor={colors.selectListSelectedItemColor} />
            ),
        [author, authorImgSize, isLoading, colors.selectListSelectedItemColor]
    );

    const authorName = useMemo(
        () =>
            isLoading ? (
                <Skeleton variant={'rectangular'} width={'100%'} height={18} style={{ borderRadius: 7 }} />
            ) : (
                <div>
                    <Typography style={{ fontSize: 18, fontWeight: 'bold' }}>{author?.name}</Typography>
                </div>
            ),
        [author, isLoading]
    );

    const authorTitle = useMemo(
        () =>
            isLoading ? (
                <Skeleton variant={'rectangular'} width={'100%'} height={14} style={{ borderRadius: 7 }} />
            ) : (
                <div>
                    <Typography style={{ fontSize: 14, opacity: 0.65 }}>{author?.title}</Typography>
                </div>
            ),
        [author, isLoading]
    );

    const authorDetails = useMemo(
        () =>
            isLoading ? (
                <Flex column fullWidth style={{ gap: 4, marginTop: 12 }}>
                    <Skeleton variant={'rectangular'} width={'60%'} height={12} style={{ borderRadius: 7 }} />
                    <Skeleton variant={'rectangular'} width={'80%'} height={12} style={{ borderRadius: 7 }} />
                </Flex>
            ) : (
                <Flex column fullWidth style={{ gap: 4, marginTop: 12 }}>
                    {author?.details?.map((d, idx) => (
                        <BioDetail key={`detail_${authorId}_${idx}`} title={d.title} value={d.value} format={d.format} />
                    ))}
                </Flex>
            ),
        [isLoading, authorId, author]
    );

    const bioText = useMemo(
        () =>
            isLoading ? (
                <>
                    <Skeleton variant={'rectangular'} width={'100%'} height={18} style={{ borderRadius: 7 }} />
                    <Skeleton variant={'rectangular'} width={'100%'} height={18} style={{ borderRadius: 7 }} />
                    <Skeleton variant={'rectangular'} width={'100%'} height={18} style={{ borderRadius: 7 }} />
                    <Skeleton variant={'rectangular'} width={'100%'} height={18} style={{ borderRadius: 7 }} />
                    <Skeleton variant={'rectangular'} width={'100%'} height={18} style={{ borderRadius: 7 }} />
                </>
            ) : (
                <p style={{ lineHeight: '1.25rem' }}>{author?.bio}</p>
            ),
        [isLoading, author]
    );

    return (
        <BaseScreenLayout >
            <Flex row fullWidth style={{ gap: 24, height: '80vh' }}>
                <Flex column style={{ width: '60%', height: '100%', overflow: 'scroll', maxWidth: 600 }}>
                    <AuthorContent posts={content} isLoading={isLoading} />
                </Flex>
                <Flex ref={sidebarRef} column style={{ width: '40%', gap: 12 }}>
                    <div style={{ borderRadius: 17, backgroundColor: colors.cardBackgroundColor, padding: 16, display: 'flex', flexDirection: 'column', gap: 16 }}>
                        <Flex row fullWidth align={'center'} style={{ gap: 12 }}>
                            {avatarContent}
                            <Flex column style={{ flex: 1, gap: 6 }}>
                                {authorName}
                                {authorTitle}
                                {authorDetails}
                            </Flex>
                        </Flex>
                        {bioText}
                    </div>
                </Flex>
            </Flex>
        </BaseScreenLayout>
    );
};
