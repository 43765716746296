// @ts-strict-ignore
import { Flex } from "components"
import React, { useCallback, useMemo } from "react"
import { OnboardingLottieForm, OnboardingPageTitle } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFuturesOnboardingStore } from "../../Store"
import animationData from 'phoenix/assets/animations/ProcessingCard.json'
import { Chat } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { SnexButton } from "components/SnexButton"
import { useOpenChatWindow } from "hooks/UseChat"
import { Urls, ResolveFrontend } from "phoenix/constants"
import { useText } from "phoenix/hooks/UseText"
import useWindow from "hooks/UseWindow"
import Lottie from "lottie-react"
import { LogFuturesOnboardingFailed, LogFuturesOnboardingSuccess } from "../../Base"
import { SnexAxios } from "phoenix/stores/AxiosHelpers"
import { useAsyncEffect } from "phoenix/hooks/UseAsyncEffect"
import { ErrorLogger } from "phoenix/util/ErrorLogger"

export const FuturesOnboarding_Submitting_Form = () => {

    const {setValue, clearData, submit, submitResult, data} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding)
    const chatText = useText(s => s.misc.chatWithSupport);
    const openChatWindow = useOpenChatWindow()
    const {viewportWidth} = useWindow()
    
    const handleChatClick = useCallback((e: any) => {
        if(e) e.preventDefault();
        openChatWindow()
    }, [])

    const tryAgain = () => {
        setValue('submitting', false)
        setValue('selectedScreenId', 'certification')
    }

    const completeOnboarding = () => {
        SnexAxios.ApiPut(Urls.users.updateHasOnboarded(true)).run() // added to fix delay from onboarding
        
        setValue('submitting', false)
        clearData()
        window.location.href = ResolveFrontend() // hard refresh used to trigger account loading
    }
    
    useAsyncEffect(async () => {
        try
        {
            const result = await submit();
            if(result?.isSuccess === true) {
                LogFuturesOnboardingSuccess({accountId: result?.onboardingResponse?.accountId, applicationId: result?.onboardingResponse?.accountId})
            } else {
                LogFuturesOnboardingFailed({name: 'futuresOnboardingError', message: result?.onboardingResponse?.errorMessage})
            }
        }
        catch(err)
        {
            ErrorLogger.RecordError(err, 'futuresOnboardingError', {info: {...data}})
        }
    }, [])

    const errorControls = useMemo(() => 
        <>
            <SnexButton flavor={'secondary'} onClick={handleChatClick} style={{margin: '1rem 0'}}>
                <Chat />
                <span style={{ marginLeft: '32px' }}>{ chatText }</span>
            </SnexButton>
            <SnexButton color='primary' onClick={() => tryAgain()}>{'Go Back'}</SnexButton>
        </>
    , [])

    const loadingControls = useMemo(() => 
        <>
            <SnexButton flavor={'secondary'} onClick={handleChatClick} style={{margin: '1rem 0'}}>
                <Chat />
                <span style={{ marginLeft: '32px' }}>{ chatText }</span>
            </SnexButton>
            <SnexButton color='primary'  disabled={true} style={{margin: '2rem 0'}} >{text.okButton}</SnexButton>
        </>
    , [])

    const successControls = useMemo(() => 
        <>
            <SnexButton flavor={'secondary'} onClick={handleChatClick} style={{margin: '1rem 0'}}>
                <Chat />
                <span style={{ marginLeft: '32px' }}>{ chatText }</span>
            </SnexButton>
            <SnexButton color='primary' onClick={completeOnboarding}>{text.okButton}</SnexButton>
        </>
    , [])

    const statusMessage = useMemo(() => {
        if(submitResult === 'pristine' || submitResult ==='loading') return <Typography variant='h4' >{text.submitMessage}</Typography>
        if(submitResult?.isSuccess === true) return <Typography variant='h4'>{text.accountDelayMessage}</Typography>
        return <Typography variant='h4'>{text.tryAgain}</Typography>
    }, [submitResult])

    const statusTitle = useMemo(() => {
        if(submitResult === 'pristine' || submitResult ==='loading') return text.submitMessageTitle
        if(submitResult?.isSuccess === true) return text.successMessage(submitResult?.onboardingResponse?.accountId) 
        return submitResult?.onboardingResponse?.errorMessage || text.errorMessage
    }, [submitResult])

    const statusControls = useMemo(() => {
        if(submitResult === 'pristine' || submitResult ==='loading') return loadingControls
        if(submitResult?.isSuccess === true) return successControls
        return errorControls
    }, [submitResult])

    const lottie = () => <Lottie                  
                            loop={true}
                            autoplay={true}
                            animationData={animationData}
                            rendererSettings = {{
                                preserveAspectRatio: 'xMidYMid slice'
                            }}                    
                            width={'100%'}
                            height={'auto'}
                            style={{
                                opacity: 1,
                                transition: '1s'
                            }}
                        />

    return (
        <Flex column fullWidth style={{gap: '1rem'}}>
            <OnboardingLottieForm data={animationData} loop={submitResult === 'pristine' || submitResult ==='loading'}>
                <>
                <OnboardingPageTitle title={statusTitle}/>
                {statusMessage}
                {viewportWidth < 1100? lottie(): null}
                {statusControls}
                </>
            </OnboardingLottieForm>
        </Flex>
    )
}