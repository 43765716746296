import { TextField } from '@mui/material';
import { Flex, OpenOrders } from 'components';
import { OrderList } from 'components/OpenOrders/OrderList';
import { SnexButton } from 'components/SnexButton';
import { OrderSearchFiltersV3 } from 'phoenix/redux/actions';
import { SendMockOrderUpdateMessage } from 'phoenix/stores/messageHelpers';
import { useOrdersStore } from 'phoenix/stores/OrdersStore';
import React, { ReactElement } from 'react';

export const ZanderDebugScreen = (): ReactElement => {
    const { searchResults: orders, searchOrders } = useOrdersStore();
    const [orderId, setOrderId] = React.useState<string>('TestOrderUpdate');
    const [symbol, setSymbol] = React.useState<string>('TESTV');
    const orderFilter: OrderSearchFiltersV3 = {
        take: 20
    };

    return (
        <Flex column fullWidth style={{ wordBreak: 'break-all', padding: '16px 64px', marginTop: '16px', gap: '1rem', cursor: 'pointer', position: 'relative' }}>
            <SnexButton onClick={async () => await searchOrders(orderFilter)}>Search Orders</SnexButton>
            <SnexButton onClick={async () => await SendMockOrderUpdateMessage('New', orderId, symbol)}>Send New Test Order</SnexButton>
            <SnexButton onClick={async () => await SendMockOrderUpdateMessage('Partial Fill', orderId, symbol)}>Send Update Test Partial Fill</SnexButton>
            <SnexButton onClick={async () => await SendMockOrderUpdateMessage('Filled', orderId, symbol)}>Send Update Test Filled</SnexButton>
            <SnexButton onClick={async () => await SendMockOrderUpdateMessage('Canceled', orderId, symbol)}>Send Update Test Canceled</SnexButton>
            <Flex row fullWidth style={{ gap: 12 }}>
                <TextField sx={{ width: '100%' }} onChange={(a) => setOrderId(a.target.value)} value={orderId} label='OrderId' />
                <TextField sx={{ width: '100%' }} onChange={(a) => setSymbol(a.target.value)} value={symbol} label='Symbol' />
            </Flex>

            <OrderList orders={orders} />
        </Flex>
    );
};
