// @ts-strict-ignore
import { Flex } from "components"
import { OnboardingInputRow } from "components/Onboarding"
import { useText } from "phoenix/hooks/UseText"
import React, { useEffect, useState } from "react"
import { OnboardingLottieForm, OnboardingDropdown, OnboardingYesNo } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFuturesOnboardingStore } from "../../Store"
import { getTradingExperienceErrors } from "./Validation"
import animationData from 'phoenix/assets/animations/Stocks.json'
import { useColors } from "hooks/UseColors"


export const FuturesOnboarding_TradingExperience_Form = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding.tradingExperience)
    const [enableProQuestion, setEnableProQuestion] = useState(false)
    const handleChange = (key: string, value: string) => {
        setValue(key, value)
    }
    const getErrors = (key: string) => {
        const errors = getTradingExperienceErrors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
        if(!enableProQuestion) errors.push({key: 'data.tradingExperience.professionalTrader', error: 'Must view Futures Market Data Subscription Terms'})
        return errors;
    }

    const handlePdfClick = () => {
        setEnableProQuestion(true)
        window.open(text.professionalTraderUrl, '_blank');
    }

    useEffect(() => {
        if(data?.tradingExperience?.professionalTrader !== undefined && data?.tradingExperience?.professionalTrader !== null) {
            console.log({pro: data?.tradingExperience?.professionalTrader})
            setEnableProQuestion(true)
        }
    }, [data?.tradingExperience?.professionalTrader])

    const colors = useColors()

    return(
        <OnboardingLottieForm data={animationData} loop={false}>
             <Flex column center fullWidth style={{gap: '1rem'}}>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.tradingExperience.commoditiesYears' options={text.experienceYears} 
                        label={text.commoditiesQuestion} value={data.tradingExperience?.commoditiesYears} handleChange={handleChange} 
                        errors={getErrors('data.tradingExperience.commoditiesYears')}/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.tradingExperience.securitiesYears' options={text.experienceYears} 
                        label={text.securitiesQuestion} value={data.tradingExperience?.securitiesYears} handleChange={handleChange} 
                        errors={getErrors('data.tradingExperience.securitiesYears')}/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.tradingExperience.otcYears' options={text.experienceYears} 
                        label={text.otcQuestion} value={data.tradingExperience?.otcYears} handleChange={handleChange} 
                        errors={getErrors('data.tradingExperience.otcYears')}/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <Flex row style={{color: colors.selectListSelectedItemColor}} onClick={handlePdfClick}>{text.professionalTraderPdfLabel}</Flex>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                <OnboardingYesNo value={data.tradingExperience?.professionalTrader} 
                    disabled={!enableProQuestion}
                    question={text.professionalTrader}
                    buttonWidth={'auto'}
                    handleChange={(val) => setValue('data.tradingExperience.professionalTrader', val)} 
                    errors={ getErrors('data.tradingExperience.professionalTrader') }/>
                </OnboardingInputRow> 
             </Flex>
        </OnboardingLottieForm>
    )
}