// @ts-strict-ignore
import { useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { TradeTicketAccountDropdown } from 'components/AccountDropdown/TradeTicketAccountDropdown';
import { QuantityInputSplit } from 'components/InputSplit/QuantityInputSplit';
import { T } from 'phoenix/assets/lang/T';
import { TradeActions } from 'phoenix/constants/Trade';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { CryptosAssetClass } from 'phoenix/models/AssetClasses/CryptoAssetClass';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { toMoneyOpt2, UniqueBy } from 'phoenix/util';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex } from '../..';
import { TradeCancelHeader } from '../Headers/TradeCancelHeader';
import { TradeInputHeader } from '../Headers/TradeInputHeader';
import { getCryptoEstimatedLabel, getCryptoEstimatedValue, GetTradeTicketQuote } from '../Shared/helpers';
import { BuyingPowerDisplayV2, MarketPriceDisplay, PriceInputSplit, TotalCostDisplayPresentation } from '../Shared/TradeFormComponents';
import { TradeInputValidationButton } from '../Shared/TradeInputValidationButton';
import { TradeTicketSection } from '../Shared/TradeTicketSection';
import { CryptoTradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';

export const CryptoTradeInputPage = React.memo(() => {
    const { load: loadPositions } = usePositionsStore();
    const viewModel = useTradeTicketViewModel<CryptoTradeTicketViewModel>();
    const {
        awaitingConfirmation,
        displayOrderType,
        initialQuantity,
        initialLimitPrice,
        initialStopPrice,
        limitPrice,
        modifyingOrder,
        orderType,
        quantity,
        quantityQualifier,
        setViewModel,
        stopPrice,
        symbol,
        tradeAction
    } = viewModel;
    // Use quantity input key to force a remount of the quantity input component
    // such as when using "Sell All" button to override user input
    const [quantityInputKey, setQuantityInputKey] = useState<number>(0);
    const positions = usePositionsStore((s) => s.positions)?.filter((p) => p.symbol === symbol);
    const text = useText((t) => t);
    const logo = useSnexStore((s) => s.logos.bySymbol[symbol]);
    const quote = GetTradeTicketQuote(symbol);
    const generalText = useText((s) => s.general);
    const quantityLabel = quantityQualifier === 'EvenDollar' ? text.tradeTicket.input.dollars : generalText.coins(0);
    const selectedAccountNumber = useSelectedAccountByAssetFamily(CryptosAssetClass.family);
    const meta = useSecurityMetadataV2().getMetadataBySymbol(symbol);
    const { formatPrice, formatQuantity, getPriceFormatInfo, getQuantityFormatOptions, getQuantityForPrice, getPriceForQuantity } = CryptosAssetClass;
    const quantityFormatOptions = getQuantityFormatOptions();
    const priceFormatOptions = getPriceFormatInfo(meta);
    const formatOptions = quantityQualifier === 'Shares' ? quantityFormatOptions : null; // Dollar based qty will use default money config
    const totalCostLabel = getCryptoEstimatedLabel({
        quantityQualifier,
        symbol,
        text: text.tradeTicket.input,
        tradeAction
    });
    const totalCostValue = getCryptoEstimatedValue({
        limitPrice: limitPrice || initialLimitPrice,
        stopPrice: stopPrice || initialStopPrice,
        orderType: displayOrderType,
        quantity: quantity || initialQuantity,
        quantityQualifier,
        quote,
        tradeAction
    });

    const formatter =
        quantityQualifier === 'Shares' ? (number: number) => formatQuantity(number, meta) : (number: number) => toMoneyOpt2(number, { hideCurrencySymbol: true });

    const holdingAccounts = useMemo(() => {
        return tradeAction === 'Sell'
            ? new Set(
                  UniqueBy<string>(
                      positions?.map((p) => p.accountNumber),
                      (x) => x
                  )
              )
            : null;
    }, [tradeAction, positions]);

    useEffect(() => {
        loadPositions();
    }, [loadPositions]);

    const handleFieldUpdate = (name: keyof CryptoTradeTicketViewModel, value: CryptoTradeTicketViewModel[keyof CryptoTradeTicketViewModel]) => {
        const newValue = { [name]: value };

        if (name === 'quantity' && !value) {
            newValue.initialQuantity = '';
        }

        setViewModel(newValue);
    };

    const handleQuantityToggle = () => {
        const newQualifier = quantityQualifier === 'EvenDollar' ? 'Shares' : 'EvenDollar';
        const conversionFn = quantityQualifier === 'EvenDollar' ? getQuantityForPrice : getPriceForQuantity;
        const orderPrice = orderType === 'market' ? null : limitPrice || initialLimitPrice; // Market orders convert using the quote price
        const effectiveQuantity = quantity || initialQuantity;
        const newQuantity = conversionFn(effectiveQuantity, quote, { orderPrice });

        setViewModel({ quantityQualifier: newQualifier, ...(effectiveQuantity ? { initialQuantity: newQuantity, quantity: null } : {}) });
        setQuantityInputKey(quantityInputKey + 1); // Remount since potentially overriding previous user input
    };

    const handleAccountSelect = useCallback(() => setViewModel({ awaitingConfirmation: false }), [setViewModel]);

    return (
        <Flex column>
            {awaitingConfirmation ? (
                <TradeCancelHeader
                    logo={logo}
                    text={text.tradeTicket.input}
                    title={symbol}
                    onCancel={() => setViewModel({ awaitingConfirmation: false, validateResponse: null })}
                />
            ) : (
                <TradeInputHeader
                    customActions={!positions?.length ? [{ label: T((s) => s.tradeTicket.input.action.buy), value: TradeActions.Buy }] : undefined} // don't allow sell, if there are no positions
                />
            )}

            <TradeTicketSection noBorder style={{ paddingTop: 20, paddingBottom: 10 }}>
                <TradeTicketAccountDropdown
                    assetFamily={CryptosAssetClass.family}
                    balanceType={tradeAction.toLowerCase()}
                    defaultToFirst={'if-only-one'}
                    disabled={modifyingOrder}
                    isByAssetClass
                    numberWhitelist={holdingAccounts}
                    onSelect={handleAccountSelect}
                    skipInitialSelect={modifyingOrder || !!selectedAccountNumber}
                    style={{ marginBottom: 15 }}
                />
                <QuantityInputSplit
                    accountNumber={selectedAccountNumber}
                    arrows={true}
                    formatOptions={formatOptions}
                    formatter={formatter}
                    initialValue={initialQuantity}
                    key={quantityInputKey}
                    label={quantityLabel}
                    liquidateOnSellAll={false}
                    onBuySellAll={() => setQuantityInputKey(quantityInputKey + 1)}
                    onUnitChange={handleQuantityToggle}
                    onValueChange={(v) => handleFieldUpdate('quantity', v)}
                    showPosition={true}
                    showSellAll={true}
                    step={quantityQualifier === 'Shares' ? 1 : meta?.sizing?.minimumIncrementUsd || 0.01} // Used for shares only, 1 share per tick if shares, 1 cent per tick if dollars
                    symbol={symbol}
                    tradeAction={tradeAction}
                />
                {['stop', 'stoplimit'].includes(orderType) && (
                    <PriceInputSplit
                        formatOptions={priceFormatOptions}
                        formatter={(v: number) => formatPrice(v, meta)}
                        initialValue={initialStopPrice}
                        label={text.tradeTicket.input.stopPrice}
                        onValueChange={(v) => handleFieldUpdate('stopPrice', v)}
                    />
                )}
                {['limit', 'stoplimit'].includes(orderType) && (
                    <PriceInputSplit
                        formatOptions={priceFormatOptions}
                        formatter={(v: number) => formatPrice(v, meta)}
                        initialValue={initialLimitPrice}
                        label={orderType === 'limit' ? text.tradeTicket.input.limitPrice : text.tradeTicket.input.stopLimitPrice}
                        onValueChange={(v) => handleFieldUpdate('limitPrice', v)}
                        step={priceFormatOptions?.tickSize}
                    />
                )}
                <MarketPriceDisplay
                    ask={toMoneyOpt2(quote?.ask)}
                    bid={toMoneyOpt2(quote?.bid)}
                    loading={quote?.loading}
                    price={toMoneyOpt2(quote?.price)}
                    text={text.tradeTicket.input}
                />
            </TradeTicketSection>
            <TradeTicketSection style={{ paddingBottom: 20 }}>
                <TotalCostDisplayPresentation label={totalCostLabel} value={totalCostValue} />
                <TradeInputValidationButton />
            </TradeTicketSection>
            <TradeTicketSection noBorder>
                <BuyingPowerDisplayV2 accountNumber={selectedAccountNumber} symbol={symbol} />
            </TradeTicketSection>
        </Flex>
    );
});
