import React, { CSSProperties, useState } from 'react';
import { Collapse } from '@mui/material'
import { useTelemetry } from 'hooks/UseTelemetry';

interface CulledCollapseProps {
    in: boolean,
    eventTag?: string,
    children: any,
    style?: CSSProperties
}

const CulledCollapseFunction = (props: CulledCollapseProps) => {
    const LogEvent = useTelemetry();
    const [culled, setCulled] = useState(false)

    const handleToggle = (isCulled: boolean) => {
        const actionString = isCulled ? 'section collapsed' : 'section expanded'
        props.eventTag && LogEvent(`${props.eventTag} ${actionString}`)
        setCulled(isCulled);
    }

    return (
        <Collapse style={props.style} unmountOnExit in={props.in} onEnter={() => handleToggle(false)} onExited={() => handleToggle(true)}>
            {culled ? null : props.children}
        </Collapse>
    )

}

export const CulledCollapse = React.memo(CulledCollapseFunction)