// @ts-strict-ignore
import { Flex } from "components"
import { OnboardingInputRow } from "components/Onboarding"
import { useText } from "phoenix/hooks/UseText"
import React from "react"
import { OnboardingTextField, OnboardingYesNo } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFuturesOnboardingStore } from "../../Store"
import { getAffiliationErrors } from "./Validation"


export const FuturesOnboarding_Affiliations_Form = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding.affiliationsInfo)
    const handleChange = (key: string, value: string) => {
        setValue(key, value)
    }
    const getErrors = (key: string) => {
        return getAffiliationErrors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
    }
    
    return(
            <Flex column center fullWidth style={{gap: '1rem'}}>
            <OnboardingInputRow noPadding>
                <OnboardingYesNo value={data.affiliationsInfo?.politicalOrGovernment} 
                    question={text.politicalOrGovernment}
                    handleChange={(val) => setValue('data.affiliationsInfo.politicalOrGovernment', val)} 
                    errors={ getErrors('data.affiliationsInfo.politicalOrGovernment') }/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingYesNo value={data.affiliationsInfo?.employeeOfStoneX} 
                    question={text.employeeOfStoneX}
                    handleChange={(val) => setValue('data.affiliationsInfo.employeeOfStoneX', val)} 
                    errors={ getErrors('data.affiliationsInfo.employeeOfStoneX') }/>
            </OnboardingInputRow>
            {
                data?.affiliationsInfo?.employeeOfStoneX?
                    <OnboardingInputRow>
                         <OnboardingTextField name='data.affiliationsInfo.employeeName' label={text.employeeName}
                             value={data.affiliationsInfo?.employeeName} handleChange={handleChange} errors={getErrors('data.affiliationsInfo.employeeName')}/>
                         <OnboardingTextField name='data.affiliationsInfo.employeeRelationship' label={text.employeeRelationship}
                             value={data.affiliationsInfo?.employeeRelationship} handleChange={handleChange} errors={getErrors('data.affiliationsInfo.employeeRelationship')}/>
                    </OnboardingInputRow> : null
            }
            <OnboardingInputRow noPadding>
                <OnboardingYesNo value={data.affiliationsInfo?.regulatoryMember} 
                    question={text.regulatoryMember}
                    handleChange={(val) => setValue('data.affiliationsInfo.regulatoryMember', val)} 
                    errors={ getErrors('data.affiliationsInfo.regulatoryMember') }/>
            </OnboardingInputRow>
            {
                data?.affiliationsInfo?.regulatoryMember?
                    <OnboardingInputRow>
                         <OnboardingTextField name='data.affiliationsInfo.regulatoryBody' label={text.regulatoryBody}
                             value={data.affiliationsInfo?.regulatoryBody} handleChange={handleChange} errors={getErrors('data.affiliationsInfo.regulatoryBody')}/>
                         <OnboardingTextField name='data.affiliationsInfo.registrationNumber' label={text.registrationNumber}
                             value={data.affiliationsInfo?.registrationNumber} handleChange={handleChange} errors={getErrors('data.affiliationsInfo.registrationNumber')}/>
                    </OnboardingInputRow> : null
            }
            </Flex>
    )
}