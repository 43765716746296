// @ts-strict-ignore
import moment from "moment-timezone"
import { OnboardingInputError, ValidateRequired } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"

export const ssnRegex = /^(?!666|000|9\d{2})\d{3}(-)?(?!00)\d{2}(-)?(?!0{4})\d{4}$/;

export const PersonalInfoIsValid = () => getPersonalInfoErrors()?.length < 1
export const getPersonalInfoErrors = () => {
    const keys = ['data.personalInfo.dob', 'data.personalInfo.country', 'data.personalInfo.ssn', ]
    const store = useFuturesOnboardingStore.getState()
    const _errors = []  as OnboardingInputError[]
    keys.forEach(key => {
        switch(key) {
            case 'data.personalInfo.country':
                _errors.push(ValidateRequired(store, key))
                break
            case 'data.personalInfo.ssn':
                _errors.push(ValidateRequired(store, key))
                if(ssnRegex.test(store.data?.personalInfo?.ssn) !== true) _errors.push({key, error: 'Invalid SSN'});
                break
            case 'data.personalInfo.dob':
                _errors.push(ValidateRequired(store, key))
                if(!moment(store?.data?.personalInfo?.dob, ["MM/DD/YYYY"], true).isValid()) _errors.push({key, error: 'Invalid date of birth'});
                if(
                    store?.data?.personalInfo?.dob === "01/01/0001" ||
                    store?.data?.personalInfo?.dob?.split('/')[2] < "1920"
                    ) _errors.push({key, error: 'Invalid date of birth'});
                if(moment().diff(store?.data?.personalInfo?.dob, 'years') < 21 ) _errors.push({key, error: 'Must be at least 21 years of age'});
                break
    }})
    return _errors.filter(e => !!e);
}