// @ts-strict-ignore
import { Link, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import React from 'react';
import { Flex } from '..';
import { useTelemetry } from 'hooks/UseTelemetry';
import { Link as ReactLink } from 'react-router-dom';
import './ArrowLink.scss';

interface ArrowLinkProps {
    href?: string;
    onClick?: () => any;
    disabled?: boolean;
    eventTag?: string;
    children?: any;
    text?: string;
    style?: React.CSSProperties;
    className?: string;
    target?: string;
    icon?: JSX.Element;
    justify?: 'flex-start' | 'flex-end'
    noAnimate?: boolean;
    textStyle?: React.CSSProperties;
    iconYNudge?: number;
    route?: string;
}

export const ArrowLink = React.memo((props: ArrowLinkProps) => {
    const LogEvent = useTelemetry();
    return (
        <Link
            component={props.route ? ReactLink : undefined}
            className={`${props.className} arrow-link ${!props.noAnimate ? 'animate-arrow' : ''}`}
            href={props.href}
            style={{ ...props.style }}
            target={props.target}
            to={props.route}
            onClick={(e) => {
                if (props.eventTag) LogEvent(`${props.eventTag} arrow link button click`);
                if (props.onClick) props.onClick()
            }}
            underline='hover'>
            <Flex row align='center' justify={props.justify || 'flex-end'}>
                <Typography style={{ fontWeight: 600, marginRight: 10, ...props.textStyle }}>
                    {props.children || props.text}
                </Typography>
                <span className='arrow-link-arrow ' style={{ marginTop: 1 + (props.iconYNudge || 0) }}>
                    {props.icon || <ChevronRight style={{ fontSize: 14, ...props.textStyle }} />}
                </span>
            </Flex>
        </Link>
    );
});
