// @ts-strict-ignore
import { Flex } from "components"
import _ from "lodash"
import { T } from "phoenix/assets/lang/T"
import { useText } from "phoenix/hooks/UseText"
import React from "react"
import { OnboardingReviewItemProp, OnboardingReviewSection } from "screens/OnboardingScreens/Base/OnboardingControls"
import { getPersonalErrors } from "screens/OnboardingScreens/Registration/Screens/Personal/Validation"
import { useFuturesOnboardingStore } from "../../Store"
import { getAffiliationErrors } from "../Affiliations/Validation"
import { getContactInfoErrors } from "../ContactInfo/Validation"
import { getEmploymentInfoErrors } from "../EmploymentInfo/Validation"
import { getFinancialInfo1Errors, getFinancialInfo2Errors } from "../FinancialInfo/Validation"
import { getRegulatoryInfoErrors } from "../RegulatoryInfo/Validation"
import { getTradingExperienceErrors } from "../TradingExperience/Validation"

export const FuturesOnboarding_Review_Form = () => {
    const {data} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding)

    const getItems = (props: OnboardingReviewItemProp) => {
        const _keys = _.keys(props.data)
        return _keys.map(k => ({ key: k, value: props.data[k]?.toString(), screenId: props.screenId, isSecret: (props.secrets.map(s=> s.toLowerCase()) || []).includes(k.toLowerCase())})) || []
    }


    return (
        <Flex column fullWidth style={{gap: '2rem'}}>
            <OnboardingReviewSection 
                title={'Contact Info'}  
                goto={'contactInfo'}
                errors={getContactInfoErrors()}
                items={getItems({data: data.contactInfo, screenId: 'contactInfo', secrets: ['email', 'phone']})}
                /> 
            <OnboardingReviewSection 
                title={'Personal Info'}  
                goto={'personalInfo'}
                errors={getPersonalErrors()}
                items={getItems({data: data.personalInfo, screenId: 'personalInfo', secrets: ['dob', 'ssn']})}
                /> 
            <OnboardingReviewSection 
                title={'Financial Info'}  
                goto={'financialInfo'}
                errors={getPersonalErrors()}
                items={[
                    {
                        key: 'sourceOfFunds', 
                        value: (text.accountInfo?.sourceOfFundsOptions|| []).find(o => o.value === data?.accountInfo?.sourceOfFunds)?.label,
                        screenId: 'financialInfo1',
                        isSecret: true
                    },
                    {
                        key: 'intendedInitialDeposit', 
                        value: (text.accountInfo?.intendedInitialDepositOptions|| []).find(o => o.value === data?.accountInfo?.intendedInitialDeposit)?.label,
                        screenId: 'financialInfo1',
                        isSecret: true
                    },
                    {
                        key: 'expectedMonthlyTrading', 
                        value: (text.accountInfo?.expectedMonthlyTradingOptions|| []).find(o => o.value === data?.accountInfo?.expectedMonthlyTrading)?.label,
                        screenId: 'financialInfo1',
                        isSecret: true
                    },
                    {
                        key: 'annualRevenue', 
                        value: (text.financialInfo?.annualRevenueOptions|| []).find(o => o.value === data?.financialInfo?.annualRevenue)?.label,
                        screenId: 'financialInfo2',
                        isSecret: true
                    },
                    {
                        key: 'netWorth', 
                        value: (text.financialInfo?.netWorthOptions|| []).find(o => o.value === data?.financialInfo?.netWorth)?.label,
                        screenId: 'financialInfo2',
                        isSecret: true
                    },
                    {
                        key: 'workingCapital', 
                        value: (text.financialInfo?.workingCapitalOptions|| []).find(o => o.value === data?.financialInfo?.workingCapital)?.label,
                        screenId: 'financialInfo2',
                        isSecret: true
                    },
                ]}
                /> 
            <OnboardingReviewSection 
                title={'Employment Info'}  
                goto={'employmentInfo'}
                errors={getEmploymentInfoErrors()}
                items={getItems({data: data.employmentInfo, screenId: 'employmentInfo', secrets: []})}
                /> 
            <OnboardingReviewSection 
                title={'Trading Experience'}  
                goto={'tradingExperience'}
                errors={getTradingExperienceErrors()}
                items={[
                    {
                        key: 'commoditiesYears', 
                        value: (text.tradingExperience?.experienceYears|| []).find(o => o.value === data?.tradingExperience?.commoditiesYears)?.label,
                        screenId: 'tradingExperience',
                        isSecret: false
                    },
                    {
                        key: 'securitiesYears', 
                        value: (text.tradingExperience?.experienceYears|| []).find(o => o.value === data?.tradingExperience?.securitiesYears)?.label,
                        screenId: 'tradingExperience',
                        isSecret: false
                    },
                    {
                        key: 'otcYears', 
                        value: (text.tradingExperience?.experienceYears|| []).find(o => o.value === data?.tradingExperience?.otcYears)?.label,
                        screenId: 'tradingExperience',
                        isSecret: false
                    },
                    {
                        key: 'professionalTrader', 
                        value: data?.tradingExperience?.professionalTrader?.toString(),
                        screenId: 'tradingExperience',
                        isSecret: false
                    },
                ]}
                /> 
                <OnboardingReviewSection 
                title={'Affiliations'}  
                goto={'affiliations'}
                errors={getAffiliationErrors()}
                items={getItems({data: data.affiliationsInfo, screenId: 'affiliations', secrets: []})}
                /> 
                <OnboardingReviewSection 
                title={'Regulatory Info'}  
                goto={'regulatoryInfo'}
                errors={getRegulatoryInfoErrors()}
                items={getItems({data: data.regulatoryInfo, screenId: 'regulatoryInfo', secrets: ['bankruptcy', 'disputeOrArbitration', 'unsatisfiedDebitBalance']})}
                /> 
        </Flex>
    )
}