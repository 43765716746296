// @ts-strict-ignore
import { Tab, Tabs } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinny, StyledIcon } from '..';
import { QuoteSelector } from 'phoenix/constants/ReduxSelectors';
import { OptionButton } from 'screens/OptionsScreen';
import { QuoteAttribute } from 'phoenix/util';
import { useLocalStorage } from 'hooks/UseLocalStorage';
import { useColors } from 'hooks/UseColors';
import './TabSelect.scss';
import { UseTabGroupStyles } from 'theming';
import { Link } from 'react-router-dom';

export type TabSelectItem = { label: string | JSX.Element; value: any };

// TODO There are 7 style props here; we should probably improve this interface

interface TabSelectProps {
    TabComponent?: (props: TabSelectItem) => JSX.Element;
    buttonStyle?: React.CSSProperties;
    buttonStyleActive?: React.CSSProperties;
    buttonStyleInactive?: React.CSSProperties;
    buttons?: boolean;
    color?: string;
    defaultSelected?: any;
    disabled?: boolean;
    hightlightColor?: string;
    isSortable?: boolean;
    onSelect?: (value: any) => any;
    scrollButtons?: boolean;
    style?: React.CSSProperties;
    symbol?: string;
    tabStyles?: React.CSSProperties;
    values: TabSelectItem[];
    route?: (route: string) => string;
}

const TabSelectComponent = (props: TabSelectProps) => {
    const quote = useSelector(QuoteSelector(props.symbol));
    let buttonColor;

    if (props.symbol) {
        buttonColor = (() => {
            const c = QuoteAttribute.getChangePercent(quote, true);
            if (c === 0) return 'neutral';
            return c > 0 ? 'positive' : 'negative';
        })();
    }

    return <TabSelectPresentation {...{ ...props, buttonColor }} />;
};

export const TabSelect = React.memo(TabSelectComponent);

type TabSelectPresentationProps = {
    TabComponent?: (props: TabSelectItem) => JSX.Element;
    buttonColor?: string;
    buttonStyle?: React.CSSProperties;
    buttonStyleActive?: React.CSSProperties;
    buttonStyleInactive?: React.CSSProperties;
    buttons?: boolean;
    color?: string;
    defaultSelected?: any;
    disabled?: boolean;
    hightlightColor?: string;
    isSortable?: boolean;
    onSelect?: (value: any) => any;
    scrollButtons?: boolean;
    style?: React.CSSProperties;
    tabStyles?: React.CSSProperties;
    values: TabSelectItem[];
    textColorOverride?: string;
    route?: (route: string) => string;
};

export const TabSelectPresentation = (props: TabSelectPresentationProps) => {
    const colors = useColors();
    const resolveValueFromIndex = (index: number) => (index >= props.values.length ? props.values[0].value : props.values[index].value);
    const resolveIndexFromValue = (value: any): number => props.values.findIndex((x) => x.value === value);

    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [sort, setSort] = useLocalStorage('POSITION_SORT_DIR', '_desc');

    useEffect(() => {
        if (props.defaultSelected) setSelectedIndex(resolveIndexFromValue(props.defaultSelected));
    }, [props.defaultSelected, props.values]);

    const handleSelect = (value: any) => {
        if (props.disabled) return;
        props.onSelect && props.onSelect(value);
        setSelectedIndex(resolveIndexFromValue(value));
    };

    const handleSelectIndex = (index: number) => {
        if (props.disabled) return;
        props.onSelect && props.onSelect(resolveValueFromIndex(index));
        setSelectedIndex(index);
    };

    if (props.buttons) {
        let buttonColor = 'neutral';
        switch (props.color) {
            case 'money-negative':
                buttonColor = 'negative';
                break;
            case 'money-positive':
                buttonColor = 'positive';
                break;
            case 'default':
            case 'disabled':
            default:
                buttonColor = 'neutral';
                break;
        }

        if ( props.buttonColor ) buttonColor = props.buttonColor

        let extraActiveStyles = {};
        let extraInactiveStyles = {};
        if (props.buttonStyleActive) {
            extraActiveStyles = { ...extraActiveStyles, ...props.buttonStyleActive };
        }

        if (props.buttonStyleInactive) {
            extraInactiveStyles = { ...extraInactiveStyles, ...props.buttonStyleInactive };
        }
        return (
            <div className='tab-select-buttons' role='tablist' style={props.style}>
                {props.values.map((v, idx) => (
                    <OptionButton
                        color={buttonColor as any}
                        key={idx}
                        selected={selectedIndex === idx}
                        style={{
                            ...(idx !== props.values.length - 1 ? { marginRight: 12, fontSize: 20 } : { fontSize: 20 }),
                            ...(selectedIndex === idx ? extraActiveStyles : extraInactiveStyles),
                            ...(props.buttonStyle || {})
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (props.isSortable) {
                                const newSort = sort === '' && selectedIndex === idx ? '_desc' : '';
                                setSort(newSort);
                            }
                            handleSelect(v.value);
                        }}
                        route={props.route ? props.route(v.value) : undefined}
                    >
                        {v.label}
                        {props.isSortable && selectedIndex === idx && (
                            <Spinny spun={sort === '_desc'}>
                                <StyledIcon IconComponent={ArrowUpward} size='18px' style={{ color: colors.white }} />
                            </Spinny>
                        )}
                    </OptionButton>
                ))}
            </div>
        );
    }

    return (
        <Tabs
            classes={UseTabGroupStyles( props.textColorOverride || colors.generalTextColor, props.color || colors.segmentedControlActiveColor)()}
            className='tab-select'
            scrollButtons={props.scrollButtons}
            textColor='inherit'
            style={{ ...props.style }}
            TabIndicatorProps={{ children: <span />, style: { backgroundColor: 'transparent' } }}
            TabScrollButtonProps={{ style: { color: props.color || colors.segmentedControlActiveColor } }}
            value={selectedIndex}
            variant={props.scrollButtons ? 'scrollable' : undefined}
            onChange={(_, newValue) => handleSelectIndex(newValue)}
        >
            {props.values.map((v, idx) => (
                <Tab
                    disableRipple
                    className={(idx === undefined || idx === null || idx === selectedIndex) ? 'selected' : undefined}
                    disabled={props.disabled}
                    key={idx}
                    label={props.TabComponent ? props.TabComponent(v) : v?.label}
                    component={props.route ? Link : undefined}
                    to={props.route ? props.route(v.value) : undefined}
                ></Tab>
            ))}
        </Tabs>
    );
};
