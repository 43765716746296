// @ts-strict-ignore
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Close } from '@mui/icons-material';
import { Button, ButtonProps, IconButton, lighten, Skeleton, TextField, Typography } from '@mui/material';
import { GetSelectedAccountForOrderRequest, useSelectedAccountByAssetFamily } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { DecimalPriceInput, DecimalPriceInputProps } from 'components/CustomNumberInput';
import { FractionalNumberInput } from 'components/CustomNumberInput/FractionalNumberInput';
import { SlidingComponent } from 'components/SlidingComponent/SlidingComponent';
import { useColors } from 'hooks/UseColors';
import { useQueryParams } from 'hooks/UseQueryParams';
import { omit } from 'lodash';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { T, Ts } from 'phoenix/assets/lang/T';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { useSnexStore } from 'phoenix/hooks/UseSnexStore';
import { useText } from 'phoenix/hooks/UseText';
import { ApiCreditDebit, ApiTimeInForce } from 'phoenix/models/ApiTradeRequest';
import { EquitiesAssetClass } from 'phoenix/models/AssetClasses/EquitiesAssetClass';
import { EquityOptionsAssetClass } from 'phoenix/models/AssetClasses/EquityOptionsAssetClass';
import { GetAssetClassForSecurity, GetAssetClassFromMetadataV2, useAssetClassMetaV2 } from 'phoenix/models/AssetClasses/useAssetClass';
import { GetAccountSummaryAction } from 'phoenix/redux/actions';
import { OptionSymbol } from 'phoenix/redux/models';
import { AccountBuyingPower } from 'phoenix/redux/models/AccountSummary/AccountBuyingPower';
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { GetFromSnexStore } from 'phoenix/redux/util/GetFromSnexStore';
import { BuyingPowerStore_Load, useBuyingPowerStore } from 'phoenix/stores/BuyingPowerStore';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { SecurityMetadataV2, useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';
import { ChangeColor, FormatNumber, GetSecurityTypeFromStore, GetSecurityTypeFromStoreV2, MoneyFormatOptions, parseDecimal, Sum } from 'phoenix/util';
import { IsShortSell } from 'phoenix/util/IsShortSell';
import { XS } from 'phoenix/xstream/XS';
import React, { CSSProperties, ReactElement, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { UseInputFieldStyles } from 'theming';
import { DISPLAY_POV_10, DISPLAY_POV_15, DISPLAY_POV_2, DISPLAY_POV_20, DISPLAY_POV_25, DISPLAY_POV_5, POV_DISPLAYS, POV_FLOORNOTES } from 'util/Utils';
import { Flex } from '../..';
import { CulledCollapse } from '../../CulledCollapse/CulledCollapse';
import { CustomNumberInput } from '../../CustomNumberInput/CustomNumberInput';
import { DropdownComponent } from '../../DropdownComponent';
import { InputSplit } from '../../InputSplit/InputSplit';
import { EquityTradeTicketViewModel, OptionsTradeTicketViewModel, TradeTicketViewModel } from '../Store/TradeTicketViewModel';
import { useTradeTicketViewModel } from '../Store/useTradeTicketViewModel';
import './index.scss';

export const PriceInputSplit = React.memo(
    (
        props: {
            error?: boolean;
            formatOptions?: MoneyFormatOptions;
            fullWidth?: boolean;
            helperText?: string;
            initialValue?: number;
            inputStyles?: any;
            label: string;
            onBlur?: (value: number) => any;
            onUnitChange?: (value: string) => void;
            onValueChange?: (value: number) => void;
            priceFormat?: number;
            sublabel?: string;
            units?: { label: string; value: string }[];
        } & DecimalPriceInputProps
    ) => {
        const filteredProps = omit(props, ['label']);

        return (
            <InputSplit label={props.label} options={props.units} subLabel={props.sublabel} onOptionSelect={props.onUnitChange}>
                {props?.formatOptions?.useFractional ? (
                    <FractionalNumberInput {...(filteredProps as CustomNumberInputProps)} />
                ) : (
                    <DecimalPriceInput {...filteredProps} onDollarValueChange={props.onValueChange} styles={props.inputStyles} />
                )}
            </InputSplit>
        );
    }
);

export interface CustomNumberInputProps {
    [propKey: string]: any;
    arrows?: boolean;
    decimalPlaces?: number;
    disabled?: boolean;
    error?: boolean;
    formatOptions?: MoneyFormatOptions;
    helperText?: string;
    initialValue: number;
    max?: number;
    maxIsInfinite?: boolean;
    meta?: SecurityMetadataV2;
    /** @deprecated use formatOptions */ min?: number;
    onBlur?: (v: string) => void;
    onDollarValueChange?: (dollars: number) => void;
    step?: number;
    styles?: React.CSSProperties;
}

export const NumberInputSplit = React.memo(
    React.forwardRef(
        (
            props: {
                decimalPlaces?: number;
                error?: boolean;
                fullWidth?: boolean;
                helperText?: string;
                initialValue: number | string;
                inputStyles?: any;
                isFractional?: boolean;
                label: string;
                meta?: SecurityMetadataV2;
                onBlur?: (value: number) => any;
                onUnitChange?: (value: string) => any;
                onValueChange?: (value: number) => any;
                step?: number;
                sublabel?: string;
                tickSize?: number;
                units?: { label: string; value: string }[];
            } & Partial<CustomNumberInputProps>,
            ref?: React.MutableRefObject<HTMLInputElement>
        ) => {
            const filteredProps = omit(props, ['label']);
            return (
                <InputSplit label={props.label} options={props.units} subLabel={props.sublabel} onOptionSelect={props.onUnitChange}>
                    {props.meta?.formatting?.isFractional ? (
                        <FractionalNumberInput {...(filteredProps as CustomNumberInputProps)} />
                    ) : (
                        <CustomNumberInput
                            ref={ref}
                            {...(filteredProps as CustomNumberInputProps)}
                            onDollarValueChange={props.onValueChange}
                            styles={props.inputStyles}
                        />
                    )}
                </InputSplit>
            );
        }
    )
);

interface TimeInForceSplitProps {
    onValueChange: (value: ApiTimeInForce) => void;
    text: Snex1LanguagePack['tradeTicket']['input'];
    value: ApiTimeInForce;
    disabled?: boolean;
    values?: ApiTimeInForce[];
}

export const TimeInForceSplit = React.memo((props: TimeInForceSplitProps) => {
    const { text } = props;
    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();
    const { getMetadataBySymbol } = useSecurityMetadataV2();
    const meta = getMetadataBySymbol(viewModel.symbol);
    const assetClass = GetAssetClassFromMetadataV2(meta);
    const selectedAccountNumber = GetSelectedAccountForOrderRequest(assetClass);
    const isDvp = GetFromSnexStore((s) => s.user.myInfo.data?.isDvp);
    const isShort = useMemo(() => IsShortSell(viewModel?.symbol, selectedAccountNumber, viewModel?.tradeAction, viewModel?.quantity), [viewModel, selectedAccountNumber]);
    const tifs = useMemo(
        () => ({
            Day: { label: text.timesInForce.day, value: 'Day' },
            GTC: { label: text.timesInForce.gtc, value: 'GTC' }
        }),
        [text]
    );
    const optionsToUse = useMemo(
        () => (viewModel?.algoStrategy === text.pov ? [tifs.Day] : isShort && !isDvp ? [tifs.Day] : [tifs.Day, tifs.GTC]),
        [viewModel?.algoStrategy, text.pov, tifs.Day, tifs.GTC, isShort, isDvp]
    );

    const options = useMemo(() => {
        if (props.values) return props.values.map((v) => tifs[v]);
        else return optionsToUse;
    }, [optionsToUse, props.values, tifs]);

    return (
        <InputSplit className='time-in-force-select' label={text.timeInForce} style={{ opacity: props.disabled ? 0.6 : 1 }}>
            <DropdownComponent disabled={props.disabled} options={options} value={props.value} onValueChange={props.onValueChange} />
        </InputSplit>
    );
});

interface OutputSplitProps {
    title: string;
    figure: string;
    fontType?: string;
}

export const OutputSplit = React.memo(function OutputSplit(props: OutputSplitProps) {
    const inputSplitStyle: React.CSSProperties = (() => {
        if (props.fontType === 'title') return { fontWeight: 'bold', fontSize: 20 };
        else if (props.fontType === 'bold') return { fontWeight: 'bold' };
        else return {};
    })();

    return (
        <InputSplit label={props.title} style={inputSplitStyle}>
            <Typography style={{ fontSize: 15, whiteSpace: 'pre-wrap' }} variant='h6'>
                {props.figure}
            </Typography>
        </InputSplit>
    );
});

export const MarketPriceDisplay = React.memo(
    ({
        ask,
        bid,
        hideAskBid = false,
        loading = false,
        price,
        text
    }: {
        ask: string;
        bid: string;
        hideAskBid?: boolean;
        loading: boolean;
        price: string;
        text: Snex1LanguagePack['tradeTicket']['input'];
    }) => {
        const title = text.marketPrice;

        return (
            <Flex column>
                <div className='market-price-display'>
                    <Typography className='label' variant='h6'>
                        {title}
                    </Typography>
                    {loading ? (
                        <Skeleton animation='wave' width={60} />
                    ) : (
                        <Typography style={{ fontSize: 20, fontWeight: 500 }} variant='h6'>
                            {price}
                        </Typography>
                    )}
                </div>
                {!hideAskBid && (
                    <Flex row justify='flex-end' style={{ marginTop: -14, opacity: bid || ask ? 1 : 0, transition: 'opacity 200ms' }}>
                        <Typography variant='caption'>
                            {text.bid}: {bid}
                        </Typography>
                        <Typography style={{ marginLeft: 30 }} variant='caption'>
                            {text.ask}: {ask}
                        </Typography>
                    </Flex>
                )}
            </Flex>
        );
    }
);

export const BidAskDisplay = React.memo(
    ({ ask, bid, text, showNet = false }: { ask: string; bid: string; text: Snex1LanguagePack['tradeTicket']['input']; showNet?: boolean }) => {
        return (
            <div className='bid-ask-display'>
                <Typography variant='caption'>
                    {showNet ? text.options.netBid : text.bid}: {bid}
                </Typography>
                <Typography style={{ marginLeft: 30 }} variant='caption'>
                    {showNet ? text.options.netAsk : text.ask}: {ask}
                </Typography>
            </div>
        );
    }
);

export const SideDisplay = React.memo(() => {
    const { tradeAction } = useTradeTicketViewModel<TradeTicketViewModel>();
    const text = useText((s) => s.tradeTicket.input);
    const title = text.futures.side;

    return (
        <InputSplit label={title}>
            <Typography style={{ fontSize: 15 }} variant='h6'>
                {tradeAction === 'Buy' ? text.action.buy : text.action.sell}
            </Typography>
        </InputSplit>
    );
});

export const TotalCostDisplay = React.memo(() => {
    // Order cost is (quantity * marketPrice) / unitQuantity, displayed for both buys and sells
    const dispatch = useDispatch();
    const { initialLimitPrice, initialQuantity, initialStopPrice, limitPrice, orderType, quantity, quantityQualifier, stopPrice, symbol, tradeAction } =
        useTradeTicketViewModel<TradeTicketViewModel>();
    const { loadData, getMetadataBySymbol } = useSecurityMetadataV2();

    const apiQuote = useSnexStore((s) => s.securityQuote.bySymbol[symbol])?.data;
    const xStreamQuote: StandardQuote = XS.Quotes.use(symbol);
    const isMutualFund = apiQuote?.securityType && apiQuote?.securityType === 'mutual-fund';
    const quote = isMutualFund ? apiQuote : { ...apiQuote, ...xStreamQuote };
    const lang = Ts((s) => s.tradeTicket.input);

    const title = useMemo(() => {
        const estimated = orderType === 'market' || orderType === 'stop';
        const isBuy = tradeAction === 'Buy';
        return estimated ? (isBuy ? lang.estimatedTotalCost : lang.estimatedTotalGain) : isBuy ? lang.totalCost : lang.totalGain;
    }, [lang, orderType, tradeAction]);

    useEffect(() => {
        loadData(symbol);
    }, [dispatch, loadData, symbol]);

    const effectiveQuantity = quantity || initialQuantity;
    const meta = getMetadataBySymbol(symbol);
    const unitQuantity = useMemo(() => 1 / (meta?.sizing?.multiplier || 1), [meta]);

    const total = useMemo(() => {
        if (quantityQualifier === 'EvenDollar') return effectiveQuantity;
        const marketPrice = quote?.price;
        const effectiveUnitPrice = (() => {
            switch (orderType) {
                case 'market':
                    return marketPrice;
                case 'limit':
                case 'stoplimit':
                    return limitPrice || initialLimitPrice;
                case 'stop':
                    return stopPrice || initialStopPrice;
                default:
                    return NaN;
            }
        })();
        const orderCost = (effectiveQuantity * effectiveUnitPrice) / unitQuantity;
        return orderCost;
    }, [quantityQualifier, effectiveQuantity, quote?.price, unitQuantity, orderType, limitPrice, initialLimitPrice, stopPrice, initialStopPrice]);

    return <TotalCostDisplayPresentation label={title} value={FormatNumber.toMoney(total)} />;
});

export const TotalCostDisplayPresentation = ({ label, value }: { label: string; value: string }): JSX.Element => {
    return (
        <div className='total-cost-display'>
            <Typography className='label' variant='h6'>
                {label}
            </Typography>
            <Typography className='value' variant='h6'>
                {value}
            </Typography>
        </div>
    );
};

export const BigTradeButton = React.memo((props: { fillColor?: string; textColor?: string } & ButtonProps) => {
    const { primaryItemColor } = useColors();
    let color = props.fillColor || primaryItemColor;
    if (props.disabled) color = lighten(color, 0.5);

    // Remove props which do not conform to the underlying button component interface
    const filteredProps = omit(props, ['fillColor']);

    return (
        <Button {...filteredProps} fullWidth style={{ ...props.style, backgroundColor: color, color: props.textColor || 'white', margin: '5px 0', padding: 15 }}>
            {props.children}
        </Button>
    );
});

export const BuyingPowerDisplayV2 = React.memo(
    (props: { meta?: SecurityMetadataV2; symbol: string; accountNumber?: string; useOptionsBuyingPower?: boolean; showAggregateBuyingPower?: boolean }) => {
        const dispatch = useDispatch();
        const { load: loadPositions } = usePositionsStore();
        const assetClass = useAssetClassMetaV2(props.symbol);
        const text = useText((s) => s.tradeTicket.input);

        const buyingPowerStore = useBuyingPowerStore();
        const aggregateBuyingPower = useMemo(() => buyingPowerStore?.summary, [buyingPowerStore?.summary]);
        const selectedAccountBuyingPower = useMemo(
            () => (props?.accountNumber ? buyingPowerStore?.findByAccount(props.accountNumber) : {}),
            [buyingPowerStore, props.accountNumber]
        );
        const buyingPowerDataIsLoading = useMemo(() => buyingPowerStore?.isLoading === true, [buyingPowerStore?.isLoading]);
        const buyingPower = useMemo(
            () => (props.accountNumber ? selectedAccountBuyingPower : props.showAggregateBuyingPower ? aggregateBuyingPower : {}),
            [aggregateBuyingPower, props.showAggregateBuyingPower, props.accountNumber, selectedAccountBuyingPower]
        );

        const dataPresent = !!buyingPower?.buyingPower;
        const userInfo = useSnexStore((s) => s.user?.myInfo.data);
        const isDvp = useMemo(() => userInfo?.isDvp, [userInfo]);

        const loading = (() => {
            if ((dataPresent || !props.accountNumber) && !buyingPowerDataIsLoading) return false;
            return !!buyingPower?.buyingPower;
        })();

        useEffect(() => {
            loadPositions();
            BuyingPowerStore_Load();
        }, [loadPositions]);

        useEffect(() => {
            if (props.accountNumber) dispatch(GetAccountSummaryAction(props.accountNumber));
        }, [dispatch, props.accountNumber]);

        const title = text.buyingPower;
        const figure = props.useOptionsBuyingPower ? FormatNumber.toMoney(buyingPower?.optionsBuyingPower) : FormatNumber.toMoney(buyingPower?.buyingPower);

        return <BuyingPowerDisplayPresentation className={assetClass.family} {...{ figure, isDvp, loading, title }} />;
    }
);

export type BuyingPowerDisplayProps = {
    className: string;
    figure: string;
    isDvp: boolean;
    loading: boolean | AccountBuyingPower;
    title: string;
};

export const BuyingPowerDisplayPresentation = React.memo(({ className, figure, isDvp, loading, title }: BuyingPowerDisplayProps) => {
    return (
        <div className={`buying-power-display ${className}`}>
            <Typography variant='h6'>{title}</Typography>
            {loading ? (
                <Flex row justify='flex-end'>
                    <Skeleton animation='wave' style={{ width: 90 }} />
                </Flex>
            ) : (
                <Typography variant='h6'>{isDvp ? 'DVP' : figure}</Typography>
            )}
        </div>
    );
});

export const OptionUnderlyingHoldingsDisplay = React.memo((props: { accountNumber: string | null; underlyingSymbol?: string }) => {
    const { accountNumber, underlyingSymbol } = props;
    const underlyingPosition = usePositionsStore((s) => s.find({ qsi: underlyingSymbol, accountNumber }));
    const isFuture = GetSecurityTypeFromStoreV2(underlyingSymbol) === 'future';
    const underlyingShares = Sum(underlyingPosition?.map((p) => p.quantity));
    const text = useText((s) => s.tradeTicket.input);

    if (!underlyingSymbol || !accountNumber || isFuture) return null;

    return (
        <CulledCollapse in={!!(underlyingShares && props.accountNumber)}>
            <InputSplit label={text.options.underlyingShares}>
                <Typography variant='h6'>{EquitiesAssetClass.formatQuantity(underlyingShares)}</Typography>
            </InputSplit>
        </CulledCollapse>
    );
});

const OptionTotalCostDisplayComponent = () => {
    const { initialLimitPrice, initialQuantity, initialStopPrice, limitPrice, orderType, quantity, stopPrice, symbol, tradeAction } =
        useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const { rollQuantity } = useQueryParams();
    const selectedAccountNumber = useSelectedAccountByAssetFamily(GetAssetClassForSecurity(symbol)?.family);
    const text = useText((s) => s.tradeTicket.input);
    const isBuy = tradeAction === 'Buy';
    const optSym = new OptionSymbol(symbol);
    const isFuture = FuturesSymbol.IsFuturesSymbol(symbol);
    const quote = XS.OptionQuotes.use(isFuture ? optSym.upstreamSymbol : optSym.osiSymbol);
    const { getMetadataBySymbol } = useSecurityMetadataV2();
    const meta = getMetadataBySymbol(symbol);
    const effectiveLimitPrice = limitPrice || initialLimitPrice;
    const effectiveQuantity = quantity || initialQuantity || parseDecimal(rollQuantity);
    const effectiveStopPrice = stopPrice || initialStopPrice;
    const positionsStore = usePositionsStore();

    const contractSize = meta?.sizing?.multiplier || NaN;
    const strike = meta?.option?.strikePrice || optSym.strike;

    const effectiveSize = useMemo(() => effectiveQuantity * contractSize, [effectiveQuantity, contractSize]);

    // For call sells: if you are trying to sell more shares than you have, then
    // the loss is unlimited (because you'd then be shorting the option)
    const underlyingPositions = positionsStore.find({ qsi: optSym.underlyingSymbol, accountNumber: selectedAccountNumber });
    const positions = positionsStore.find({ qsi: symbol, accountNumber: selectedAccountNumber });
    const underlyingShares = useMemo(() => Sum(underlyingPositions?.map((p) => p.quantity)), [underlyingPositions]);
    const holdsSecurity = !!positions?.length;
    const goingShort = !isBuy && optSym.putCall === 'C' && effectiveSize > underlyingShares;
    const isLong = Sum(positions?.map((p) => p.quantity)) > 0;
    const openClose = (() => {
        if (!holdsSecurity) return 'Open';
        if (tradeAction === 'Buy') return isLong ? 'Open' : 'Close';
        else return isLong ? 'Close' : 'Open';
    })();
    const title = useMemo(() => {
        const estimated = orderType === 'market' || orderType === 'stop';
        const isBuy = tradeAction === 'Buy';
        return estimated ? (isBuy ? text.estimatedTotalCost : text.estimatedTotalGain) : isBuy ? text.totalCost : text.totalGain;
    }, [tradeAction, orderType, text]);

    const total = useMemo(() => {
        const marketPrice = tradeAction === 'Buy' ? quote?.ask : quote?.bid;
        if (orderType === 'market') return marketPrice * effectiveSize;
        else return effectiveLimitPrice * effectiveSize;
    }, [effectiveSize, effectiveLimitPrice, orderType, quote, tradeAction]);

    const isCall = optSym.putCall === 'C';
    const maxTitle = isBuy ? text.options.maxGain : text.options.maxLoss;
    const maxValue = (() => {
        if (isCall) return goingShort || isBuy ? text.options.unlimited : null;
        const principal = (() => {
            if (orderType === 'market' || orderType === 'stop') return quote?.last;
            else if (orderType === 'stoplimit') return effectiveLimitPrice;
            else return effectiveStopPrice;
        })();
        return FormatNumber.toMoney(effectiveSize * (strike - principal));
    })();

    const isDangerous = maxTitle === text.options.maxLoss && maxValue === text.options.unlimited;

    return (
        <Flex column>
            <div className='total-cost-display'>
                <Typography className='label' variant='h6'>
                    {title}
                </Typography>
                <Typography className='value' variant='h6'>
                    {FormatNumber.toMoney(total)}
                </Typography>
            </div>
            {!isFuture && openClose === 'Open' && maxValue ? (
                <div className='total-cost-display'>
                    <Typography className='label' variant='h6'>
                        {maxTitle}
                    </Typography>
                    <Typography className='value' style={{ fontSize: 20, color: isDangerous ? ChangeColor(-1) : undefined }}>
                        {maxValue}
                    </Typography>
                </div>
            ) : null}
        </Flex>
    );
};

export const OptionTotalCostDisplay = React.memo(OptionTotalCostDisplayComponent);

export const OptionContractSummaryRow = React.memo(
    ({
        action,
        ask,
        bid,
        expDate,
        last,
        onCancel,
        putCall,
        showBidAsk,
        showCloseButton = true,
        strike,
        symbol
    }: {
        action: string;
        ask: string;
        bid: string;
        expDate: string;
        last: string;
        onCancel?: () => any;
        putCall: string;
        showBidAsk?: boolean;
        showCloseButton?: boolean;
        strike: string;
        symbol?: string;
    }) => {
        const text = useText((s) => s.tradeTicket.input);

        return (
            <Flex column>
                <Flex row align='center' justify='space-between' style={{ padding: '6px 0' }}>
                    <Flex column>
                        <Typography variant='h6'>
                            {symbol} {strike} {putCall}
                        </Typography>
                        <Typography color='textSecondary' variant='body1'>
                            {expDate} &middot; {action}
                        </Typography>
                    </Flex>
                    <Flex row align='center'>
                        <Typography style={{ marginRight: 5 }} variant='caption'>
                            {text.options.last}
                        </Typography>
                        <Typography style={{ fontSize: 17, marginRight: 4 }} variant='h6'>
                            {last}
                        </Typography>
                        {showCloseButton && (
                            <IconButton onClick={() => onCancel()}>
                                <Close />
                            </IconButton>
                        )}
                    </Flex>
                </Flex>
                {showBidAsk && (
                    <Flex row justify='flex-end' style={{ marginTop: -10, marginBottom: 10, opacity: bid || ask ? 1 : 0, transition: 'opacity 200ms' }}>
                        <Typography variant='caption'>
                            {text.bid}: {bid}
                        </Typography>
                        <Typography style={{ marginLeft: 30 }} variant='caption'>
                            {text.ask}: {ask}
                        </Typography>
                    </Flex>
                )}
            </Flex>
        );
    }
);

export const MultiLegOptionPriceRow = React.memo((): ReactElement => {
    const { initialLimitPrice, debitCredit, setViewModel } = useTradeTicketViewModel<OptionsTradeTicketViewModel>();
    const text = useText((s) => s.tradeTicket.input);

    const options = [
        { label: text.options.credit, value: 'Credit' },
        { label: text.options.debit, value: 'Debit' },
        { label: text.options.even, value: 'Even' }
    ];

    return (
        <>
            <Typography variant='h6'>{text.limitPrice}</Typography>
            <Flex row align='center' justify='space-between' style={{ padding: '6px 0', height: 56 }}>
                <DecimalPriceInput
                    disabled={debitCredit === 'Even'}
                    formatter={EquityOptionsAssetClass.formatPrice}
                    formatOptions={EquityOptionsAssetClass.getPriceFormatInfo()?.moneyFormatOptions}
                    initialValue={initialLimitPrice}
                    label={undefined}
                    onDollarValueChange={(limitPrice: number) => setViewModel({ limitPrice })}
                />
                <DropdownComponent
                    disabled={false}
                    options={options}
                    value={options.find((o) => o.value === debitCredit)}
                    onValueChange={(debitCredit) =>
                        debitCredit === 'Even'
                            ? setViewModel({ initialLimitPrice: 0, limitPrice: 0, debitCredit: 'Even' })
                            : setViewModel({ debitCredit: debitCredit as ApiCreditDebit })
                    }
                />
            </Flex>
        </>
    );
});

export const AdvancedRoutingControl = React.memo(({ onChange }: { onChange: (value: string) => void }) => {
    const text = useText((s) => s.tradeTicket.input);
    const colors = useColors();
    const inputClasses = UseInputFieldStyles(colors)();

    const strategyOptions = [text.care, text.vwap, text.twap, text.pov, text.sor].map((x) => ({ isDisabled: x !== text.care, label: x, value: x }));

    return (
        <>
            <InputSplit label={text.strategy}>
                <DropdownComponent options={strategyOptions} value={text.care} />
            </InputSplit>
            <InputSplit label={text.notes}>
                <TextField classes={inputClasses} onChange={({ target }) => onChange(target?.value)} variant='outlined' />
            </InputSplit>
        </>
    );
});

export const POVOptions = [
    { label: '10%', value: DISPLAY_POV_10 },
    { label: '2%', value: DISPLAY_POV_2 },
    { label: '5%', value: DISPLAY_POV_5 },
    { label: '15%', value: DISPLAY_POV_15 },
    { label: '20%', value: DISPLAY_POV_20 },
    { label: '25%', value: DISPLAY_POV_25 }
];

export const ExpandingTradeFormControl = ({ isVisible, children }: { isVisible: boolean; children: ReactJSXElement }): JSX.Element => {
    return <div style={{ overflowY: isVisible ? 'visible' : 'clip', height: isVisible ? 60 : 0, transition: 'all 0.25s ease', position: 'relative' }}>{children}</div>;
};

export const POVControl = ({ modifyingOrder, isVisible }: { modifyingOrder?: boolean; isVisible?: boolean }): JSX.Element => {
    return (
        <ExpandingTradeFormControl isVisible={isVisible}>
            <InputSplit label={'Percentage'}>
                <PovPercentageComponent modifyingOrder={modifyingOrder} />
            </InputSplit>
        </ExpandingTradeFormControl>
    );
};
const PovPercentageComponent = ({ modifyingOrder, style }: { modifyingOrder?: boolean; style?: CSSProperties }) => {
    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();
    const { setViewModel } = viewModel;

    useEffect(() => {
        if (!viewModel?.algoNotes && viewModel?.algoStrategy === T((t) => t.tradeTicket.input.pov) && !modifyingOrder) setViewModel({ algoNotes: DISPLAY_POV_10 });
    }, [viewModel?.algoNotes, viewModel?.algoStrategy, modifyingOrder, setViewModel]);

    const handlePovChange = (povValueKey: string) => {
        if (!modifyingOrder) setViewModel({ algoNotes: povValueKey });
    };

    return <DropdownComponent options={POVOptions} value={viewModel?.algoNotes} onValueChange={handlePovChange} style={{ marginTop: '8px', ...style }} />;
};

export const AlgoControl = () => {
    const lang = useText((s) => s);
    const text = lang.tradeTicket.input;
    const colors = useColors();
    const inputClasses = UseInputFieldStyles(colors)();
    const userInfo = useSnexStore((s) => s.user?.myInfo.data);

    const { algoNotes = '', algoStrategy = text.sor, modifyingOrder, setViewModel, symbol } = useTradeTicketViewModel<EquityTradeTicketViewModel>();
    const isOption = OptionSymbol.IsOptionSymbol(symbol);
    const textDisabled = algoStrategy !== text.care || modifyingOrder;

    const optionsStrategies = [text.sor, text.care];
    const optionsEditStrategies = [...optionsStrategies, ''];
    const standardStrategies = [text.sor, text.care, text.vwap, text.twap, text.pov];
    const standardEditStrategies = [...standardStrategies, ''];
    const viewModel = useTradeTicketViewModel<TradeTicketViewModel>();

    const getOptions = () => {
        if (isOption) return modifyingOrder ? optionsEditStrategies : optionsStrategies;

        return modifyingOrder ? standardStrategies : standardEditStrategies;
    };

    const timeInForceIsNotDay = viewModel?.timeInForce?.toUpperCase() !== ('DAY' as ApiTimeInForce);
    const strategyOptions = getOptions().map((x) => ({ isDisabled: modifyingOrder || (x === text.pov && timeInForceIsNotDay), label: x, value: x }));
    const showPercentage = useMemo(() => algoStrategy === strategyOptions?.find((x) => x.label === text.pov)?.value, [algoStrategy, strategyOptions, text.pov]);
    const showNotes = userInfo?.role === 'admin';

    const onStratChange = (v: string) => {
        const notes = v === text.care && !POV_FLOORNOTES.includes(algoNotes) && !POV_DISPLAYS.includes(algoNotes) ? algoNotes : '';
        if (!modifyingOrder) setViewModel({ algoNotes: notes, algoStrategy: v });
    };

    const onNotesChange = (v: string) => setViewModel({ algoNotes: v });

    return (
        <>
            <InputSplit label={text.strategy}>
                <DropdownComponent disabled={modifyingOrder} options={strategyOptions} value={algoStrategy} onValueChange={onStratChange} />
            </InputSplit>
            {showNotes && (
                <SlidingComponent minHeight={60} slideIndex={showPercentage ? 1 : 0}>
                    <InputSplit label={text.notes}>
                        <TextField
                            disabled={textDisabled}
                            value={algoNotes}
                            classes={inputClasses}
                            onChange={({ target }) => onNotesChange(target?.value)}
                            variant='outlined'
                        />
                    </InputSplit>
                    <InputSplit label={'Percentage'}>
                        <PovPercentageComponent modifyingOrder={modifyingOrder} />
                    </InputSplit>
                </SlidingComponent>
            )}
        </>
    );
};

export function BidAskButtons({
    ask,
    bid,
    askLabel,
    bidLabel,
    onValueChange
}: {
    ask?: number;
    bid?: number;
    askLabel: string;
    bidLabel: string;
    onValueChange: (value: number) => void;
}): ReactElement {
    return (
        <div className='bid-ask-buttons'>
            <button onClick={() => onValueChange(bid)}>{bidLabel}</button>
            <button onClick={() => onValueChange(ask)}>{askLabel}</button>
        </div>
    );
}
