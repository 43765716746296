// @ts-strict-ignore
import React from "react"
import animationData from 'phoenix/assets/animations/Money.json'
import { Flex } from "components"
import { OnboardingDropdown, OnboardingInputRow, OnboardingLottieForm } from "screens/OnboardingScreens/Base/OnboardingControls"
import { AllowedFuturesCountryOptions } from "screens/OnboardingScreens/_constants/DropdownOptions/CountryOptions"
import { useFuturesOnboardingStore } from "../../Store"
import { useText } from "phoenix/hooks/UseText"
import { getFinancialInfo1Errors } from "./Validation"

export const FuturesOnboarding_FinancialInfo1_Form = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding.accountInfo)
    const handleChange = (key: string, value: string) => {
        setValue(key, value)
    }
    const getErrors = (key: string) => {
        return getFinancialInfo1Errors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
    }
    
    return(
        <OnboardingLottieForm data={animationData} loop={false}>
             <Flex column center fullWidth style={{gap: '1rem'}}>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.accountInfo.sourceOfFunds' options={text.sourceOfFundsOptions} 
                        label={text.sourceOfFunds} value={data.accountInfo?.sourceOfFunds} handleChange={handleChange} 
                        errors={getErrors('data.accountInfo.sourceOfFunds')}/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.accountInfo.intendedInitialDeposit' options={text.intendedInitialDepositOptions} 
                        label={text.intendedInitialDeposit} value={data.accountInfo?.intendedInitialDeposit} handleChange={handleChange} 
                        errors={getErrors('data.accountInfo.intendedInitialDeposit')}/>
                </OnboardingInputRow>
                <OnboardingInputRow noPadding>
                    <OnboardingDropdown name='data.accountInfo.expectedMonthlyTrading' options={text.expectedMonthlyTradingOptions} 
                        label={text.expectedMonthlyTrading} value={data.accountInfo?.expectedMonthlyTrading} handleChange={handleChange} 
                        errors={getErrors('data.accountInfo.expectedMonthlyTrading')}/>
                </OnboardingInputRow>
             </Flex>
        </OnboardingLottieForm>
    )
}