// @ts-strict-ignore
import { OnboardingDropdown, OnboardingInputRow, OnboardingLottieForm, OnboardingTextField } from "screens/OnboardingScreens/Base/OnboardingControls"
import animationData from 'phoenix/assets/animations/Identification.json'
import { useText } from "phoenix/hooks/UseText"
import { useFuturesOnboardingStore } from "../../Store"
import React, { useEffect } from "react"
import { getPersonalInfoErrors } from "./Validation"
import { AllowedFuturesCountryOptions } from "screens/OnboardingScreens/_constants/DropdownOptions/CountryOptions"
import { Flex } from "components"
import moment from "moment"

export const FuturesOnboarding_PersonalInfo_Form = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding.personalInfo)
    const handleChange = (key: string, value: string) => {
        setValue(key, value)
    }
    const getErrors = (key: string) => {
        const errors  = getPersonalInfoErrors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
        const moment1 = moment(data?.personalInfo?.dob).format("MM/DD/YYYY")
        const moment2 =  moment(data?.personalInfo?.dob).format("YYYY")
        console.log({errors, moment1, moment2})
        return errors
    }

    useEffect(() => console.log({dob: data?.personalInfo?.dob}), [data?.personalInfo?.dob])

    return(
        <OnboardingLottieForm data={animationData} loop={false}>
            <Flex column center fullWidth style={{gap: '1rem'}}>
             <OnboardingInputRow noPadding>
                <OnboardingTextField placeholder={"MM/DD/YYYY"} name='data.personalInfo.dob' label={text.dob} value={data.personalInfo?.dob} handleChange={handleChange} errors={getErrors('data.personalInfo.dob')}/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingDropdown name='data.personalInfo.country' options={AllowedFuturesCountryOptions} label={text.country} value={data.personalInfo?.country} handleChange={handleChange} errors={getErrors('data.personalInfo.country')}/>
            </OnboardingInputRow>
            <OnboardingInputRow noPadding>
                <OnboardingTextField placeholder={"000-00-0000"} name='data.personalInfo.ssn' secret={true} label={text.ssn} value={data.personalInfo?.ssn} handleChange={handleChange} errors={getErrors('data.personalInfo.ssn')}/>
            </OnboardingInputRow>
            </Flex>
        </OnboardingLottieForm>
    )
}