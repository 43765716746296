import { T } from "phoenix/assets/lang/T"
import { OnboardingInputError } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"

export const AgreementsAreValid = () => getArgreementsErrors()?.length < 1
export const getArgreementsErrors = () => {
    const _errors = []  as OnboardingInputError[]
    const requiredLinks = useFuturesOnboardingStore.getState().requiredLinks
    const clickedLinks = useFuturesOnboardingStore.getState().clickedLinks
    const requiredText = T(t => t.webRegistration.errors.required)

    if(!requiredLinks.length) _errors.push({key: 'linksLoaded', error: requiredText})
    requiredLinks.forEach(r => {
        if(!clickedLinks.includes(r)) _errors.push({key: `${r}`, error: requiredText})
    })

    return _errors.filter(e => !!e);

}