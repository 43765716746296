// @ts-strict-ignore
import classNames from "classnames";
import { DndTypes, WatchlistDndItem } from "constants/DndTypes";
import { GetConfig } from "phoenix/constants";
import { Actions, AddToWatchlistAction, RemoveFromWatchlistAction, ReorderWatchlistItemAction } from "phoenix/redux/actions";
import React from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import './WatchlistItemReorderDropZone.scss';

interface WatchlistItemReorderDropZoneProps {
    watchlistId: string,
    index: number,
    children: any
}

export const WatchlistItemReorderDropZone = React.memo((props: WatchlistItemReorderDropZoneProps) => {

    const { watchlistId, index } = props

    const handleItemDrop = async (item: WatchlistDndItem) => {

        const fromIdx = item.index;
        let toIdx = index;
        const movedPositions = fromIdx !== toIdx;
        const fromListId = item.watchlistId;
        const toListId = watchlistId;
        const movedLists = fromListId !== toListId;
        if (!movedPositions && !movedLists) return console.warn('Item not moved, bailing');

        const toLists = GetConfig().Store.getState().watchlists.all.data?.filter(l => l.id === watchlistId) || [];
        if (!toLists.length) return console.warn('Destination list not found, bailing', { watchlistId });
        const toList = toLists[0];

        const copy = false; // TODO -- If the user is holding down the ctrl button, make this a copy operation instead

        if (fromListId !== toListId) { // Between lists, just remove + add
            const fromLists = GetConfig().Store.getState().watchlists.all.data?.filter(l => l.id === fromListId) || [];
            if (!fromLists.length) return console.warn('Source list not found, bailing', { watchlistId });
            const fromList = fromLists[0];
            const toMove = fromList.securities[fromIdx]
            dispatch({ type: Actions.Watchlists.MoveItemBetweenLists, data: { fromListId, toListId, fromIdx, toIdx, item: toMove } })
            if (!copy) dispatch(RemoveFromWatchlistAction(fromListId, item.qsi));
            await dispatch(AddToWatchlistAction(toListId, item.qsi, toIdx));
        } else { // Otherwise, use the reorder endpoint
            const reordered = [ ...(toList.securities) ];
            if (fromIdx < toIdx) --toIdx;
            const toMove = reordered[fromIdx];
            reordered.splice(fromIdx, 1);
            reordered.splice(toIdx, 0, toMove);
            dispatch(ReorderWatchlistItemAction(watchlistId, reordered))
        }

    }

    const dispatch = useDispatch()
    const [ { isOver, canDrop }, dropRef ] = useDrop(() => ({
        accept: DndTypes.WatchlistItem,
        canDrop: _ => true,
        drop: handleItemDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }))

    return (
        <div ref={dropRef} className={classNames({
            'watchlist-item-reorder-drop-zone': true,
            'watchlist-item-reorder-drop-zone-hover': isOver,
            'watchlist-item-reorder-drop-zone-hidden': !canDrop,
        })}>
            {props.children}
        </div>
    )

})
