import React from 'react';
import { Flex } from 'components';
import { Typography } from '@mui/material';
import { useText } from 'phoenix/hooks/UseText';
import { CallSupportButton } from 'components/Support/CallSupportButton';
import { EmailSupportButton } from 'components/Support/EmailSupportButton';
import { ChatWithSupportButton } from 'components/Support/ChatWithSuportButton';
import { useColors } from 'hooks/UseColors';
import Logo from 'components/Logo';
import '../SplashScreen/style.css';
import { LoginButton } from 'components/LoginButton/LoginButton';



const LoggedOutScreen = () : JSX.Element => {
    const text = useText((s) => s.loggedOutScreen);
    const { boxShadowLight } = useColors();

    return (
        <section className='splash-screen'>
            <div className='image-wrapper'>
                <Logo />
                <Flex column align='center'>
                    <Typography style={{ marginTop: 40, textAlign: 'center' }} variant='h3'>
                        {text.title}
                    </Typography>
                    <Typography style={{ marginTop: 32, textAlign: 'center' }} variant='body1'>
                        {text.body}
                    </Typography>
                    <Flex column style={{ width: 400, marginTop: 20 }}>
                        <div style={{ borderTop: boxShadowLight, margin: '15px 0' }}></div>
                        <EmailSupportButton />
                        <CallSupportButton />
                        <ChatWithSupportButton />
                        <LoginButton />
                    </Flex>
                </Flex>
            </div>
        </section>
    );
};

export default LoggedOutScreen;
