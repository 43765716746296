import { Flex } from "components"
import { useText } from "phoenix/hooks/UseText"
import React from "react"
import { OnboardingButton, OnboardingErrorRow, OnboardingLottieForm } from "screens/OnboardingScreens/Base/OnboardingControls"
import { useFuturesOnboardingStore } from "../../Store"
import { getEmploymentInfoErrors } from "./Validation"
import animationData from 'phoenix/assets/animations/Employee.json'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import _ from "lodash"


export const FuturesOnboarding_EmploymentInfo_Form1 = () => {
    const {data, setValue} = useFuturesOnboardingStore()
    const text = useText(t => t.futuresOnboarding.employmentInfo)

    const handleClick = (key: string, value: string) => {
        if(_.get(useFuturesOnboardingStore.getState(), key) === value) {
            setValue(key, undefined)
        } else {
            setValue(key, value)
        }
    }
    const getErrors = (key: string) => {
        return getEmploymentInfoErrors()?.filter(e => e?.key?.toLowerCase() === key?.toLowerCase())
    }  

    return(
        <OnboardingLottieForm data={animationData} loop={false}>
            <Flex column center fullWidth style={{gap: '1rem'}}>
                <OnboardingButton 
                    icon={<AccountBalanceWalletIcon/>}
                    label={text.employedTitle} sublabel={text.employedSubtitle}
                    selected={data.employmentInfo?.status?.toLowerCase() === 'employed'} 
                    handleClick={() => handleClick('data.employmentInfo.status', 'employed')} 
                />
                <OnboardingButton 
                    icon={<AccountBalanceWalletIcon/>}
                    label={text.studentTitle} sublabel={text.studentSubtitle}
                    selected={data.employmentInfo?.status?.toLowerCase() === 'self-employed'} 
                    handleClick={() => handleClick('data.employmentInfo.status', 'self-employed')} 
                />
                <OnboardingButton  
                    icon={<AccountBalanceWalletIcon/>}
                    label={text.retiredTitle} sublabel={text.retiredSubtitle}
                    selected={data.employmentInfo?.status?.toLowerCase() === 'retired'} 
                    handleClick={() => handleClick('data.employmentInfo.status', 'retired')} 
                />
                 <OnboardingButton 
                    icon={<AccountBalanceWalletIcon/>}
                    label={text.unemployedTitle} sublabel={text.unemployedSubtitle}
                    selected={data.employmentInfo?.status?.toLowerCase() === 'other'} 
                    handleClick={() => handleClick('data.employmentInfo.status', 'other')} 
                />
                <OnboardingErrorRow errors={getErrors('data.employmentInfo.status')} />
            </Flex> 
        </OnboardingLottieForm>
    )
}