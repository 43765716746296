// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingStep, OnboardingButton, OnboardingScreen, NoValidation } from "../../../../../phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { ContactInfoIsValid } from "../ContactInfo/Validation"
import { FinancialInfo1IsValid, FinancialInfo2IsValid } from "../FinancialInfo/Validation"
import { PersonalInfoIsValid } from "../PersonalInfo/Validation"
import { FuturesOnboarding_EmploymentInfo_Form1 } from "./Form1"
import { FuturesOnboarding_EmploymentInfo_Form2 } from "./Form2"
import { EmploymentInfoIsValid, getEmploymentInfoErrors } from "./Validation"

export const futuresOnboarding_employmentInfo_step: OnboardingStep = {
    label: "Employment Info",
    isComplete: () => EmploymentInfoIsValid(),
    isEnabled: () => FinancialInfo1IsValid() && FinancialInfo2IsValid() && PersonalInfoIsValid() && ContactInfoIsValid() 
        && useFuturesOnboardingStore.getState().isWelcomed,
    isCurrent: () => ['employmentInfo, employmentInfo2'].includes(useFuturesOnboardingStore.getState().selectedScreenId),
    onClickGoToScreenId: 'employmentInfo'
}

export const futuresOnboarding_employmentInfo_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () => {
        if(useFuturesOnboardingStore.getState().data?.employmentInfo?.status.toLowerCase() === 'employed') {
            useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'employmentInfo2')
        } else {
            useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'tradingExperience')
        }
       
    },
    isPrimary: true,
    isEnabled: () => useFuturesOnboardingStore.getState().data?.employmentInfo?.status && FinancialInfo1IsValid() && FinancialInfo2IsValid() 
        && PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_employmentInfo_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'financialInfo2'),
    isPrimary: false,
    isEnabled: () => true
}

export const futuresOnboarding_employmentInfo2_nextButton: OnboardingButton = {
    label: "Next",
    onClick: () =>  useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'tradingExperience'),
    isPrimary: true,
    isEnabled: () => EmploymentInfoIsValid() && FinancialInfo1IsValid() && FinancialInfo2IsValid() 
        && PersonalInfoIsValid() && ContactInfoIsValid() && useFuturesOnboardingStore.getState().isWelcomed
}

export const futuresOnboarding_employmentInfo2_prevButton: OnboardingButton = {
    label: "Go Back",
    onClick: () => useFuturesOnboardingStore.getState().setValue('selectedScreenId', 'employmentInfo'),
    isPrimary: false,
    isEnabled: () => true
}

export const futuresOnboarding_employmentInfo_screen: OnboardingScreen = {
    id: "employmentInfo",
    validate: getEmploymentInfoErrors,
    buttons: [futuresOnboarding_employmentInfo_prevButton, futuresOnboarding_employmentInfo_nextButton],
    title: T(t => t.futuresOnboarding.employmentInfo.title),
    subtitles: null,
    body: <FuturesOnboarding_EmploymentInfo_Form1/>
}

export const futuresOnboarding_employmentInfo2_screen: OnboardingScreen = {
    id: "employmentInfo2",
    validate: getEmploymentInfoErrors,
    buttons: [futuresOnboarding_employmentInfo2_prevButton, futuresOnboarding_employmentInfo2_nextButton],
    title: T(t => t.futuresOnboarding.employmentInfo.title),
    subtitles: null,
    body: <FuturesOnboarding_EmploymentInfo_Form2/>
}