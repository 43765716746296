// @ts-strict-ignore
import { T } from "phoenix/assets/lang/T"
import React from "react"
import { OnboardingButton, OnboardingScreen } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"
import { getPersonalInfoErrors } from "../PersonalInfo/Validation"
import { FuturesOnboarding_Submitting_Form } from "./Form"


export const futuresOnboarding_submitting_screen: OnboardingScreen = {
    id: "submitting",
    validate: () => [],
    buttons: [],
    title: '',
    subtitles: null,
    body: <FuturesOnboarding_Submitting_Form/>
}