import React from 'react';
import { StandardQuote } from 'phoenix/constants/ReduxSelectors';
import { TradeTicketError } from './TradeTicketError';
import { Snex1LanguagePack } from 'phoenix/assets/lang/Snex1LanguagePack';
import { HasNumericValueGreaterThanZero } from 'util/Utils';
import { ApiTradeAction } from 'phoenix/models/ApiTradeRequest';
import { DisplayOrderType } from '../Store/TradeTicketViewModel';

interface ImmediateExecutionAdvisoryProps {
    action?: ApiTradeAction;
    limitPrice?: number;
    orderType?: DisplayOrderType;
    quote: StandardQuote;
    stopPrice?: number;
    text: Snex1LanguagePack['tradeTicket']['input']['advisories'];
}

export const ImmediateExecutionAdvisory = React.memo((props: ImmediateExecutionAdvisoryProps) => {
    const { orderType, limitPrice = 0, quote, stopPrice = 0, action, text } = props;
    const marketPrice = quote?.price || 0;
    const messages = text.immediateExecution;

    const isBuy = action === 'Buy';
    if (orderType === 'market') return null;
    else if (orderType === 'limit' && HasNumericValueGreaterThanZero(limitPrice)) {
        if (isBuy && limitPrice > marketPrice) return <TradeTicketError title={text.notice} message={messages.orderPriceError('limit', 'above-market')} />;
        if (!isBuy && limitPrice < marketPrice) return <TradeTicketError title={text.notice} message={messages.orderPriceError('limit', 'below-market')} />;
    } else if (orderType === 'stoplimit' && HasNumericValueGreaterThanZero(limitPrice)) {
        if (isBuy && limitPrice > stopPrice) return <TradeTicketError title={text.notice} message={messages.stopLimitPriceError('above-stop')} />;
        if (!isBuy && limitPrice < stopPrice) return <TradeTicketError title={text.notice} message={messages.stopLimitPriceError('below-stop')} />;
    }

    return null;
});
