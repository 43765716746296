// @ts-strict-ignore
import { FuturesSymbol } from 'phoenix/redux/models/Futures/FuturesSymbol';
import { SafeFormat } from '../../../util';
import { GlobalState } from '../../GlobalState';
import { OptionQuote } from './OptionQuote';
import { QualifiedId } from 'phoenix/util/QualifiedId';

const OsiSymbolPattern = /.{1,}\s*\d{6}[P|C]\d{8}/;

export type OptionSymbolFormat = 'TorchDescription' | 'OSI' | 'OCC' | 'Gain/CQG';

const getDetailsFromOsi = (src) => {
    const trimmed = src.replace(/\s+/g, '');
    if (!trimmed) return;

    const symLength = trimmed.length - 15;

    if (symLength <= 0) return;
    const [, year, month, day, pcIndicator, priceInt, priceDec] = trimmed.slice(symLength).match(/(\d{2})(\d{2})(\d{2})([P|C])(\d{5})(\d{3})/) || [];

    const result = {
        trimmed,
        symLength,
        expDate: new Date(+`20${year}`, month - 1, day), // new Date(`${month}/${day}/${year}`),
        putCall: pcIndicator === 'P' ? 'P' : ('C' as 'C' | 'P'),
        strike: parseFloat(`${priceInt}.${priceDec}`),
        strikeCents: parseInt(`${priceInt}${priceDec.slice(0, 2)}`)
    };

    return result;
};

export class OptionSymbol {
    /** @deprecated The EOSI spec is not designed specifically for CQG; what comes after the @ symbol is the generic upstream symbol */ cqgSymbol?: string;
    error?: string;
    expDate: Date;
    isOption: boolean;
    occSymbol: string;
    originalFormat: OptionSymbolFormat;
    originalSymbol: string;
    osiSymbol: string;
    putCall: 'P' | 'C' | null;
    strike: number;
    strikeCents: number;
    underlyingSymbol: string;
    underlyingSymbolWithPrefix?: string;
    upstreamSymbol: string;

    // Example (OSI):              TSLA210305C0050000 -> Tesla Call at $500.00 on March 5, 2021
    // Example (OCC):              TSLA  210305C0050000 -> Tesla Call at $500.00 on March 5, 2021
    // Example (Extended OSI):     F:ZCZ21211117C00005750@F:OZC3XC5.75 -> Corn Call at $5.75 on November 17, 2021 (with attached CQG symbology)
    // Example (Gain/CQG):         OZC3X C5.75 -> Corn Call at $5.75 on November 17, 2021 (Proposed deprecation: CQG symbols do not have enough reliable information to form an OSI on their own)
    // Example (Torch Desription): CALL TESLA $500 EXP 03/05/2021 -> Tesla Call at $500.00 on March 5, 2021 (DEPRECATED: Torch now provides OCC symbols)

    // TODO: Refactor to conform to linter (remove unused argument)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- see above
    constructor(src: string, underlyingSymbol: string = null, expirationDate: Date = null, formatHint: 'gain' | 'osi' | 'occ' | 'description' | undefined = undefined) {
        if (!src) return;

        const fromDescription = () => {
            const [original, putCallStr, strikeStr, dateStr] = src.match(/^([A-Za-z]+)\s.*\$([\d|.]+)\sEXP\s([\d|/]+)$/);
            this.originalFormat = 'TorchDescription';
            this.underlyingSymbol = underlyingSymbol;

            // TODO: This is a hack to get around the fact that native mobile throws an error when creating a date with a string like '03/05/2021'
            const splitDate = dateStr.split('/');
            const normalizedDate = new Date();
            normalizedDate.setFullYear(Number(splitDate[2]), Number(splitDate[0]) - 1, Number(splitDate[1]));
            this.expDate = normalizedDate;
            this.putCall = (() => {
                switch (putCallStr) {
                    case 'CALL':
                        return 'C';
                    case 'PUT':
                        return 'P';
                    default:
                        return null;
                }
            })();
            this.isOption = this.putCall !== null;
            this.strike = Number(strikeStr);
            this.strikeCents = this.strike * 100;
            this.originalSymbol = original;
        };

        const fromOsiSymbol = () => {
            this.isOption = false;
            this.originalFormat = 'OSI';

            const { trimmed, symLength, expDate, putCall, strike, strikeCents } = getDetailsFromOsi(src);

            if (!trimmed) return;
            const underlyingRaw = trimmed.substr(0, symLength)
            this.originalSymbol = this.originalSymbol || trimmed;
            this.underlyingSymbol = QualifiedId.RemovePrefix(underlyingRaw);
            this.underlyingSymbolWithPrefix = underlyingRaw;
            this.expDate = expDate;
            this.putCall = putCall;
            this.strike = strike;
            this.strikeCents = strikeCents;
            this.isOption = true;
        };

        const fromEosiSymbol = () => {
            const atIdx = src.indexOf('@');
            if (atIdx > 10) {
                const osi = src.substr(0, atIdx);
                const ext = src.substr(atIdx + 1);
                const original = src
                src = osi;
                fromOsiSymbol();
                this.originalSymbol = original;
                this.upstreamSymbol = ext;

                // For backwards compatibility
                if (FuturesSymbol.IsFuturesSymbol(src)) {
                    this.cqgSymbol = ext;
                    this.upstreamSymbol = original;
                }

            } else fromOsiSymbol();
        };

        const fromOccSymbol = () => {
            this.originalSymbol = src;
            this.originalFormat = 'OCC';
            fromEosiSymbol();
        };

        try {
            switch (true) {
                case !src:
                    return;
                case /^(CALL|PUT)\s/.test(src):
                case formatHint === 'description':
                    fromDescription();
                    break;
                case formatHint === 'gain':
                case src.includes('@'):
                    fromEosiSymbol();
                    return;
                case ['occ', 'osi'].includes(formatHint):
                default:
                    fromOccSymbol();
            }

            this.osiSymbol = `${this.underlyingSymbol?.trim()}${SafeFormat(this.expDate, 'yyMMdd')}${this.putCall}${`0000000${this.strikeCents}`.slice(-7)}0`;
            if (this.upstreamSymbol) this.osiSymbol += `@${this.upstreamSymbol}`;
            this.occSymbol = `${this.underlyingSymbol}${SafeFormat(this.expDate, 'yyMMdd')}${this.putCall}${`0000000${this.strikeCents}`.slice(-7)}0`;
        } catch (e) {
            this.error = `Fatal: ${e} - ${this}`;
        }
    }

    /** @deprecated Please use SnexLanguagePack.general.options.putCallWord */
    putCallWord = () => {
        switch (this.putCall) {
            case 'C':
                return 'Call';
            case 'P':
                return 'Put';
            default:
                return 'Unknown';
        }
    };

    toOsiSymbol = () => this.osiSymbol;
    toOccSymbol = () => this.occSymbol;

    // For sending straight to Gain LS
    toGain = (separator = ' ') => this.cqgSymbol?.replace(' ', separator);

    selectQuote = (s: GlobalState): OptionQuote => {
        const lookup = this.originalFormat === 'Gain/CQG' ? this.originalSymbol : this.toOsiSymbol();
        return s.options.quotesByOsi[lookup]?.data;
    };

    static IsOptionSymbol = (src: string) => OsiSymbolPattern.test(src);
}
