// @ts-strict-ignore
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from '../..';
import { Routes } from 'util/Routes';
import { GetSecurityTypeFromProductType, TradeableSecurityType } from 'phoenix/util';
import { EquityPositionCell } from './EquityPositionCell';
import { FundPositionCell } from './FundPositionCell';
import { OptionPositionCell } from './OptionPositionCell';
import style from '../style.module.scss';
import { ApiPosition } from 'phoenix/redux/models';
import { GetVariant } from 'phoenix/util/Variant';
import { FuturePositionCell } from './FuturePositionCell';
import { useTelemetry } from 'hooks/UseTelemetry';
import { SetSelectedAccount } from 'components/AccountDropdown/Store/AccountSelectionStore';
import { CryptoPositionCell } from './CryptoPositionCell';
import AlgoliaHelper from 'phoenix/util/AlgoliaHelper';

export interface PositionCellProps {
    getCached?: boolean;
    hideAccountName?: boolean;
    position: ApiPosition;
    secType?: TradeableSecurityType;
    streamingNamespace?: string;
    symbol: string;
    visible?: boolean;
}

export const PositionCell = React.memo((props: PositionCellProps) => {
    const LogEvent = useTelemetry();
    const { hideAccountName, position, symbol } = props;
    const { canViewOptionContract = true } = GetVariant();
    const type = useMemo(() => GetSecurityTypeFromProductType(position.productType), [position]);

    const retrieveRoute = useMemo(() => {
        if (type === 'option' && !canViewOptionContract) return Routes.positions();
        else if (position.accountNumber) return Routes.position(symbol);
        else return Routes.security(symbol);
    }, [canViewOptionContract, position.accountNumber, symbol, type]);

    const handleNavigateToSecurityView = useCallback(() => {
        if (symbol) AlgoliaHelper.Report.ClickedSecurity(symbol);
        if (position.accountNumber) {
            SetSelectedAccount(position.accountNumber);
            LogEvent('Position cell click', { symbol, 'product type': position.productType });
        } else {
            LogEvent('Security cell click', { symbol, 'product type': position.productType });
        }
    }, [LogEvent, position.accountNumber, position.productType, symbol]);

    return (
        <Link to={retrieveRoute}>
            <Flex row className={style.wrapper} onClick={handleNavigateToSecurityView}>
                <Flex row className={style.innerWrapper} style={{ minHeight: hideAccountName ? 60 : 84 }}>
                    <Content {...props} secType={type} />
                    {/* This is here to maintain consistency with the watchlist cell */}
                    <Flex align='center' className={style.deleteAction} justify='flex-end'></Flex>
                </Flex>
            </Flex>
        </Link>
    );
});

export const Content = React.memo((props: PositionCellProps) => {
    const { getCached, streamingNamespace, secType, position, hideAccountName, symbol, visible } = props;
    const commonProps = { getCached, streamingNamespace, position, hideAccountName, symbol, visible };
    switch (secType) {
        case 'crypto':
            return <CryptoPositionCell {...commonProps} />;
        case 'option':
            return <OptionPositionCell {...commonProps} />;
        case 'mutual-fund':
            return <FundPositionCell {...commonProps} />;
        case 'future':
            return <FuturePositionCell {...commonProps} />;
        case 'equity':
        case 'adr':
        case 'unknown':
        default:
            return <EquityPositionCell {...commonProps} />;
    }
});
