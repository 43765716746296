import React from 'react'
import Lottie from 'lottie-react'
import animationData from 'phoenix/assets/animations/StoneXLoadingCircle.json';

export const LoadingSpinner = (props: { size?: number; style?: React.CSSProperties }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <div style={props.style}>
            <Lottie
                {...defaultOptions}
                style={{
                    height: props.size || 100,
                    width: props.size || 100
                }}
            />
        </div>
    )
}
