import { OnboardingInputError, ValidateRequired } from "phoenix/onboarding/OnboardingTypes"
import { useFuturesOnboardingStore } from "../../Store"

export const FinancialInfo1IsValid = () => getFinancialInfo1Errors()?.length < 1
export const getFinancialInfo1Errors = () => {
    const keys = [ 
        'data.accountInfo.sourceOfFunds',
        'data.accountInfo.intendedInitialDeposit',
        'data.accountInfo.expectedMonthlyTrading'
    ]
    const store = useFuturesOnboardingStore.getState()
    const _errors = []  as OnboardingInputError[]
    keys.forEach(key => {
        switch(key) {
            case 'data.accountInfo.sourceOfFunds':
            case 'data.accountInfo.intendedInitialDeposit':
            case 'data.accountInfo.expectedMonthlyTrading':
                _errors.push(ValidateRequired(store, key))
                break
    }})
    return _errors.filter(e => !!e);
}

export const FinancialInfo2IsValid = () => getFinancialInfo2Errors()?.length < 1
export const getFinancialInfo2Errors = () => {
    const keys = [ 
        'data.financialInfo.annualRevenue',
        'data.financialInfo.netWorth',
        'data.financialInfo.workingCapital'
    ]
    const store = useFuturesOnboardingStore.getState()
    const _errors = []  as OnboardingInputError[]
    keys.forEach(key => {
        switch(key) {
            case 'data.financialInfo.annualRevenue':
            case 'data.financialInfo.netWorth':
            case 'data.financialInfo.workingCapital':
                _errors.push(ValidateRequired(store, key))
                break
    }})
    const data = store.data.financialInfo || {}
    if([data?.annualRevenue, data?.netWorth, data?.workingCapital].every(x => x === '0'))
    {
        _errors.push({key: 'data.financialInfo.annualRevenue', error: 'Financials do not meet requirements for a futures account'})
        _errors.push({key: 'data.financialInfo.netWorth', error: 'Financials do not meet requirements for a futures account'})
        _errors.push({key: 'data.financialInfo.workingCapital', error: 'Financials do not meet requirements for a futures account'})
    }
    return _errors.filter(e => !!e);
}