import { Checkbox, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Flex } from 'components';
import { useText } from 'phoenix/hooks/UseText';
import React, { useEffect, useMemo } from 'react';
import { useFuturesOnboardingStore } from '../../Store';
import { useColors } from 'hooks/UseColors';
import { FormattedTextDisplay } from 'components/FormattedTextDisplay/FormattedTextDisplay';
import { OnboardingYesNo } from 'screens/OnboardingScreens/Base/OnboardingControls';
import { OnboardingInputRow } from 'components/Onboarding';
import { getCertificationsErrors } from './Validation';

export const FuturesOnboarding_Certifications_Form = () => {
    const { data, setValue, requiredCerts, clickedCerts, acceptArbitration } = useFuturesOnboardingStore();
    const text = useText((t) => t.futuresOnboarding);
    const agreements = text?.agreements;
    const ssn = useMemo(() => data?.personalInfo?.ssn || 'XXXX', [data]);
    const maskedSsn = useMemo(() => `••• - •• - ${ssn.substring(ssn.length - 4)}`, [ssn]);
    const colors = useColors();
    const agreementRows = [
        {
            label: agreements.clientAcknowledgementItem1,
            field: 'acceptPrivacyStatements',
            docs: [{ text: text.documents.privacyPolicy, url: text.documents.blobStorageUrls.privacyPolicies }]
        },
        {
            label: agreements.clientAcknowledgementItem2,
            field: 'acceptRiskDisclosureStatements',
            docs: [
                { text: text.documents.commoditiesFuturesRiskDisclosure, url: text.documents.blobStorageUrls.commodityFuturesTradingComissionRiskDisclosure },
                { text: text.documents.commoditiesOptionsRiskDisclosure, url: text.documents.blobStorageUrls.optionsDisclosureStatement },
                {
                    text: text.documents.addlDisclosures,
                    url: text.documents.blobStorageUrls.acknowledgementOfAdditionalDisclosures,
                    subText: [
                        text.documents.subText.nfaRiskDisclosure,
                        text.documents.subText.addlRiskDisclosure,
                        text.documents.subText.materialConflicts,
                        text.documents.subText.electronicTrading,
                        text.documents.subText.noticeToOmnibus,
                        text.documents.subText.underlyingOrSpot,
                        text.documents.subText.clearedSwaps
                    ]
                },
                {
                    text: text.documents.virtualCurrencyRiskDisclosure,
                    url: text.documents.blobStorageUrls.virtualCurrencyRiskStatements
                }
            ]
        },
        {
            label: agreements.clientAcknowledgementItem3,
            field: 'acceptFuturesCustomerAgreements',
            docs: [
                {
                    text: text.documents.stoneXCustomerAgreement,
                    url: text.documents.blobStorageUrls.futuresAndExchangeTradedOptionsCustomerAgreement
                }
            ]
        },
        {
            label: agreements.clientAcknowledgementItem4,
            field: 'acceptPlatformUserAgreement',
            docs: [{ text: text.documents.platformAgreement, url: text.documents.blobStorageUrls.platformUserAgreement }]
        },
        { label: agreements.clientAcknowledgementItem6, field: 'acceptAchAgreement', docs: [] },
        {
            label: agreements.clientAcknowledgementItem7,
            field: 'acceptMarketDataSubscriptionTerms',
            docs: [{ text: text.documents.marketDataSubscriptionTerms, url: text.documents.blobStorageUrls.futuresMarketDataSubscriptionTerms }]
        }
    ];

    useEffect(() => {
        if (!requiredCerts.length) {
            const _requiredCerts = agreementRows.map((r) => r.field);
            setValue('requiredCerts', _requiredCerts);
        }
    }, [requiredCerts]);

    const handlClick = (field: string) => {
        if (!clickedCerts.includes(field)) {
            setValue('clickedCerts', [...clickedCerts, field]);
        } else {
            setValue(
                'clickedCerts',
                clickedCerts.filter((x) => x !== field)
            );
        }
    };

    const handleArbitrationClick = (val?: boolean) => {
        setValue('acceptArbitration', val);
    };

    return (
        <Flex column style={{ padding: '1rem', marginBottom: '2rem' }}>
            <Flex row style={{ marginBottom: '1rem' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: '14px' }}>{agreements.acceptAndContinueText}</Typography>
            </Flex>
            <Flex row style={{ marginBottom: '1rem' }}>
                <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                    {agreements.W9title}
                </Typography>
            </Flex>
            <Flex row>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                    {agreements.perjuryCertTitle}
                </Typography>
            </Flex>
            <Flex column style={{ marginBottom: '1rem' }}>
                <ul>
                    <li style={{ marginBottom: '.5rem' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: 400 }}>{agreements.perjuryCertItem1}</Typography>
                    </li>
                    <li style={{ marginBottom: '.5rem' }}>
                        <Flex row>
                            <Typography style={{ fontSize: '14px', fontWeight: 400, marginRight: '.5rem' }}>{maskedSsn}</Typography>
                            <Typography style={{ fontSize: '14px', fontWeight: 400 }}>{agreements.perjuryCertItem2}</Typography>
                        </Flex>
                    </li>
                    <li style={{ marginBottom: '.5rem' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: 400 }}>{agreements.perjuryCertItem3}</Typography>
                    </li>
                    <li style={{ marginBottom: '.5rem' }}>
                        <Typography style={{ fontSize: '14px', fontWeight: 400 }}>{agreements.perjuryCertItem4}</Typography>
                    </li>
                </ul>
            </Flex>
            <Flex row>
                <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                    {agreements.clientAcknowledgementTitle}
                </Typography>
            </Flex>
            <Flex column style={{ marginBottom: '1rem' }}>
                <Table>
                    <TableBody>
                        {agreementRows.map((r, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ borderBottom: 'none', verticalAlign: 'top' }}>
                                    <IconButton onClick={() => handlClick(r.field)}>
                                        <Checkbox
                                            checked={clickedCerts.includes(r.field)}
                                            style={{
                                                cursor: 'pointer',
                                                color: clickedCerts.includes(r.field) ? colors.green : colors.red,
                                                padding: 0
                                            }}
                                        />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    <FormattedTextDisplay text={r.label} />
                                    {r.docs.map((d, dIndex) => (
                                        <Flex
                                            column
                                            onClick={() => window.open(d.url, '_blank')}
                                            style={{ marginBottom: '.5rem', color: colors.selectListSelectedItemColor }}
                                        >
                                            <Typography key={`${r.field}_${dIndex}`} variant='h6'>
                                                {d.text}
                                            </Typography>
                                            {d.subText?.map((s, sIndex) => (
                                                <Typography key={`${r.field}_${dIndex}_${sIndex}`} variant='body1' style={{ marginLeft: '1rem' }}>
                                                    {s}
                                                </Typography>
                                            ))}
                                        </Flex>
                                    ))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Flex row center align='center' style={{ gap: '1rem', marginTop: '1rem' }}>
                    <FormattedTextDisplay text={agreements.clientAcknowledgementItem5} />
                </Flex>
                <Flex row align='center' style={{ gap: '1rem' }}>
                    <Typography
                        variant='h6'
                        style={{ cursor: 'pointer', color: colors.primaryItemColor }}
                        onClick={() => window.open(text.documents.blobStorageUrls.arbitrationAgreement, '_blank')}
                    >
                        {text.documents.arbitrationAgreement}
                    </Typography>
                </Flex>
                <OnboardingInputRow>
                    <OnboardingYesNo
                        question={text.agreements.arbitrationAcceptText}
                        value={acceptArbitration}
                        errors={getCertificationsErrors()?.filter((e) => e.key === 'arbitrationAcceptance')}
                        handleChange={handleArbitrationClick}
                    />
                </OnboardingInputRow>
                <Flex row style={{ marginTop: '1rem', border: `1px solid ${colors.generalTextColor}`, padding: '.5rem' }}>
                    <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>{agreements.IRSnote}</Typography>
                </Flex>
            </Flex>
        </Flex>
    );
};
