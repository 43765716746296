// @ts-strict-ignore
import { ErrorLogger } from "phoenix/util/ErrorLogger";

const SetOnboardingEventMeta = (context?: { [key: string]: string }, tags?: { [key: string]: string }, status?: string): { info: { [key: string]: string }, tags: { [key: string]: string } } => {
    const updatedTags = tags;
    if (status) updatedTags.status = status

    return { tags: updatedTags, info: context }
}

export const LogOnboardingStarted = async (customTags?: { [key: string]: any }, context?: { [key: string]: any }) => {
    try {
        const loggingExtras = SetOnboardingEventMeta(context, customTags, 'start')
        ErrorLogger.RecordMessage("Started onboarding application", "Started onboarding", loggingExtras)
        ErrorLogger.ClearScope()
    } catch (error) {
        ErrorLogger.RecordError(error)
        ErrorLogger.ClearScope()
    }
}

export const LogOnboardingError = (error: Error, customTags?: { [key: string]: any }, context?: { [key: string]: any }) => {
    try {
        if (error) {
            const { tags, info } = SetOnboardingEventMeta(context, customTags, 'failed')
            ErrorLogger.RecordError(error, 'Onboarding Error', { tags, info })
            ErrorLogger.ClearScope()
        }
    } catch (error) {
        ErrorLogger.RecordError(error)
        ErrorLogger.ClearScope()
    }
}


export const LogOnboardingSuccess = async (customTags?: { [key: string]: any }, context?: { [key: string]: any }) => {
    try {
        const loggingExtras = SetOnboardingEventMeta(context, customTags, 'success')
        ErrorLogger.RecordMessage("Completed onboarding successfully", 'Completed Onboarding', loggingExtras)
        ErrorLogger.ClearScope()
    } catch (error) {
        ErrorLogger.RecordError(error)
        ErrorLogger.ClearScope()
    }
}

export const LogOnboardingEvent = (message: string, customTags?: { [key: string]: any }, context?: { [key: string]: any }) => {
    try {
        const loggingExtras = SetOnboardingEventMeta(context, customTags)
        ErrorLogger.RecordMessage(message, 'Onboarding Event', loggingExtras)
        ErrorLogger.ClearScope()
    } catch (error) {
        ErrorLogger.RecordError(error)
        ErrorLogger.ClearScope()
    }
}