// @ts-strict-ignore
import { Checkbox, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Spinny } from '../..';
import { AddToWatchlistAction, GetWatchlistsAction, RemoveFromWatchlistAction } from 'phoenix/redux/actions';
import { GlobalState } from 'phoenix/redux/GlobalState';
import { Watchlist } from 'phoenix/redux/models';
import { useText } from 'phoenix/hooks/UseText';
import { AddWatchlist } from '../../AddWatchlist';
import { CulledCollapse } from '../../CulledCollapse/CulledCollapse';
import { LoadingSpinner } from '../../LottieAnimations';
import style from './style.module.scss';

const WatchlistCheckbox = ({ isSelected = false }: { isSelected?: boolean }) => {
    return (
        <Flex align='center' justify='center' style={{ margin: '0 15px 0 8px', minHeight: 24, minWidth: 24 }}>
            <Checkbox checked={ isSelected } style={{ padding: 0 }} />
        </Flex>
    );
};

const WatchlistOption = React.memo((props: { symbol: string, watchlist: Watchlist }) => {

    const dispatch = useDispatch();
    const { symbol, watchlist } = props;
    const [ selected, setSelected ] = useState(false);

    useEffect(() => {
        setSelected(watchlist.securities.some(r => r.symbol === symbol)); 
    }, [ symbol, watchlist ]);

    const onSelect = async () => {
        const action = selected ? 'remove' : 'add';
        setSelected(!selected);

        // @ts-ignore
        const result: any = (action === 'remove')
            ? await dispatch(RemoveFromWatchlistAction(watchlist.id, symbol))
            : await dispatch(AddToWatchlistAction(watchlist.id, symbol));
        
        if (result.failed) setSelected(action !== 'remove');
    };

    return (
        <Flex align='center' className={ style.row } justify='flex-start' style={{ width: '100%', padding: '10px 5px' }} onClick={onSelect}>
            <WatchlistCheckbox isSelected={ selected } />
            <Typography variant='h6'>{ props.watchlist.name }</Typography>
        </Flex>
    );
});

export const AddToListModal = React.memo((props: { symbol: string }) => {

    const dispatch = useDispatch();
    const [ addListOpen, setAddListOpen ] = useState(false);

    const lists = useSelector((s: GlobalState) => s.watchlists.all);
    const text = useText(s => s.modals.addToWatchlist);

    useEffect(() => {
        dispatch(GetWatchlistsAction()); 
    }, []);

    return (
        <Flex column style={{ height: '100%', boxSizing: 'border-box', marginBottom: 15 }}>
            <Flex column style={{ width: '100%', marginBottom: 15, padding: '0px' }}>
                <Flex row justify='space-between' style={{ borderBottom: 'solid 1px rgba(0,0,0,0.05)', paddingBottom: 10 }} onClick={ () => setAddListOpen(!addListOpen) }>
                    <Typography variant='h4'>{ text.createAList }</Typography>
                    <Spinny spun={ addListOpen } targetDegrees={ 45 }><Add /></Spinny>
                </Flex>
                <CulledCollapse eventTag='Create Watchlist' in={ addListOpen }>
                    <AddWatchlist secondary />
                </CulledCollapse>
            </Flex>
            { lists.data.length
                ? (<Flex column> {lists.data.map((w, key) => (<WatchlistOption key={key} symbol={props.symbol} watchlist={w} />))} </Flex>)
                : (<Flex center column style={{ height: 200 }}> <LoadingSpinner /> </Flex>
                )}
        </Flex>
    );
});