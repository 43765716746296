// @ts-strict-ignore
import { XS } from 'phoenix/xstream/XS';
import { useMemo } from 'react';
import { getFuturesTotalProfitLoss } from 'phoenix/util/CalculationHelpers';
import { GetMarketTime, QuoteAttribute, Sum } from '.';
import { GetConfig } from '../constants';
import { QuoteSelector } from '../constants/ReduxSelectors';
import { useSnexStore } from '../hooks/UseSnexStore';
import { QualifiedSecurityId } from '../models/QualifiedSecurityId';
import { ApiPosition, OptionSymbol, SecurityQuote, TaxlotItem } from '../redux/models';
import { usePositionsStore } from 'phoenix/stores/PositionsStore';
import { GetAssetClassForSecurity } from 'phoenix/models/AssetClasses/useAssetClass';
import { SecurityMetadata } from 'phoenix/redux/models/Securities/SecurityMetadata';
import { useSecurityMetadataV2 } from 'phoenix/stores/SecurityMetadataV2Store';

// const DEBUG_SYMBOL = 'F:ZCK23'
const DEBUG_SYMBOL = null;

export type GainLossFigures = {
    glToday?: number;
    glTodayPercent?: number;
    glTotal?: number;
    glTotalPercent?: number;
    price?: number;
    change?: number;
    changePercent?: number;
    costBasis?: number;
    avgCost?: number;
    avgELotCost?: number;
    gainLossBasis?: number;
    marketValue?: number;
    quantity?: number;
    lotQuantity?: number;
    hasPosition?: boolean;
    lots?: TaxlotItem[];
};

/** @deprecated Use v2 for SecurityMetadataV2 */
export const useGainLossFigures = (
    qsi: string,
    accountNumber?: string,
    options?: Partial<{
        longness: 'short' | 'long';
        positions: ApiPosition[];
    }>
): {
    figures: GainLossFigures;
    loading: boolean;
    lotsLoading: boolean;
    positionsLoading: boolean;
    quoteLoading: boolean;
} => {
    const storePositions = usePositionsStore((s) => s.positions);
    let positions = options.positions || storePositions;
    if (options?.longness === 'short') positions = positions?.filter((p) => !!p.quantitySummary?.short);
    if (options?.longness === 'long') positions = positions?.filter((p) => !p.quantitySummary?.short);
    if (accountNumber) positions = positions?.filter((p) => p.accountNumber === accountNumber);

    const meta = useSnexStore((s) => s.securities.bySymbol[qsi]?.metadata?.data);

    const first: ApiPosition | null = positions?.length ? positions[0] : null;

    const secId = useMemo(() => {
        if (!first) return QualifiedSecurityId.Unkown();
        const optSym = new OptionSymbol(first?.description, first?.symbol);
        return optSym.isOption ? QualifiedSecurityId.FromRaw('OptionSymbol', optSym.osiSymbol) : QualifiedSecurityId.FromPosition(first);
    }, [qsi, first?.description, first?.symbol]);

    const quote = XS.Quotes.use(qsi);

    const lots = useSnexStore((s) => {
        let l = s.taxlots.openTaxlots.data?.taxlGroupItems.filter((i) => secId.MatchesTaxLot(i)).flatMap((i) => i.items) || [];
        if (accountNumber) l = l.filter((i) => i.accountNumber === accountNumber) || [];

        if (options?.longness === 'short') l = l.filter((i) => i.quantity < 0);
        else if (options?.longness === 'long') l = l.filter((i) => i.quantity > 0);

        return l;
    });

    const lotsLoading = useSnexStore((s) => s.taxlots.openTaxlots.loading);
    const positionsLoading = usePositionsStore((s) => s.isLoading);
    const loading = useMemo(() => positionsLoading || lotsLoading, [positionsLoading, lotsLoading]);

    return useMemo(() => {
        return {
            figures: GetGainLossFiguresAll(positions, quote, lots, meta?.unitFactor),
            loading,
            lotsLoading,
            positionsLoading,
            quoteLoading: quote?.loading || false
        };
    }, [positions, quote, lots, meta?.unitFactor, loading, lotsLoading, positionsLoading]);
};

export const useGainLossFiguresV2 = (
    qsi: string,
    accountNumber?: string,
    options?: Partial<{
        longness: 'short' | 'long';
        positions: ApiPosition[];
    }>
): {
    figures: GainLossFigures;
    loading: boolean;
    lotsLoading: boolean;
    positionsLoading: boolean;
    quoteLoading: boolean;
} => {
    const storePositions = usePositionsStore((s) => s.positions);
    let positions = options.positions || storePositions;
    if (options?.longness === 'short') positions = positions?.filter((p) => !!p.quantitySummary?.short);
    if (options?.longness === 'long') positions = positions?.filter((p) => !p.quantitySummary?.short);
    if (accountNumber) positions = positions?.filter((p) => p.accountNumber === accountNumber);

    const meta = useSecurityMetadataV2().getMetadataBySymbol(qsi);

    const first: ApiPosition | null = positions?.length ? positions[0] : null;

    const secId = useMemo(() => {
        if (!first) return QualifiedSecurityId.Unkown();
        const optSym = new OptionSymbol(first?.description, first?.symbol);
        return optSym.isOption ? QualifiedSecurityId.FromRaw('OptionSymbol', optSym.osiSymbol) : QualifiedSecurityId.FromPosition(first);
    }, [qsi, first?.description, first?.symbol]);

    const quote = XS.Quotes.use(qsi);

    const lots = useSnexStore((s) => {
        let l = s.taxlots.openTaxlots.data?.taxlGroupItems.filter((i) => secId.MatchesTaxLot(i)).flatMap((i) => i.items) || [];
        if (accountNumber) l = l.filter((i) => i.accountNumber === accountNumber) || [];

        if (options?.longness === 'short') l = l.filter((i) => i.quantity < 0);
        else if (options?.longness === 'long') l = l.filter((i) => i.quantity > 0);

        return l;
    });

    const lotsLoading = useSnexStore((s) => s.taxlots.openTaxlots.loading);
    const positionsLoading = usePositionsStore((s) => s.isLoading);
    const loading = useMemo(() => positionsLoading || lotsLoading, [positionsLoading, lotsLoading]);

    return useMemo(() => {
        return {
            figures: GetGainLossFiguresAllV2(positions, quote, lots, meta?.sizing?.multiplier),
            loading,
            lotsLoading,
            positionsLoading,
            quoteLoading: quote?.loading || false
        };
    }, [positions, quote, lots, meta?.sizing?.multiplier, loading, lotsLoading, positionsLoading]);
};

export const GetGainLossFigures = (position: ApiPosition, quote?: SecurityQuote, lots?: TaxlotItem[], futuresContractSize?: number): GainLossFigures =>
    GetGainLossFiguresAll([position], quote, lots, futuresContractSize);

export const GetGainLossFiguresV2 = (position: ApiPosition, quote?: SecurityQuote, lots?: TaxlotItem[], futuresContractSize?: number): GainLossFigures =>
    GetGainLossFiguresAllV2([position], quote, lots, futuresContractSize);

export const GetGainLossFiguresAll = (positions: ApiPosition[], quote?: SecurityQuote, lots?: TaxlotItem[], futuresContractSize?: number): GainLossFigures => {
    if (!positions?.length) return { hasPosition: false };
    const first = positions[0];
    if (!first) return { hasPosition: false };
    const assetClass = GetAssetClassForSecurity(first?.secMasterOptionSymbol || first?.symbol);
    const secId = QualifiedSecurityId.FromPosition(first);
    const eQuote = quote || QuoteSelector(secId.id)(GetConfig().Store.getState());
    const isFixedIncome = first.productType === 'Fixed Income';

    // NOTE: This is a hotfix solution, please remove this once the API is updated to return the expected options average cost.
    const secMeta = GetConfig().Store.getState().securities.bySymbol[secId.id]?.metadata?.data as SecurityMetadata;

    if (assetClass.family === 'futures') return GetGainLossFiguresAllFutures(positions, eQuote, futuresContractSize);

    const eLots =
        lots ||
        GetConfig()
            .Store.getState()
            .taxlots.openTaxlots.data?.taxlGroupItems.filter((i) => secId.MatchesTaxLot(i))
            .flatMap((i) => i.items)
            .filter((i) => i.accountNumber === first.accountNumber) || // <-- This has gotta be wrong, what if `positions` is for multiple accounts?
        [];

    const psum = (selector: (p: ApiPosition) => number) => Sum(positions?.map(selector));

    const costBasis = Sum(positions.map((p) => p.costBasis || 0));
    const lotQuantity = Sum(eLots.map((p) => p.quantity));
    const isShort = Sum(eLots.map((p) => p.quantity)) < 0;
    const quantity = psum((p) => p.quantity || 0);
    let avgCost = psum((p) => p.averageUnitCost);
    if (first.optionSymbol) {
        avgCost = avgCost / secMeta?.unitFactor;
    }
    const optionPrice = isShort ? eQuote?.ask : eQuote?.bid;
    const price = assetClass.type === 'option' ? optionPrice : eQuote?.price || first.marketPrice || first.lastPrice;
    const change = eQuote?.change;
    const changePercent = (eQuote?.changePercent || change / price) * 100;

    const unitQuantity = first.unitQuantity || 1;
    const previousClose = eQuote?.previousClose || eQuote?.close || first.previousClosing;
    const basisMarketValue = (previousClose * lotQuantity) / unitQuantity;
    const taxlotMarketValue = lotQuantity ? (lotQuantity * price) / unitQuantity : 0;
    const fixedIncomeMarketValue = psum((p) => p.marketUsdeValue);
    const nonFixedMarketValue = quantity ? (quantity * price) / unitQuantity : psum((p) => p.marketValue);
    const currentMarketValue = isFixedIncome ? fixedIncomeMarketValue : nonFixedMarketValue;

    const isNonMarketHours = GetMarketTime() !== 'open';

    // Will not be maintained for cryptos, as this is inaccurate. Will be replaced with a more accurate calculation in the future.
    const glToday = (() => {
        if (['mutual-fund', 'crypto'].includes(assetClass.type)) return NaN;
        if (!isNonMarketHours || !eQuote?.change || !quantity) return taxlotMarketValue ? taxlotMarketValue - basisMarketValue : 0;
        return lotQuantity ? (eQuote?.change * lotQuantity) / unitQuantity : 0;
    })();

    const glTodayPercent = (() => {
        if (['mutual-fund', 'crypto'].includes(assetClass.type)) return NaN;
        if (!isNonMarketHours || !eQuote?.changePercent) {
            return Math.abs(glToday / basisMarketValue) * (glToday >= 0 ? 1 : -1);
        }

        return eQuote?.changePercent;
    })();

    const fixedIncomeGlTotal = currentMarketValue - costBasis;
    const marketValueCalc = assetClass.family === 'cryptos' ? currentMarketValue : taxlotMarketValue;
    const nonFixedGlTotal = costBasis ? (isShort ? costBasis - Math.abs(marketValueCalc) : marketValueCalc - costBasis) : NaN;
    const glTotal = isFixedIncome ? fixedIncomeGlTotal : nonFixedGlTotal;

    const glTotalPercent = (() => {
        return Math.abs(glTotal / costBasis) * (glTotal >= 0 ? 1 : -1);
    })();

    const avgELotCost = lotQuantity ? Math.abs((costBasis / lotQuantity) * unitQuantity) : 0;
    const result = {
        glToday,
        glTodayPercent,
        glTotal,
        glTotalPercent,
        price,
        costBasis,
        avgCost,
        avgELotCost,
        gainLossBasis: basisMarketValue,
        marketValue: currentMarketValue,
        lotQuantity,
        quantity,
        change,
        changePercent,
        hasPosition: true
        // lots: eLots
    };

    return result;
};

export const GetGainLossFiguresAllV2 = (positions: ApiPosition[], quote?: SecurityQuote, lots?: TaxlotItem[], futuresContractSize?: number): GainLossFigures => {
    if (!positions?.length) return { hasPosition: false };
    const first = positions[0];
    if (!first) return { hasPosition: false };
    const assetClass = GetAssetClassForSecurity(first?.secMasterOptionSymbol || first?.symbol);
    const secId = QualifiedSecurityId.FromPosition(first);
    const eQuote = quote || QuoteSelector(secId.id)(GetConfig().Store.getState());
    const isFixedIncome = first.productType === 'Fixed Income';

    // NOTE: This is a hotfix solution, please remove this once the API is updated to return the expected options average cost.
    const secMeta = useSecurityMetadataV2.getState().getMetadataBySymbol(secId?.id);

    if (assetClass.family === 'futures') return GetGainLossFiguresAllFutures(positions, eQuote, futuresContractSize);

    const eLots =
        lots ||
        GetConfig()
            .Store.getState()
            .taxlots.openTaxlots.data?.taxlGroupItems.filter((i) => secId.MatchesTaxLot(i))
            .flatMap((i) => i.items)
            .filter((i) => i.accountNumber === first.accountNumber) || // <-- This has gotta be wrong, what if `positions` is for multiple accounts?
        [];

    const psum = (selector: (p: ApiPosition) => number) => Sum(positions?.map(selector));

    const costBasis = Sum(positions.map((p) => p.costBasis || 0));
    const lotQuantity = Sum(eLots.map((p) => p.quantity));
    const isShort = Sum(eLots.map((p) => p.quantity)) < 0;
    const quantity = psum((p) => p.quantity || 0);
    let avgCost = psum((p) => p.averageUnitCost);
    if (first.optionSymbol) {
        avgCost = avgCost / secMeta?.sizing?.multiplier;
    }
    const optionPrice = isShort ? eQuote?.ask : eQuote?.bid;
    const price = assetClass.type === 'option' ? optionPrice : eQuote?.price || first.marketPrice || first.lastPrice;
    const change = eQuote?.change;
    const changePercent = (eQuote?.changePercent || change / price) * 100;

    const unitQuantity = first.unitQuantity || 1;
    const previousClose = eQuote?.previousClose || eQuote?.close || first.previousClosing;
    const basisMarketValue = (previousClose * lotQuantity) / unitQuantity;
    const taxlotMarketValue = lotQuantity ? (lotQuantity * price) / unitQuantity : 0;
    const fixedIncomeMarketValue = psum((p) => p.marketUsdeValue);
    const nonFixedMarketValue = quantity ? (quantity * price) / unitQuantity : psum((p) => p.marketValue);
    const currentMarketValue = isFixedIncome ? fixedIncomeMarketValue : nonFixedMarketValue;

    const isNonMarketHours = GetMarketTime() !== 'open';

    // Will not be maintained for cryptos, as this is inaccurate. Will be replaced with a more accurate calculation in the future.
    const glToday = (() => {
        if (['mutual-fund', 'crypto'].includes(assetClass.type)) return NaN;
        if (!isNonMarketHours || !eQuote?.change || !quantity) return taxlotMarketValue ? taxlotMarketValue - basisMarketValue : 0;
        return lotQuantity ? (eQuote?.change * lotQuantity) / unitQuantity : 0;
    })();

    const glTodayPercent = (() => {
        if (['mutual-fund', 'crypto'].includes(assetClass.type)) return NaN;
        if (!isNonMarketHours || !eQuote?.changePercent) {
            return Math.abs(glToday / basisMarketValue) * (glToday >= 0 ? 1 : -1);
        }

        return eQuote?.changePercent;
    })();

    const fixedIncomeGlTotal = currentMarketValue - costBasis;
    const marketValueCalc = assetClass.family === 'cryptos' ? currentMarketValue : taxlotMarketValue;
    const nonFixedGlTotal = costBasis ? (isShort ? costBasis - Math.abs(marketValueCalc) : marketValueCalc - costBasis) : NaN;
    const glTotal = isFixedIncome ? fixedIncomeGlTotal : nonFixedGlTotal;

    const glTotalPercent = (() => {
        return Math.abs(glTotal / costBasis) * (glTotal >= 0 ? 1 : -1);
    })();

    const avgELotCost = lotQuantity ? Math.abs((costBasis / lotQuantity) * unitQuantity) : 0;
    const result = {
        glToday,
        glTodayPercent,
        glTotal,
        glTotalPercent,
        price,
        costBasis,
        avgCost,
        avgELotCost,
        gainLossBasis: basisMarketValue,
        marketValue: currentMarketValue,
        lotQuantity,
        quantity,
        change,
        changePercent,
        hasPosition: true
        // lots: eLots
    };

    return result;
};

// Note: this function only works correctly if the positions that are passed in are all for the same security.
const GetGainLossFiguresAllFutures = (positions: ApiPosition[], eQuote: SecurityQuote, unitFactor: number): GainLossFigures => {
    const first = positions[0];
    const psum = (selector: (p: ApiPosition) => number) => Sum(positions?.map(selector));
    const costBasis = psum((p) => p?.netPrice);
    const glToday = psum((p) => p?.todaysGL);
    const avgELotCost = psum((p) => p.quantity * p.netPrice) / psum((p) => p.quantity);
    const quantity = psum((p) => p.netVolume || 0);
    const price = eQuote?.price || first.lastPrice;
    const change = QuoteAttribute.getChange(eQuote, true);
    const changePercent = (eQuote?.changePercent || change / price) * 100;

    const isOption = OptionSymbol.IsOptionSymbol(first?.secMasterOptionSymbol);
    const currentMarketValue = isOption ? psum((p) => p.marketValue) : quantity * price;

    const { glTotal = 0, glTotalPercent = 0 } =
        positions.reduce(
            (acc, c) => {
                const current = GetTotalGainLossFiguresForFuturesPosition(c, eQuote, unitFactor);

                return {
                    glTotal: acc?.glTotal + current.glTotal,
                    glTotalPercent: acc?.glTotalPercent + current.glTotalPercent
                };
            },
            { glTotal: 0, glTotalPercent: 0 }
        ) || {};

    const result = {
        avgELotCost,
        change,
        changePercent,
        costBasis,
        glToday: glToday || null,
        glTodayPercent: null,
        glTotal,
        glTotalPercent,
        hasPosition: true,
        lotQuantity: quantity,
        marketValue: currentMarketValue,
        price,
        quantity
    };

    return result;
};

export const GetTotalGainLossFiguresForFuturesPosition = (position: ApiPosition, eQuote: SecurityQuote, unitFactor: number): Partial<GainLossFigures> => {
    const costBasis = position?.netPrice;
    const quantity = position?.netVolume;
    const price = eQuote?.price || position?.lastPrice;
    const glTotal = getFuturesTotalProfitLoss({ currentPrice: price, costBasis, quantity, unitFactor });
    const glTotalPercent = price && costBasis && quantity ? ((price - costBasis) / costBasis) * 100 * Math.sign(quantity) : null;

    const result = {
        glTotal,
        glTotalPercent
    };

    if (position.symbol === DEBUG_SYMBOL) console.log({ costBasis, quantity, price, unitFactor, glTotal, glTotalPercent, result });

    return result;
};
