// @ts-strict-ignore
import { FormControlLabel, IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import { AnchoredMenu } from '../AnchoredMenu';
import { Flex } from 'components/Flex';
import './index.scss';

export const InputSplit = React.memo(
    (props: {
        children: any;
        className?: string;
        label: string;
        labelColor?: string;
        onOptionSelect?: (value: string) => any;
        options?: { label: string; value: string }[];
        style?: React.CSSProperties;
        subLabel?: string;
        disabled?: boolean;
    }) => {
        const LabelComponent = useCallback(
            () => (
                <>
                    <Flex align='center'>
                        <Typography style={{ ...props.style, '--color': props.labelColor } as React.CSSProperties} variant='h6'>
                            {props.label}
                        </Typography>
                        {props.options ? (
                            <IconButton style={{ marginLeft: 10 }} onClick={(e) => setMenuEl(e.target)} size='large'>
                                <Edit fontSize='small' />
                            </IconButton>
                        ) : null}
                    </Flex>
                    {props.subLabel && <Typography color='textSecondary'>{props.subLabel}</Typography>}
                </>
            ),
            [props.label, props.labelColor, props.options, props.subLabel]
        );

        const [menuEl, setMenuEl] = useState(null);

        return (
            <>
                <FormControlLabel
                    className={`input-split${props.className ? ` ${props.className}` : ''}`}
                    control={props.children}
                    label={<LabelComponent />}
                    labelPlacement='start'
                    disabled={props.disabled}
                />
                {props.options && (
                    <AnchoredMenu
                        anchorEl={menuEl}
                        handleClose={() => setMenuEl(null)}
                        items={props.options.map((o) => ({ label: o.label, onClick: () => props.onOptionSelect(o.value) }))}
                    />
                )}
            </>
        );
    }
);
